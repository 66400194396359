import React from 'react';
import { IconContext } from "react-icons";
import { GiBottledBolt } from "react-icons/gi";
import { Trans } from 'react-i18next';
import Button from 'react-bootstrap/Button';

const Header = ( props ) => {
  return (
    <div className="header">
      <div className="title"> <Trans>Products</Trans> </div>
      <div className="separator"> | </div>
      <div className="icon">
        <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
          <div>
            <GiBottledBolt />
          </div>
        </IconContext.Provider>
     </div>
    </div>
  )
}

export default Header
