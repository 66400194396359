import React from 'react'
import ScoreLine from './ScoreLine';
import { withNamespaces } from 'react-i18next';
import { getClosestElementInArray } from '../../../../../constants/funciones';

const GraphMacroscopy = props => {

    return (
        <div className="compositionList">

            <div className="item">
              <div className="tag"> { props.t( 'Small intestine' ) } </div>
              <ScoreLine value={ parse5_4_small_intestine( props.form.promedios.macroscopiaIntestinalIntestinoDelgado ) } />
            </div>
            <div className="item">
              <div className="tag"> { props.t( 'Large intestine' ) } </div>
              <ScoreLine value={ parse5_4_large_intestine( props.form.promedios.macroscopiaIntestinalIntestinoGrueso ) } />
            </div>
            <div className="item">
              <div className="tag"> { props.t( 'Ceca' ) } </div>
              <ScoreLine value={ parse5_4_ceca( props.form.promedios.macroscopiaIntestinalCiegos ) } />
            </div>

        </div>
    )
}

  // METODO PARA MAPEAR LOS VALORES GUARDADOS EN UN RANGO DE 0-5 CON LOS VALORES INPUT 0-4
  const parse5_4_small_intestine = value => {

    const posibleValues = [ 0, 2, 3, 4, 5 ]
    const closest = getClosestElementInArray( posibleValues, value )

    switch ( closest ) {
      case 0:
        return 0
      case 2:
        return 1
      case 3:
        return 2
      case 4:
        return 3
      case 5:
        return 4
      default:
        return 0
    }
  }

  const parse5_4_large_intestine = value => {

    const posibleValues = [ 0, 2, 3, 4, 5 ]
    const closest = getClosestElementInArray( posibleValues, value )

    switch ( closest ) {
      case 0:
        return 0
      case 2:
        return 1
      case 3:
        return 2
      case 4:
        return 3
      case 5:
        return 4
      default:
        return 0
    }
  }

  const parse5_4_ceca = value => {

    const posibleValues = [ 0, 1, 2, 3, 5 ]
    const closest = getClosestElementInArray( posibleValues, value )

    switch ( closest ) {
      case 0:
        return 0
      case 1:
        return 1
      case 2:
        return 2
      case 3:
        return 3
      case 5:
        return 4
      default:
        return 0
    }
  }


export default withNamespaces()(GraphMacroscopy)