import Api from './Api';
import authService from './Auth';

const productsService = {

  get: (  ) => {

    return Api.get( '/productos', {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  post: ( jsonData ) => {

      return Api.post( '/producto', jsonData, {
        headers: {
          token: authService.getToken()
        }
      } )

  },
  delete: ( productID ) => {

    return Api.delete( `/producto/${ productID }`, {
      headers: {
        token: authService.getToken()
      }
    } )
  }

}

export default productsService
