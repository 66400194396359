import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import getForms from '../../../actions/getForms';

import Header from './components/Header';
import NoRecords from './components/NoRecords';
import FilterPanel from './components/filter/FilterPanel';
import RemoveForm from './components/RemoveForm';

import InfoSection from './components/info/InfoSection';
import HistoricalGraphSection from './components/historical/HistoricalGraphSection';
import SummerySection from './components/summery/SummerySection';
import DigestiveSystemSection from './components/digestiveSystem/DigestiveSystemSection';
import CoccidiosisSection from './components/coccidiosis/CoccidiosisSection';
import ClostridiosisSection from './components/clostridiosis/ClostridiosisSection';
import ObservationsSection from './components/observations/ObservationsSection';

import FormModal from './components/form/FormModal';
import ReportModal from './components/report/ReportModal';

import './dashboards.css';
import useFilters from './hooks/useFilters';
import { getFormsAverage } from '../../../constants/funciones';

const DashboardsPage = ( props ) => {

  // VARIABLES DE ESTADO DEL FILTRO, LOADING Y LISTA DE ZONAS
  // const [ filterString, setFilterString ] = useState( '' );
  const [ loading, setLoading ] = useState( true );
  const [ formSelected, selectForm ] = useState( null );
  const [ openPanel, setOpenPanel ] = useState( false );
  const [ averageMode, setAverageMode ] = useState( true );
  const [ formModal, setFormModal ] = useState( null );
  const [ reportModal, setReportModal ] = useState( false );
  const { initialFilters, filters, modifyFilter, filteredForms, restoreFilters } = useFilters( props.farms, props.products, props.forms, props.zones );

  // HOOK PARA PEDIR A LA API GET /ZONES AL INICIAR EL COMPONENTE
  useEffect( () => {

    if ( averageMode ){

      selectForm( getFormsAverage( filteredForms ) )
    } else {
      selectForm( props.forms[0] );
    }
    setLoading( false );

  }, [ props.forms ] )

  useEffect( () => {

    if ( averageMode ){
      selectForm( getFormsAverage( filteredForms ) )
    } else {
      selectForm( props.forms[0] );
    }

  }, [ averageMode ] )

  // RENDERIADO DEL COMPONENTE
    // LOADING
    // COMPONENTE
  if ( loading ){

    return (
      <div className="loadingPage">
        <img src="/assets/img/icons/loading.svg" alt="loading"></img>
      </div>
    )
  } else if ( !filteredForms.length ) {
    return (
      <div className="dashboardsPage">
        <FilterPanel openPanel={ openPanel } openPanelHandler={ setOpenPanel } filters={ filters } modifyFilter={ modifyFilter } restoreFilters={ restoreFilters } />
        <Header averageMode={ averageMode } setAverageMode={ setAverageMode } openPanelHandler={ setOpenPanel } reportOpener={ () => { setReportModal( true ) } } />
        <div className="noRecords">
          <div className="logo">
            <img src="/assets/img/icons/noForms.svg" alt="noForms" />
          </div>
          <div className="title"> ¡ Sin Registros ! </div>
          <div className="message">
            <p> Todavía no fue enviado ningun formulario por parte de los usuarios de este espacio. </p>
            <p> En cuanto envien el primer formulario podrás visualizar sus resultados en esta sección. </p>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="dashboardsPage">
        <FilterPanel openPanel={ openPanel } openPanelHandler={ setOpenPanel } filters={ filters } modifyFilter={ modifyFilter } restoreFilters={ restoreFilters } />

        <Header averageMode={ averageMode } setAverageMode={ setAverageMode } openPanelHandler={ setOpenPanel } filter={ true } formOpener={ () => { setFormModal( formSelected._id ) } } reportOpener={ () => { setReportModal( true ) } } forms={ filteredForms } />
        {
          filteredForms.length ? (
            <div className="sections">
              { !averageMode ? <HistoricalGraphSection farms={ props.farms } users={ props.users } selectFormHandler={ selectForm } formSelected={ formSelected } formsData={ filteredForms } /> : null }
              <InfoSection averageMode={ averageMode } formsList={ filteredForms } formSelected={ formSelected } />
              <SummerySection form={ formSelected } />
              <DigestiveSystemSection form={ formSelected } />
              <CoccidiosisSection form={ formSelected } />
              <ClostridiosisSection form={ formSelected } />
              { !averageMode && formSelected.datosFormulario ? <ObservationsSection form={ formSelected } /> : null }
              { !averageMode && formSelected.datosFormulario ? <RemoveForm form={ formSelected } /> : null }
            </div>
          ) : <NoRecords />
        }

        <FormModal form={ formModal } handlerClose={ () => { setFormModal( null ) } } />
        <ReportModal opened={ reportModal } average={ averageMode } forms={ filteredForms } formSelected={ formSelected } filters={ filters } handlerClose={ () => { setReportModal( false ) } } initialFilters={ initialFilters } />
      </div>
    )
  }
}



const mapStateToProps = ( state ) => {

  return {
    sidenavOpen: state.sidenavOpen,
    farms: state.farms,
    forms: state.forms,
    zones: state.zones,
    users: state.users,
    products: state.products
  }

}

export default connect( mapStateToProps, { getForms } )(DashboardsPage)
