import React from 'react';

import DigestiveSystemPercentage from './DigestiveSystemPercentage';
import DigestiveSystemDistribution from './DigestiveSystemDistribution';

import { withNamespaces } from 'react-i18next';

import './digestive.css'

const DigestiveSystemSection = ( props ) => {

  return (
    <div className="section summery" id="digestiveSection">
      <div className="title"> { props.t( 'Intestinal Injuries' ) } </div>
      <div className="rowContainer">
        <DigestiveSystemPercentage formData={ props.form } />
        <DigestiveSystemDistribution formData={ props.form } />
      </div>
    </div>
  )
}

export default withNamespaces()(DigestiveSystemSection)
