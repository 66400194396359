import Api from './Api';
import authService from './Auth';

const formsService = {

  get: (  ) => {

    return Api.get( '/formularios', {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  getFormData: ( formID ) => {

    return Api.get( '/formulario/unitario/web/' + formID, {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  put: ( formulario, jsonCallbacks ) => {

    return Api.put( '/formulario/' + formulario._id, formulario, {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  photos: ( formID ) => {

    return Api.get( '/formulario/fotos/' + formID, {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  getReport: contentHTML => {

    return Api.post( '/files/report', { content: contentHTML }, {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  getExcel: formsID => {
    return Api.post( '/files/excel', { forms: formsID }, {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  delete: formID => {

    return Api.delete( '/formulario/' + formID, {
      headers: {
        token: authService.getToken()
      }
    } )
  }

}

export default formsService
