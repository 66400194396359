import React from 'react'
import ScoreLine from './ScoreLine';
import { withNamespaces } from 'react-i18next';
import { getClosestElementInArray } from '../../../../../constants/funciones';

const GraphMicroscopy = props => {

    return (
      <div className="compositionList">

        <div className="item">
          <div className="tag"> { props.t( 'E. acervulina' ) } </div>
          <ScoreLine value={ parse5_4_small_intestine( props.form.promedios.microscopiaIntestinalIntestinoDelgado ) } />
        </div>
        <div className="item">
          <div className="tag"> { props.t( 'E. maxima' ) } </div>
          <ScoreLine value={ parse5_4_large_intestine( props.form.promedios.microscopiaIntestinalIntestinoGrueso ) } />
        </div>
        <div className="item">
          <div className="tag"> { props.t( 'E. tenella' ) } </div>
          <ScoreLine value={ parse5_4_ceca( props.form.promedios.microscopiaIntestinalCiegos ) } />
        </div>

      </div>
    )
}

  // METODO PARA MAPEAR LOS VALORES GUARDADOS EN UN RANGO DE 0-5 CON LOS VALORES INPUT 0-4
  const parse5_4_small_intestine = value => {

    const posibleValues = [ 0, 1, 2, 3, 4 ]
    const closest = getClosestElementInArray( posibleValues, value )

    return closest
  }

  const parse5_4_large_intestine = value => {

    const posibleValues = [ 0, 1, 3, 4, 5 ]
    const closest = getClosestElementInArray( posibleValues, value )

    switch ( closest ) {
      case 0:
        return 0
      case 1:
        return 1
      case 3:
        return 2
      case 4:
        return 3
      case 5:
        return 4
      default:
        return 0
    }
  }

  const parse5_4_ceca = value => {

    const posibleValues = [ 0, 1, 3, 4, 5 ]
    const closest = getClosestElementInArray( posibleValues, value )

    switch ( closest ) {
      case 0:
        return 0
      case 1:
        return 1
      case 3:
        return 2
      case 4:
        return 3
      case 5:
        return 4
      default:
        return 0
    }
  }

export default withNamespaces()(GraphMicroscopy)