import React from 'react';
import { connect } from 'react-redux';
import { Checkbox, DatePicker, Slider, Select } from 'antd';

import { Trans } from 'react-i18next';

const { Option } = Select;

const ZonesFilter = props => {

  const handlerZonesChange = zonesSelected => {
    if ( zonesSelected.includes( 'ALL' ) ){

      // SI ALL ESTABA SELECCIONADO Y MARQUE UNA OPCION, DESMARCO ALL
      if ( props.zones.includes('ALL') ){
        props.setZones( zonesSelected.filter( f => f !== 'ALL' ) )
      } else {
        // SI ALL LO MARQUE AHORA.. MARCO ALL
        props.setZones( ['ALL'] )
      }

    } else {
        props.setZones( zonesSelected );
    }
  }


  return (
    <div className="filter zones">
      <div className="tag"> <Trans>Zones</Trans> </div>
      <div className="selector">
        <Checkbox.Group value={ props.zones } onChange={ handlerZonesChange } style={{ width: '100%' }}>
          {
            props.initialZones.map( zone => {
              return (
                <div key={ zone._id }>
                  <Checkbox value={ zone._id } > { zone.nombre } </Checkbox>
                  <br />
                </div>
              )
            })
          }
          <br />
          <Checkbox value="ALL" > <Trans>All</Trans> </Checkbox>
        </Checkbox.Group>
      </div>
    </div>
  )

}

const mapStateToProps = ( state ) => {

  return {
    initialZones: state.zones
  }

}

export default connect( mapStateToProps )(ZonesFilter)
