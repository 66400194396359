const profileReducer = ( profile = null, action ) => {

  if ( action.type === 'GET_PROFILE' ){

    return action.payload;
  }

  return profile;

}

export default profileReducer;
