import Cookie from '../constants/cookie';
import env from '../environment/environment';
import Api from './Api';

const loginService = {

  login: ( jsonData, jsonCallbacks ) => {

    jsonData['web'] = true;

    return fetch(
      `${ env.url }/login`, 
      {
        method: 'POST', 
        body: JSON.stringify(jsonData), 
        headers: { "Content-Type": "application/json" }, 
        credentials: 'same-origin' 
      })
        .then( res => res.json() )
        .then( data => {
          window.localStorage.setItem('t', data.token );
          Cookie.set('t', data.token)
          if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success();
          return data
        })
        .catch( err => {
          if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
        })
  },
  logout: ( callback ) => {

    window.localStorage.removeItem('t');
    if ( callback ) callback();

  }

}

export default loginService
