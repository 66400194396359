const updateProduct = ( product, action ) => {

  if ( action === 'ADD' ){
    return {
      type: 'ADD_PRODUCT',
      payload: product
    }
  } else if ( action === 'REMOVE' ){
    return {
      type: 'REMOVE_PRODUCT',
      payload: product
    }
  }

}

export default updateProduct;
