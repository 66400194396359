import React from 'react';

const DotPoint = ( props ) => {
  return (
    <div className={ props.marked[0] < props.marked[1] ? 'dot selected' : 'dot' }></div>
  )
}

const ScoreLine = props => {

  let icons = [];
  for ( let i = 0; i < 4; i++ ){
    icons.push( <DotPoint key={ i } marked={ [i, props.value] } /> )
  }

  return (
    <div className="scoreLine">
      <div className="dots">
        { icons }
      </div>

      <div className="score">
        <span className="value"> { props.value } </span>
        <span className="total"> / 4 </span>
      </div>
    </div>
  )

}


export default ScoreLine
