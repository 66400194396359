import React from 'react';
import { IconContext } from "react-icons";
import { BsFileEarmark } from "react-icons/bs";

import { withNamespaces } from 'react-i18next';

import Button from 'react-bootstrap/Button';

import env from '../../../../environment/environment';
import authService from '../../../../services/Auth';

const Header = ( props ) => {

  const getExcel = () => {
    
    var f = document.getElementById('excelListForms');
    f.token.value = authService.getToken();
    f.forms.value = JSON.stringify( props.formularios.map( f => f._id ) );
    window.open('', 'TheWindow');
    f.submit();

  }

  return (
    <div className="header">
      <div className="title"> { props.t( 'Forms List' ) } </div>
      <div className="separator"> | </div>
      <div className="icon">
        <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
          <div>
            <BsFileEarmark />
          </div>
        </IconContext.Provider>
      </div>

      <div className="btn-group">
        <Button variant="outline-primary" onClick={ getExcel }> { props.t( 'Download Excel' ) } </Button>
        <form id="excelListForms" method="post" action={ env.url + '/files/excel' } target="TheWindow">
          <input type="hidden" name="forms" value="content" />
          <input type="hidden" name="token" value="content" />
        </form>
      </div>

    </div>
  )
}

export default withNamespaces()(Header)
