import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Header from './components/Header';
import NoAlarms from './components/NoAlarms';
import AlarmsList from './components/AlarmsList';
import { withNamespaces } from 'react-i18next';

import Swal from 'sweetalert2'

import getNotifications from '../../../actions/getNotifications';

import notificationsService from '../../../services/Notifications';

import './alarms.css';

const AlarmsPage = ( props ) => {

  const [ loading, setLoading ] = useState( true );

  const saveNotifications = () => {

    // ALERT DE LOADING
    Swal.fire ({
       title: props.t( '..Saving your alarms configuration..' ),
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    let actualNotifications = []
    document.querySelectorAll('.alarmsPage .alarms .card.alarm').forEach((item, i) => {

      const codigo = item.getAttribute('value')
      const activado = item.querySelector('.header .activation button').getAttribute('aria-checked') === 'true'
      const regla = activado ? item.querySelector('.body .operator').getAttribute('value') : undefined
      const valor = activado ? item.querySelector('.body .value input').value : undefined

      actualNotifications.push({
        codigo: codigo,
        activado: activado,
        regla: regla,
        valor: valor
      })
    });

    const jsonData = {
      notificaciones: actualNotifications
    }

    // console.log( actualNotifications )
  if ( props.notifications ){
    notificationsService.put( props.notifications._id, jsonData, {
      success: () => {
        props.getNotifications().then( () => {

          Swal.close();

        })

      }
    } )
  }
  notificationsService.post( jsonData, {
    success: () => {
      props.getNotifications().then( () => {

        Swal.close();
      })

    }
  } )
  }

  // FUNCION HANDLER PARA RELODEAR EL COMPONENTE
  const reloadNotifications = () => {
    setLoading( true );
    props.getNotifications();
  }

  useEffect( () => {
    props.getNotifications().then( () => {
      setLoading( false );
    })
  }, [])

  // useEffect( () => {
  //   setAlarmsData( props.notifications );
  //   setLoading( false );
  // }, [ props.notifications ])


  // RENDERIADO DEL COMPONENTE
    // LOADING
    // COMPONENTE
  if ( loading ){
    return (
      <div className="loadingPage">
        <img src="/assets/img/icons/loading.svg" alt="loading"></img>
      </div>
    )
  } else {

    return (
      <div className="alarmsPage">
        <Header save={ saveNotifications } />
        <AlarmsList alarmsData={ props.notifications } />
      </div>
    )
  }

}

const mapStateToProps = ( state ) => {

  return {
    notifications: state.notifications
  }

}

export default compose(withNamespaces(), connect( mapStateToProps, { getNotifications } ))(AlarmsPage)
