import React from 'react';

import { withNamespaces } from 'react-i18next';

// COMPONENTS
const getIndexStyle = ( value ) => {
  let color, background;
  if ( value < 40 ){
    background = 'rgba(217, 83, 79, 0.8)'
    color = 'white'
  } else if ( value < 70 ){
    background = 'rgba(240, 173, 78, 0.8)'
    color = 'black'
  } else {
    background = 'rgba(92, 184, 92, 0.8)'
    color = 'white'
  }

  return {
    background: background,
    color: color
  }
}

// ITEM LIST HEADER
const FormularioListItemHeader = props => {

  return (
    <div className="formularioListItem header">
      <div className="datoInfo date"> { props.t( 'Date' ) } </div>
      <div className="datoInfo user"> { props.t( 'User' ) } </div>
      <div className="datoInfo farm"> { props.t( 'Farm' ) } </div>
      <div className="datoInfo galpon"> { props.t( 'Galpon' ) } </div>
      <div className="datoInfo chickens"> { props.t( 'Chickens' ) } </div>
      <div className="datoInfo age"> { props.t( 'Age' ) } </div>

      <div className="datoResultado"> { props.t( 'Calidad Intestinal' ) } </div>
      <div className="datoResultado"> { props.t( 'Sistema Digestivo' ) } </div>
      <div className="datoResultado"> { props.t( 'Grado Coccidios' ) } </div>
      <div className="datoResultado"> { props.t( 'Grado Clostridios' ) } </div>

      <div className="datoPromedio"> { props.t( 'Cavidad Bucal' ) } </div>
      <div className="datoPromedio"> { props.t( 'Proventriculo' ) } </div>
      <div className="datoPromedio"> { props.t( 'Molleja' ) } </div>
      <div className="datoPromedio"> { props.t( 'Higado' ) } </div>
      <div className="datoPromedio"> { props.t( 'Pancreas' ) } </div>
      <div className="datoPromedio"> { props.t( 'Int. Delgado' ) } </div>
      <div className="datoPromedio"> { props.t( 'Int. Grueso' ) } </div>
      <div className="datoPromedio"> { props.t( 'Ciegos' ) } </div>
      <div className="datoPromedio"> { props.t( 'Contenido' ) } </div>
      <div className="datoPromedio"> { props.t( 'Tono' ) } </div>
      <div className="datoPromedio"> { props.t( 'Enteritis Necrotica' ) } </div>
      <div className="datoPromedio"> { props.t( 'Retencion de Yema' ) } </div>
      <div className="datoPromedio"> { props.t( 'Presencia de Parasitos' ) } </div>
      <div className="datoPromedio"> { props.t( 'E. acervulina' ) } </div>
      <div className="datoPromedio"> { props.t( 'E. maxima' ) } </div>
      <div className="datoPromedio"> { props.t( 'E. tenella' ) } </div>

      <div className="datoInfo"> { props.t( 'Vacunacion' ) } </div>
      <div className="datoInfo"> { props.t( 'Promotor Inicial' ) } </div>
      <div className="datoInfo"> { props.t( 'Promotor Final' ) } </div>
      <div className="datoInfo"> { props.t( 'Coccidrato Inicial' ) }  </div>
      <div className="datoInfo"> { props.t( 'Coccidrato Final' ) }  </div>

    </div>
  )
}

// ITEM LIST DEL BODY
const FormularioListItem = ( props ) => {

  const user = props.users.find( u => u._id === props.form.datosFormulario.usuario )

  return (
    <div className="formularioListItem" key={ props.form._id }>
      <div className="datoInfo date"> { convertDateISO( props.form.datosFormulario.fecha, props.t ) } </div>
      <div className="datoInfo user"> { user ? (user.nombre + ' ' + user.apellido) : '-' } </div>
      <div className="datoInfo farm"> { props.form.datosFormulario.granja.nombre } </div>
      <div className="datoInfo galpon"> { props.form.datosFormulario.galpon } </div>
      <div className="datoInfo chickens"> { props.form.cantidadAves } </div>
      <div className="datoInfo age"> { props.form.datosFormulario.edad } </div>

      <div className="datoResultado" > <div className="container" style={ getIndexStyle( props.form.resultadosIndices.calidadIntestinal ) }>{ props.form.resultadosIndices.calidadIntestinal.toFixed(0) }%</div> </div>
      <div className="datoResultado"> { props.form.resultadosIndices.lesionesSistemaDigestivo.toFixed(0) }% </div>
      <div className="datoResultado"> { props.form.resultadosIndices.riesgoDeCoccidiosis.toFixed(0) }% </div>
      <div className="datoResultado"> { props.form.resultadosIndices.riesgoDeClostridiosis.toFixed(0) }% </div>

      <div className="datoPromedio"> { Math.round( props.form.promedios.digestivoCavidadBucal ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.digestivoProventriculo ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.digestivoMolleja ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.digestivoHigado ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.digestivoPancreas ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalIntestinoDelgado ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalIntestinoGrueso ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalCiegos ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalContenido ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalTono ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalEnteritisNecrotica ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalRetencionYema ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.macroscopiaIntestinalPresenciaParasitos ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.microscopiaIntestinalIntestinoDelgado ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.microscopiaIntestinalIntestinoGrueso ) } </div>
      <div className="datoPromedio"> { Math.round( props.form.promedios.microscopiaIntestinalCiegos ) } </div>

      <div className="datoInfo"> { props.form.datosFormulario.vacunacion } </div>
      <div className="datoInfo"> { props.form.datosFormulario.promotorInicial?.nombre } </div>
      <div className="datoInfo"> { props.form.datosFormulario.promotorFinal?.nombre } </div>
      <div className="datoInfo"> { props.form.datosFormulario.coccidratoInicial?.nombre } </div>
      <div className="datoInfo"> { props.form.datosFormulario.coccidratoFinal?.nombre } </div>
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const FormulariosList = ( props ) => {

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="formulariosList">
      <div className="list">
        <FormularioListItemHeader t={ props.t } />
        { props.formularios.map( form => {
          return <FormularioListItem key={ form._id } form={ form } users={ props.users } t={ props.t } />
        } ) }
      </div>
    </div>
  )
}

function convertDateISO( data_in, t ){
  const keyMonth = {
    1: t( "January" ),
    2: t( "February" ),
    3: t( "March" ),
    4: t( "April" ),
    5: t( "May" ),
    6: t( "June" ),
    7: t( "July" ),
    8: t( "August" ),
    9: t( "September" ),
    10: t( "October" ),
    11: t( "November" ),
    12: t( "December" )
  }
	const fecha = new Date(Date.parse(data_in));
	const data_out = fecha.getDate() + " " + keyMonth[fecha.getMonth()+1].substr(0, 3)+" '"+fecha.getFullYear().toString().slice(-2);
	return data_out;
}

export default withNamespaces()(FormulariosList)
