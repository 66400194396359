import { combineReducers } from 'redux';

import sidenavReducer from './sidenavReducer';
import farmsReducer from './farmsReducer';
import formsReducer from './formsReducer';
import usersReducer from './usersReducer';
import zonesReducer from './zonesReducer';
import productsReducer from './productsReducer';
import profileReducer from './profileReducer';
import notificationsReducer from './notificationsReducer';

const reducers = combineReducers({
  sidenavOpen: sidenavReducer,
  farms: farmsReducer,
  forms: formsReducer,
  users: usersReducer,
  zones: zonesReducer,
  products: productsReducer,
  profile: profileReducer,
  notifications: notificationsReducer
})

export default reducers;
