const productsReducer = ( products = [], action ) => {

  if ( action.type === 'GET_PRODUCTS' ){
    return action.payload;
  } else if ( action.type === 'ADD_PRODUCT' ){

    const newArray = [ ...products ]
    const newElementIndex = getIndex( action.payload.nombre.toLowerCase(), products.map( prod => prod.nombre.toLowerCase() ) )
    newArray.splice( newElementIndex, 0, action.payload )

    return newArray

  } else if ( action.type === 'REMOVE_PRODUCT' ){

    return products.filter( prod => prod._id !== action.payload )
  }

  return products;

}

const getIndex = ( value, array ) => {

  for (var i = 0; i < array.length; i++) {
    if ( value < array[i] ) return i;
  }

  return array.length

}

export default productsReducer;
