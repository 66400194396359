import React, { useState } from 'react';
import { Slider, Checkbox } from 'antd';
import { Trans } from 'react-i18next';
import { withNamespaces } from 'react-i18next';

const ChickenAgeFilter = props => {

  const handlerModeChange = options => {

    const newOption = options.find( option => option !== props.ageFormat );

    // SI TRATE DE SELECCIONAR LA SELECCIONADA NO HAGO NADA - CANCELO
    if ( !newOption ) return
    props.setAgeFormat( newOption )

  }

  const handlerChangeFrom = ev => {

    const hasta = props.chickenAge[1] ? props.chickenAge[1] : undefined;

    props.setChickenAge( [ev.target.value, hasta] )

  }

  const handlerChangeTo = ev => {

    const desde = props.chickenAge[0] ? props.chickenAge[0] : undefined;

    props.setChickenAge( [desde, ev.target.value] )

  }

  return (
    <div className="filter chickenDays">
      <div className="tag"> <Trans>Age of the birds</Trans> </div>
      <div className="selector">
        <Checkbox.Group value={ [ props.ageFormat ] } onChange={ handlerModeChange } style={{ width: '100%' }}>
          <Checkbox value="DAY" > <Trans>Days</Trans> </Checkbox>
          <br />
          <Checkbox value="WEEK" > <Trans>Weeks</Trans> </Checkbox>
          <br />
        </Checkbox.Group>
        <div className="period">
          <input className="from" type="number" placeholder={ props.t( 'From' ) } onChange={ handlerChangeFrom } value={ props.chickenAge[0] ? props.chickenAge[0] : undefined }></input>
          <span className="separator">--</span>
          <input className="to" type="number" placeholder={ props.t( 'To' ) } onChange={ handlerChangeTo } value={ props.chickenAge[1] ? props.chickenAge[1] : undefined }></input>
        </div>
      </div>
    </div>
  )

}

export default withNamespaces()(ChickenAgeFilter)

// <Slider range value={ props.chickenDays } tooltipPlacement="bottom" tooltipVisible={ true } min={ 0 } max={ 25 } onChange={ handlerChickenDaysChange } />
