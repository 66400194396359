import React from 'react';
import { IconContext } from "react-icons";
import { FaRegBell } from "react-icons/fa";
import { Trans } from 'react-i18next';
import Button from 'react-bootstrap/Button';

const Header = ( props ) => {
  return (
    <div className="header">
      <div className="left">
        <div className="title"> <Trans>Alarms</Trans> </div>
        <div className="separator"> | </div>
        <div className="icon">
          <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
            <div>
              <FaRegBell />
            </div>
          </IconContext.Provider>
       </div>
      </div>
      <div className="right">
        <Button variant="success" onClick={ props.save }> <Trans>Save</Trans> </Button>
      </div>
    </div>
  )
}

export default Header
