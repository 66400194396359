import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Select } from 'antd';
import Button from 'react-bootstrap/Button';

import authService from '../../../../services/Auth';
import i18n from '../../../../i18n';

const { Option } = Select;

const LanguageCard = ( props ) => {

  const [ lang, setLang ] = useState( null )
  useEffect( () => {

    if ( props.user ) setLang( props.user.idioma )
    
  }, [ props.user ])

  const updateLang = ( newLang ) => {
    setLang( newLang );
  }

  const saveLang = () => {

    if ( lang !== props.user.idioma ) {
      authService.updateLocale( lang ).then( () => {
        
        window.location.reload(false);

      })
    }

  }

  return (
    <div className="card language">
      <div className="title"> { props.t( 'Language' ) } </div>
      <div className="content">
        <div className="column">
          <Select value={ lang } onChange={ updateLang }>
            <Option value="es_AR"> { props.t( 'Spanish' ) } </Option>
            <Option value="en_US"> { props.t( 'English' ) } </Option>
            <Option value="pt_BR"> { props.t( 'Portugues' ) } </Option>
          </Select>
        </div>
      </div>
      <div className="footer">
        <Button size="sm" onClick={ saveLang }>{ props.t( 'Update' ) }</Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.profile
  }
}

export default compose(withNamespaces(), connect( mapStateToProps ))(LanguageCard)
