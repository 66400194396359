import React from 'react';
import { connect } from 'react-redux';
import { Checkbox, Slider, Select } from 'antd';

import { Trans } from 'react-i18next';

const { Option } = Select;

const UsersFilter = props => {

  const handlerUsersChange = usersSelected => {
    if ( usersSelected.includes( 'ALL' ) ){

      // SI ALL ESTABA SELECCIONADO Y MARQUE UNA OPCION, DESMARCO ALL
      if ( props.users.includes('ALL') ){
        props.setUsers( usersSelected.filter( f => f !== 'ALL' ) )
      } else {
        // SI ALL LO MARQUE AHORA.. MARCO ALL
        props.setUsers( ['ALL'] )
      }

    } else {
        props.setUsers( usersSelected );
    }
  }


  return (
    <div className="filter users">
      <div className="tag"> <Trans>Users</Trans> </div>
      <div className="selector">
        <Checkbox.Group value={ props.users } onChange={ handlerUsersChange } style={{ width: '100%' }}>
          {
            props.initialUsers.map( user => {
              return (
                <div key={ user._id }>
                  <Checkbox value={ user._id } > { user.nombre + ' ' + user.apellido } </Checkbox>
                  <br />
                </div>
              )
            })
          }
          <br />
          <Checkbox value="ALL" > <Trans>All</Trans> </Checkbox>
        </Checkbox.Group>
      </div>
    </div>
  )

}

const mapStateToProps = ( state ) => {

  return {
    initialUsers: state.users
  }

}

export default connect( mapStateToProps )(UsersFilter)
