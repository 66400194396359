import React from 'react';

import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaChartLine, FaRegBell } from "react-icons/fa";
import { FiUser, FiUsers, FiMap } from "react-icons/fi";
import { GiBottledBolt } from "react-icons/gi";
import { BsHouse, BsFileEarmark } from "react-icons/bs";

import { Trans } from 'react-i18next';

const FeaturesList = ( props ) => {

  let { path, url } = useRouteMatch();
  let { pathname } = useLocation();

  const selectFeature = ( event ) => {

    if ( document.getElementsByClassName('featureItem selected').length ){
      document.getElementsByClassName('featureItem selected')[0].classList.remove('selected');
    }

    event.target.closest('.featureItem').classList.add('selected');
  }

  return (
    <div className="featuresList">
      <div className="subtitle"> <Trans>Sections</Trans> </div>
        <div className={ pathname.includes('dashboards') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/dashboards`}>
            <div className="logo">
              <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                <div>
                  <FaChartLine />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> <Trans>Dashboards</Trans> </div>
          </Link>
        </div>
        <div className={ pathname.includes('forms') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/forms`}>
            <div className="logo">
              <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                <div>
                  <BsFileEarmark />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> <Trans>Formularios</Trans> </div>
          </Link>
        </div>
        <div className={ pathname.includes('farms') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/farms`}>
            <div className="logo">
              <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                <div>
                  <BsHouse />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> <Trans>Farms</Trans> </div>
          </Link>
        </div>
        <div className={ pathname.includes('zones') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/zones`}>
            <div className="logo">
              <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                <div>
                  <FiMap />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> <Trans>Zones</Trans> </div>
          </Link>
        </div>
        <div className={ pathname.includes('users') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/users`}>
            <div className="logo">
              <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                <div>
                  <FiUsers />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> <Trans>Users</Trans> </div>
          </Link>
        </div>
        <div className={ pathname.includes('products') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/products`}>
            <div className="logo">
              <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                <div>
                  <GiBottledBolt />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tag"> <Trans>Products</Trans> </div>
          </Link>
        </div>
        <div className={ pathname.includes('alarms') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/alarms`}>
             <div className="logo">
               <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                 <div>
                   <FaRegBell />
                 </div>
               </IconContext.Provider>
             </div>
             <div className="tag"> <Trans>Alarms</Trans> </div>
          </Link>
        </div>
        <div className={ pathname.includes('profile') ? "featureItem selected" : "featureItem" } onClick={ selectFeature }>
          <Link to={`${ url }/profile`}>
             <div className="logo">
               <IconContext.Provider value={{ color: "white", className: "global-class-name" }}>
                 <div>
                   <FiUser />
                 </div>
               </IconContext.Provider>
             </div>
             <div className="tag"> <Trans>Profile</Trans> </div>
          </Link>
        </div>
    </div>
  )
}

export default FeaturesList;
