import React from 'react';
import ReactDOM from 'react-dom';
import './style/global.css';
import environment from './environment/environment'

// import "/bootstrap/scss/bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";
import "leaflet-draw/dist/leaflet.draw-src.css";
import 'leaflet/dist/leaflet.css';

import { Provider } from 'react-redux';

import App from './components/App';
import { MaintenancePage } from './components/pages/maintenance/MaintenancePage';

import store from './store';

const root = (
              <Provider store={ store }>
                {
                  !environment.maintenance
                  ?
                  <App />
                  :
                  <MaintenancePage />
                }
              </Provider>
              )

ReactDOM.render( root, document.getElementById('root') );
