import productsService from '../services/Products';

const getProducts = () => {

  return async ( dispatch ) => {

    const response = await productsService.get();

    dispatch({
      type: 'GET_PRODUCTS',
      payload: response.data.info
    })
  }

}

export default getProducts;
