import React from 'react'

const GraphProgress = props => {

    const indexValue = Math.round( props.value )
    let colorIndex
    if ( indexValue <= 40 ){
        colorIndex = '#d9534f'
      } else if ( indexValue <= 70 ){
        colorIndex = '#f0ad4e'
      } else {
        colorIndex = '#5cb85c'
      }

    return (
        <div className="graph">
          <div className="progressBar">
            <div className="progressValue" style={ { backgroundColor: colorIndex, height: indexValue + '%' } } ></div>
          </div>

          <div className="percentage">
            <span className="value"> { indexValue } </span>
            <span className="unit"> % </span>
          </div>
        </div>
    )
}

export default GraphProgress