import React from 'react';

import { IconContext } from "react-icons";
import { TiInfoLarge } from "react-icons/ti";

import { withNamespaces } from 'react-i18next';
import GraphMicroscopy from './GraphMicroscopy';

const MicroscopyCard = ( props ) => {

  return (
    <div className="card microscopia">
      <div className="head">
        <div className="title"> { props.t( 'Microscopia' ) } </div>
        <div className="info">
          <div className="icon">
            <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
              <div>
                <TiInfoLarge />
              </div>
            </IconContext.Provider>
          </div>
          <div className="tooltip">
            <div className="title">{ props.t('Info') }</div>
            <hr />
            <div className="content">
                <span> { props.t( 'Microscopic evaluation score using the ' ) } </span>
                <span> <b> { props.t( 'serial scraping of the intestinal mucosa ' ) } </b> </span>
                <span> { props.t( 'technique and observing the presence of oocysts.' ) } </span>
                <span> <b> { props.t( 'It takes values ​​from 0 to 4 where 0 is the absence of oocysts and 4 is uncountable number of them.' ) } </b> </span>
            </div>
          </div>
        </div>
      </div>
      <GraphMicroscopy form={ props.form } />

    </div>
  )
}

export default withNamespaces()(MicroscopyCard)
