import React, { useState } from 'react';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import { IconContext } from "react-icons";
import { GiBottledBolt } from "react-icons/gi";

import productsService from '../../../../services/Products';

import Swal from 'sweetalert2'
import { Select } from 'antd';

import { withNamespaces } from 'react-i18next';

const { Option } = Select;

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NewProduct = ( props ) => {

  // FUNCION DE CREACION DE ZONA
  const createProduct = () => {

    const name = document.getElementById('productName').value;

    if ( !name  ){
      Swal.fire({
        icon: 'warning',
        title: props.t( 'Incompleted Fields!' ),
        text: props.t( 'Please complete all data fields to create the product.' )
      })

      return;
    }

    // ALERT DE LOADING
    Swal.fire ({
       title: props.t( '..Creating the product..' ),
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // CAPTURO LOS DATOS PARA ENVIAR A LA API POST /ZONES
    const jsonData = {
      nombre: name,
      type: props.type
    }

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    productsService.post( jsonData )
      .then( response => {
        Swal.close()
        props.updateProduct( {
          _id: response.data.info,
          nombre: name,
          default: false,
          type: props.type
        }, 'ADD' )
        props.handlerClose()

      } )

  }

  // RENDERIZADO DEL COMPONENTE MODAL
  return (
    <div>
      <Modal
              isOpen={ props.open }
              onRequestClose={ props.handlerClose }
              style={ customStyles }
              contentLabel="Example Modal"
            >

        <div className="modalContainer newProduct">
          <div className="column">
            <IconContext.Provider size={ 120 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
              <div>
                <GiBottledBolt />
              </div>
            </IconContext.Provider>
          </div>
          <div className="data">
            <div className="form">
              <div className="line name">
                <div className="form-input">
                  <input id="productName" type="text" name="product" placeholder={ props.t( 'Product Name' ) } />
                </div>
              </div>
            </div>
            <div className="footer">
              <Button variant="primary" onClick={ createProduct }> { props.t( 'Add Product' ) } </Button>
            </div>
          </div>
        </div>

      </Modal>
    </div>
  )
}

export default withNamespaces()(NewProduct)