import React from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';

import loginService from '../../../../services/Login';

import { Trans } from 'react-i18next';

const AccountCard = ( props ) => {

  const history = useHistory();

  return (
    <div className="card full">
      <div className="title"> <Trans>Account</Trans> </div>
      <div className="data">
        <div className="icon">
          <img src="/assets/img/icons/user.svg" alt="profile" />
        </div>
        <div className="dataValues">
          <div className="column">
            <div className="dataItem">
              <div className="tag"> <Trans>First Name</Trans> </div>
              <div className="value"> { props.user.nombre } </div>
            </div>
            <div className="dataItem">
              <div className="tag"> <Trans>Last Name</Trans> </div>
              <div className="value"> { props.user.apellido } </div>
            </div>
            <div className="dataItem">
              <div className="tag"> <Trans>Email</Trans> </div>
              <div className="value"> { props.user.email } </div>
            </div>
          </div>
          <div className="column">
            <div className="dataItem">
              <div className="tag"> <Trans>Status</Trans> </div>
              <div className="value"> <Trans>Active</Trans> </div>
            </div>
            <div className="dataItem role">
              <div className="tag"> <Trans>Role</Trans> </div>
              <div className="value"> <Trans>{ props.user.tagUsuario }</Trans> </div>
            </div>
            <div className="dataItem">
              <div className="tag"> <Trans>Company</Trans> </div>
              <div className="value"> { props.user.empresa.nombre } </div>
            </div>
          </div>
        </div>
      </div>
      <div className="actions">
        <Button variant="danger" onClick={ () => { loginService.logout(() => history.push("/login")); } }> <Trans>Log out</Trans> </Button>
      </div>
    </div>
  )
}

const mapStateToProps = ( state ) => {

  return {
    user: state.profile
  }

}

export default connect( mapStateToProps )(AccountCard)
