import React from 'react';
import { connect } from 'react-redux';

import environment from '../../../environment/environment';
import authService from '../../../services/Auth';

const Header = ( props ) => {

  let empresa
  if ( props.profile && props.profile.empresa ) empresa = props.profile.empresa._id

  return (
    <div className="header">
      <div className="logo"> 
        { empresa ? <img src={ environment.url + '/empresa/image/' + empresa + '/' + authService.getToken() } onError={ defaultImage } alt="company" /> : null }
      </div>
    </div>
  )
}

const defaultImage = ( ev ) => {
  ev.target.src = '/assets/img/logo_vetanco_white.svg'
}

const mapStateToProps = ( state ) => {

  return {
    profile: state.profile
  }

}

export default connect( mapStateToProps )(Header)
