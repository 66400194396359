import React from 'react';
import { withNamespaces } from 'react-i18next';

const XAxisTickComponent = props => {
    const {
      x, y, payload, forms
    } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} fill="#666">

          <tspan textAnchor="middle" x="0" dy="20"> { convertDateISO( forms.find( form => form._id === payload.value ).datosFormulario.fecha, props.t ) } </tspan>
        </text>
      </g>
    )

}

// <tspan textAnchor="middle" x="0"> { farms.find( farm => farm._id === forms.find( form => form._id === payload.value ).datosFormulario.granja._id ).nombre } </tspan>

function convertDateISO( data_in, t ){
    const keyMonth = {
      1: t("January"),
      2: t("February"),
      3: t("March"),
      4: t("April"),
      5: t("May"),
      6: t("June"),
      7: t("July"),
      8: t("August"),
      9: t("September"),
      10: t("October"),
      11: t("November"),
      12: t("December")
    }
  	const fecha = new Date(Date.parse(data_in));
  	const data_out = fecha.getDate() + " " + keyMonth[fecha.getMonth()+1].substr(0, 3)+" '"+fecha.getFullYear().toString().slice(-2);
  	return data_out;
  }

export default withNamespaces()(XAxisTickComponent)
