import React from 'react';
import { Select } from 'antd';
import { Trans } from 'react-i18next';

const { Option } = Select;

const PromotorsFilter = props => {

  const handerPromotors = promotorsSelected => {
    props.setPromotors( promotorsSelected )
  }

  return (
    <div className="filter initialPromotors">
      <div className="tag"> <Trans>{ props.type === 'initial' ? 'Initial Growth Promoter' : 'Final Growth Promoter' }</Trans> </div>
      <div className="selector">
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
          value={ props.promotors }
          onChange={ handerPromotors }
        >
          { props.list.map( p => {
            return <Option key={ p._id } value={ p._id } > { p.nombre } </Option>
            } )
        }
        </Select>
        <div className="noneAllSelector">
          <div className="all" onClick={ () => { props.setPromotors( props.list.map( p => p._id ) ) } }> <Trans>Select All</Trans> </div>
          <div className="separator"> | </div>
          <div className="none" onClick={ () => { props.setPromotors( [] ) } }> <Trans>None</Trans> </div>
        </div>
      </div>
    </div>
  )

}

export default PromotorsFilter
