import React from 'react';
import { withNamespaces } from 'react-i18next';

import { IconContext } from "react-icons";
import { TiInfoLarge } from "react-icons/ti";
import GraphProgress from './GraphProgress';
import ProgressTractos from './ProgressTractos';

const DigestiveSystemPercentage = ( props ) => {

  const indexValue = Math.round( props.formData.resultadosIndices.lesionesSistemaDigestivo )

  return (
    <div className="card digestiveSystemPercentage">
      <div className="head">
        <div className="title"> { props.t( 'Digestive System State' ) } </div>
        <div className="info">
          <div className="icon">
            <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
              <div>
                <TiInfoLarge />
              </div>
            </IconContext.Provider>
          </div>
          <div className="tooltip">
            <div className="title">{ props.t('Info') }</div>
            <hr />
            <div className="content">
                <span> { props.t( 'The digestive system status index takes into account all ' ) } </span>
                <span> <b> { props.t( 'gross lesions ' ) } </b> </span>
                <span> { props.t( 'observed to evaluate the integrity of the same.' ) } </span>
                <span> <b> { props.t( 'The closer to 100% the greater the integrity of the digestive system.' ) } </b> </span>
            </div>
          </div>
        </div>
      </div>

      <div className="data">
        <div className="rowContainer">
          <GraphProgress value={ indexValue } />
        </div>
        <ProgressTractos formData={ props.formData } />        
      </div>


    </div>
  )
}

export default withNamespaces()(DigestiveSystemPercentage)
