import React from 'react';
import { IconContext } from "react-icons";
import { AiOutlineFileExclamation } from "react-icons/ai";

const NoForms = ( props ) => {

  return (
    <div className="noForms">
      <div className="logo">
        <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
          <div>
            <AiOutlineFileExclamation />
          </div>
        </IconContext.Provider>
      </div>
      <div className="title"> ¡ Sin Formularios ! </div>
      <div className="message">
        <p> Todavía no fue enviado ningun formulario. </p>
        <p> Podrás ver todos los resultados de los formularios enviados en esta sección. </p>
      </div>
    </div>
  )
}


export default NoForms
