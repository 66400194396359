import notificationsService from '../services/Notifications';

const getNotifications = () => {

  return async ( dispatch ) => {

    const response = await notificationsService.get();

    dispatch({
      type: 'GET_NOTIFICATIONS',
      payload: response.data.info
    })
  }

}

export default getNotifications;
