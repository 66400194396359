import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
import { withNamespaces } from 'react-i18next';

import { HiOutlinePencil } from 'react-icons/hi';

import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';

import 'react-leaflet';
import { Button } from 'react-bootstrap';

const NoPosition = ( props ) => {

  return (
    <div className="noPosition">
      <div className="container">
        <div className="image">
          <img src="/assets/img/icons/noZones.svg" alt="no Position"></img>
        </div>
        <div className="tag"> { props.t( 'This farm does not have a geographic position assigned' ) } </div>
        <div className="action">
          <Button onClick={ () => { props.setMode( 'EDIT_FARM_POSITION' ) } } > { props.t( 'Locate farm on map' ) } </Button>
        </div>
      </div>
    </div>
  )

}
 
const MapPosition = ( props ) => {

  const [ map, setMap ] = useState( null )

  useEffect( () => {

    if ( !map && props.farm.posicion && props.farm.posicion.latitud && props.farm.posicion.longitud ){

      const map = L.map('map', { center: [ props.farm.posicion.latitud, props.farm.posicion.longitud ], zoom: 7 })

      setMap( map )
  
      L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);
  
      if ( props.farms.length ){
        const markers = props.farms.map( f => {
          if ( f.posicion && f.posicion.latitud && f.posicion.longitud ){
  
            return L.marker([ f.posicion.latitud, f.posicion.longitud ])
                                                                        .bindPopup( f.nombre )
                                                                        .addTo( map );
  
          } else {
  
            return null
  
          }
        } )
      }

    }

  }, [ props.farm ]
 )

 useEffect( () => {

   if ( map && props.farm.posicion && props.farm.posicion.latitud && props.farm.posicion.longitud ) map.flyTo( [ props.farm.posicion.latitud, props.farm.posicion.longitud ] )

   }, [ props.farm ]
  )

 // FUNCION DE CREACION DE ZONA
  return (
    <div className="card map">
      <div className="mapContainer">
        <div id="map"></div>
        <div className="action">
          <Button onClick={ () => { props.setMode( 'EDIT_FARM_POSITION' ) } }> 
            <HiOutlinePencil />
            <span> { props.t('Edit') } </span> 
          </Button>
        </div>
      </div>
      {
        !props.farm.posicion || !props.farm.posicion.latitud || !props.farm.posicion.longitud ? <NoPosition setMode={ props.setMode } t={ props.t } /> : null
      }
    </div>
  )
}

export default withNamespaces()(MapPosition)
