import React from 'react';
import { IconContext } from "react-icons";
import { BsHouse } from "react-icons/bs";

import { Trans } from 'react-i18next';

const FarmCard = ( props ) => {

  return (
    <div className="card farm">
      <div className="icon">
        <div className="container">
          <IconContext.Provider value={{ size: "20px" }}>
            <div>
              <BsHouse />
            </div>
          </IconContext.Provider>
        </div>
      </div>
      <div className="data">
        <div className="item">
          <div className="tag"> <Trans>Name</Trans> </div>
          <div className="value"> { props.name } </div>
        </div>
        <div className="item">
          <div className="tag"> <Trans>ID</Trans> </div>
          <div className="value"> { props.renspa } </div>
        </div>
        <div className="item">
          <div className="tag"> <Trans>Surface</Trans> </div>
          <div className="value"> { props.m2 ? props.m2 + ' m2' : '-' } </div>
        </div>
        <div className="item">
          <div className="tag"> <Trans>Owner</Trans> </div>
          <div className="value"> { props.owner } </div>
        </div>
        <div className="item">
          <div className="tag"> <Trans>Type</Trans> </div>
          <div className="value"> { props.type } </div>
        </div>
        <div className="item">
          <div className="tag"> <Trans>Division</Trans> </div>
          <div className="value"> { props.division } </div>
        </div>
      </div>
    </div>
  )
}

export default FarmCard
