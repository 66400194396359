import i18n from '../i18n';
import authService from '../services/Auth';

const getProfile = () => {

  return async ( dispatch ) => {

    const response = await authService.getUserData()

    i18n.changeLanguage( response.data.info.idioma )

    dispatch({
      type: 'GET_PROFILE',
      payload: response.data.info
    })
  }

}

export default getProfile;
