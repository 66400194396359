import React, { useState, useEffect } from 'react';

import Swal from 'sweetalert2'

import Pagination from "react-js-pagination";

import usersService from '../../../../services/Users';

import { Trans } from 'react-i18next';
import { withNamespaces } from 'react-i18next';

// COMPONENTS

// ITEM LIST HEADER
const UserListItemHeader = () => {

  return (
    <div className="userListItem header">
      <div className="name"> <Trans>Name</Trans> </div>
      <div className="email"> <Trans>Email</Trans> </div>
      <div className="role"> <Trans>Role</Trans> </div>
      <div className="actions"> <img src="/assets/img/icons/trash.svg" alt="trash" /> </div>
    </div>
  )
}

// ITEM LIST DEL BODY
const UserListItem = ( props ) => {

  return (
    <div className="userListItem">
      <div className="name"> { props.name } </div>
      <div className="email"> { props.email } </div>
      <div className="role"> <Trans>{ parseRoleTag( props.role ) }</Trans> </div>
      <div className="actions" onClick={ () => deleteUser( [ props.userID ], props.reloadUsers, props.t ) }> <img src="/assets/img/icons/trash.svg" alt="trash" /> </div>
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const UserList = ( props ) => {

  // VARIABLES DE ESTADO DE PAGINACION Y FILTRO
  const [ activePage, setActivePage ] = useState( 1 );
  const [ filteredList, setFilteredList ] = useState( [] );
  const pageSize = 5;

  // HOOK PARA ACTUALIZACION DE LISTA DE USUARIOS EN FUNCION DEL FILTRO
  useEffect( () => {
    const fullList = props.users.map( user => {

      const fullName = `${ user.nombre } ${ user.apellido }`

      if ( fullName.toUpperCase().includes( props.filterString.toUpperCase() ) ){
        return <UserListItem t={ props.t } key={ user._id } userID={ user._id } name={ fullName } email={ user.email } role={ user.role } reloadUsers={ props.reloadUsers } />
      }

    } ).filter( data => data )

    setFilteredList( fullList );

  }, [ props.filterString ] )

  // RECORTO LA CANTIDAD DE REGISTRO AL TAMAÑO DE PAGINA
  const pageList = filteredList.slice( ( activePage - 1 ) * pageSize , ( activePage - 1 ) + pageSize);

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="userList">
      <div className="list">
        <UserListItemHeader />
        { pageList }
      </div>
      <div className="pagination" style={ filteredList.length <= pageSize ? { display: 'none' } : { } }>
        <Pagination
          activePage={ activePage }
          itemsCountPerPage={ pageSize }
          totalItemsCount={ filteredList.length }
          pageRangeDisplayed={ pageSize }
          onChange={ page => setActivePage( page ) }
          hideFirstLastPages={ true }
          prevPageText="«"
          nextPageText="»"
        />
      </div>
    </div>
  )
}

// FUNCTIONS

// BAJA DE USUARIO
const deleteUser = ( usersID, reloadUsers, t ) => {
  // DISPARO ALERT DE CONFIRMACION
  Swal.fire({
            title: t( 'Delete User' ),
            text: t( 'Are you sure you want to delete this user?' ),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: t( 'Cancel' ),
            confirmButtonText: t( 'Yes, delete it!' ),
            showLoaderOnConfirm: true,
            // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
            preConfirm: ( value ) => {
              return usersService.delete( usersID )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
            if ( result.value ) {
              Swal.fire(
                t( 'Deleted!' ),
                t( 'User has been deleted successfully.' ),
                'success'
              )

              reloadUsers();
            }
          })
}

const parseRoleTag = role => {

  let tag;
  switch (role) {
    case 'CARGA':
      tag = 'Data Loader';
      break;
    case 'SUPERVISOR':
      tag = 'Supervisor';
      break;
    case 'ADMINISTRADOR':
      tag = 'Admin';
      break;
    default:
      tag = ''
  }

  return tag

}

export default withNamespaces()(UserList)
