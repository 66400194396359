import React from 'react';
import {
  PieChart, Tooltip, Pie, Cell, ResponsiveContainer
} from 'recharts';

import { IconContext } from "react-icons";
import { TiInfoLarge } from "react-icons/ti";

import { withNamespaces } from 'react-i18next';

const COLORS = ['#0088FE', '#00C49F'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CompositionCard = ( props ) => {

  const intestinalInjuriesTerm = ( props.form.promedios.macroscopiaIntestinalContenido + props.form.promedios.macroscopiaIntestinalEnteritisNecrotica + props.form.promedios.macroscopiaIntestinalTono )
  const coccidiosis = Math.round( props.form.resultadosIndices.riesgoDeCoccidiosis );

  let data;
  if ( intestinalInjuriesTerm === 0 && coccidiosis === 0 ){

    return (
      <div className="card composition">
        <div className="head">
          <div className="title"> { props.t( 'Index Risk' ) } </div>
          <div className="info">
            <div className="icon">
              <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
                <div>
                  <TiInfoLarge />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tooltip">
              <div className="title">{ props.t('Info') }</div>
              <hr />
              <div className="content">
                <span> <b> { props.t( 'Indicates the relative impact of each index ' ) } </b> </span>
                <span> { props.t( 'for the construction of the risk of disbiosis.' ) } </span>
              </div>
            </div>
          </div>
        </div>

        <div className="success">
          <div className="icon">
            <img src="/assets/img/icons/success.svg" alt="succcess" />
          </div>
          <div className="message">
            { props.t( 'There is not any risk of disbiosis' ) }
          </div>
        </div>
      </div>
    )

  } else {
    data = [
      { name: props.t( 'Intestinal Injuries' ), value: 5 * intestinalInjuriesTerm },
      { name: props.t( 'Coccidiosis' ), value: 5 * coccidiosis }
    ]
  }

  return (
    <div className="card composition">
      <div className="head">
        <div className="title"> { props.t( 'Index Composition' ) } </div>
        <div className="info">
          <div className="icon">
            <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
              <div>
                <TiInfoLarge />
              </div>
            </IconContext.Provider>
          </div>
          <div className="tooltip">
              <div className="title">{ props.t('Info') }</div>
              <hr />
              <div className="content">
                <span> <b> { props.t( 'Indicates the relative impact of each index ' ) } </b> </span>
                <span> { props.t( 'for the construction of the risk of disbiosis.' ) } </span>
              </div>
            </div>
        </div>
      </div>
      <div className="rowContainer">
        <div className="graph">
          <ResponsiveContainer>
            <PieChart>
              <Pie
                  data={data}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  fill="#8884d8"
                  dataKey="value"
                >
                {
                  data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="references">
          <div className="reference intestinal">
            <div className="color"></div>
            <div className="tag"> { props.t( 'Intestinal Injuries' ) } </div>
          </div>
          <div className="reference coccidiosis">
            <div className="color"></div>
            <div className="tag"> { props.t( 'Coccidiosis' ) } </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(CompositionCard)
