import React from 'react'
import {
    PieChart, Pie, Cell
  } from 'recharts';

const GraphIntestinalQuality = props => {

    const indexValue = Math.round( props.value )
    const colors = getColors( indexValue )
    const data = [ {
        value: indexValue
    }, {
        value: 100 - indexValue
    } ]

    return (
        <div className="graph">
          <PieChart height={ props.size ? props.size : 250 } width={ props.size ? props.size : 250 }>
            <Pie
              data={ data }
              startAngle={225}
              endAngle={-45}
              innerRadius={ props.size ? props.size / 2 - 20 : 105 }
              outerRadius={ props.size ? props.size / 2 : 125 }
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {
                data.map((entry, index) => <Cell key={`cell-${index}`} fill={ colors[index % colors.length]} />)
              }
            </Pie>
          </PieChart>
          <div className="percentage">
            <span className="value" style={ props.size < 250 ? { fontSize: '60px' } : null }> { indexValue } </span>
            <span className="unit"> % </span>
          </div>
      </div>
    )
}

const getColors = value => {

  let color;
  if ( value <= 40 ){
    color = '#d9534f'
  } else if ( value <= 70 ){
    color = '#f0ad4e'
  } else {
    color = '#5cb85c'
  }

  return [ color, 'rgb(245,245,245)' ]
}

export default GraphIntestinalQuality