import Cookie from '../constants/cookie';
import env from '../environment/environment';
import Api from './Api';

const authService = {
  isAuthenticated: () => {
    if ( window.localStorage.getItem('t') ){
      return true
    } else {
      return false
    }
  },
  getToken: () => {
    return window.localStorage.getItem('t');
  },
  getUserData: (  ) => {

    return Api.get( '/usuario/self', {
      headers: {
        token: authService.getToken()
      }
    } )

  },
  updateLocale: ( newLocale ) => {
    return Api.put( '/usuario', { idioma: newLocale },{
      headers: {
        token: authService.getToken()
      }
    } )
  },
  updatePassword: ( passwordData ) => {
    return Api.post( '/usuario/password/cambio', passwordData, {
      headers: {
        token: authService.getToken()
      }
    })
  },
  updatePasswordOutside: ( passwordData ) => {
    return Api.post( '/usuario/password/cambio/outside', passwordData)
  },
  recoveryPasswordPetition: ( emailData ) => {
    return Api.post( '/usuario/password/recuperar', emailData)
  },
  ping: ( jsonCallbacks ) => {

    return fetch(
      `${ env.url }/ping`, 
      {
        credentials: 'same-origin',
        headers: {
          token: authService.getToken()
        }
      })
        .then( res => res.json() )
        .then( data => {
          window.localStorage.setItem('t', data.token );
          Cookie.set('t', data.token)
          if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success();
          return data
        })
        .catch( err => {
          if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
        })

  }
};

export default authService
