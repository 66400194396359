import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import { compose } from 'redux';
import { IconContext } from "react-icons";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { FaInfo } from "react-icons/fa";

import Pagination from "react-js-pagination";
import ZoneInfoModal from './ZoneInfoModal';

import zonesService from '../../../../services/Zones';
import { area, point } from 'turf';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

import { Trans } from 'react-i18next';
import { withNamespaces } from 'react-i18next';

// COMPONENTS

// ITEM LIST HEADER
const ZonesListItemHeader = (  ) => {
  return (
    <div className="zonesListItem header">
      <div className="name"> <Trans>Name</Trans> </div>
      <div className="email"> <Trans>Area</Trans> </div>
      <div className="language"> <Trans>Farms</Trans> </div>
      <div className="actions"> <img src="/assets/img/icons/trash.svg" alt="trash" /> </div>
    </div>
  )
}

// ITEM LIST DEL BODY
const ZonesListItem = ( props ) => {

  const farmsInside = props.farms.filter( farm => {

    if ( !farm.posicion ) return false
    
    const farmPoint = point([ farm.posicion.longitud, farm.posicion.latitud ])

    return props.geojson.features.some( feat => booleanPointInPolygon( farmPoint, feat ) )

  } )

  const surface = ( area( props.geojson ) / 1000000 ).toLocaleString() + ' km2';

  return (
    <div>
      <div className="zonesListItem">
        <div className="name" > { props.name } </div>
        <div className="email"> { surface } </div>
        <div className="language"> { farmsInside.length } </div>
        <div className="actions" >

          <div className="action info" onClick={ () => { props.setInfoModal( props.name, surface, farmsInside ) } }>
            <IconContext.Provider size={ 20 } value={{ className: "global-class-name" }}>
              <div>
                <FaInfo />
              </div>
            </IconContext.Provider>
          </div>
          <div className="action edit" onClick={ props.modifyHandler }>
            <IconContext.Provider size={ 20 } value={{ className: "global-class-name" }}>
              <div>
                <FiEdit2 />
              </div>
            </IconContext.Provider>
          </div>
          <div className="action remove" onClick={ () => deleteZone( [ props.zoneID ], props.reloadZones, props.t ) }>
            <IconContext.Provider size={ 20 } value={{ className: "global-class-name" }} >
              <div>
                <FiTrash />
              </div>
            </IconContext.Provider>
          </div>

        </div>
      </div>
    </div>
  )
}

// LISTA COMPLETA DE ZONAS
const ZonesList = ( props ) => {

  // VARIABLES DE ESTADO DE PAGINACION Y FILTRO
  const [ infoModal, setInfoModal ] = useState( {
                                                open: false,
                                                info: null
                                                } );
  const [ activePage, setActivePage ] = useState( 1 );
  const [ filteredList, setFilteredList ] = useState( [] );
  const pageSize = 5;

  const openInfoModal = ( zoneName, zoneSurface, farms ) => {
    setInfoModal( {
      open: true,
      info: {
        zoneName: zoneName,
        zoneSurface: zoneSurface,
        farms: farms
      }
    } )
  }

  // HOOK PARA ACTUALIZACION DE LISTA DE ZONAS EN FUNCION DEL FILTRO
  useEffect( () => {
    const fullList = props.zones.map( zone => {

      if ( zone.nombre.toUpperCase().includes( props.filterString.toUpperCase() ) ){
        return <ZonesListItem
                              t={ props.t }
                              key={ zone._id }
                              zoneID={ zone._id }
                              name={ zone.nombre }
                              geojson={ JSON.parse( zone.geojson ) }
                              farms={ props.farms }
                              reloadZones={ props.reloadZones }
                              setInfoModal={ openInfoModal }
                              modifyHandler={ () => { props.modifyZoneHandler( zone ) } }
                               />
                           } else {
                             return null
                           }

    } ).filter( data => data )

    setFilteredList( fullList );

  }, [ props.filterString ] )

  // RECORTO LA CANTIDAD DE REGISTRO AL TAMAÑO DE PAGINA
  const pageList = filteredList.slice( ( activePage - 1 ) * pageSize , ( activePage - 1 ) + pageSize);

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="zonesList">
      <div className="list">
        <ZonesListItemHeader />
        { pageList }
      </div>
      <div className="pagination" style={ filteredList.length <= pageSize ? { display: 'none' } : { } }>
        <Pagination
          activePage={ activePage }
          itemsCountPerPage={ pageSize }
          totalItemsCount={ filteredList.length }
          pageRangeDisplayed={ pageSize }
          onChange={ page => setActivePage( page ) }
          hideFirstLastPages={ true }
          prevPageText="«"
          nextPageText="»"
        />
      </div>
      <ZoneInfoModal infoModal={ infoModal } handlerClose={ () => { setInfoModal( { open: false, info: null } ) } }  />
    </div>
  )
}

// BAJA DE ZONA
const deleteZone = ( zonesID, reloadZones, t ) => {

  // DISPARO ALERT DE CONFIRMACION
  Swal.fire({
            title: t( 'Delete Zone' ),
            text: t( 'Are you sure to delete this zone from the space?' ),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: t( 'Cancel' ),
            confirmButtonText: t( 'Yes, delete it!' ),
            showLoaderOnConfirm: true,
            // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
            preConfirm: ( value ) => {
              return zonesService.delete( zonesID )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
            if ( result.value ) {
              Swal.fire(
                t( 'Zone Deleted!' ),
                t( 'The zone has been deleted successfully.' ),
                'success'
              )

              reloadZones();
            }
          })
}

const mapStateToProps = ( state ) => {

  return {
    farms: state.farms
  }

}

export default compose(withNamespaces(), connect( mapStateToProps ))(ZonesList)
