import React from 'react';
import GaugeChart from 'react-gauge-chart'
import { withNamespaces } from 'react-i18next';

import { IconContext } from "react-icons";
import { TiInfoLarge } from "react-icons/ti";

const SpeedometerCard = ( props ) => {

  return (
    <div className="card coccidiosisSpeedometer">
      <div className="head">
        <div className="title"> { props.t( 'Risk of Coccidiosis' ) } </div>
        <div className="info">
          <div className="icon">
            <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
              <div>
                <TiInfoLarge />
              </div>
            </IconContext.Provider>
          </div>
          <div className="tooltip">
            <div className="title">Info</div>
            <hr />
            <div className="content">
              <span> { props.t( 'The Coccidiosis Grade index takes into account macroscopic and microscopic lesions for establish the possibility of the appearance of a coccidiosis.' ) } </span>
              <span> <b> { props.t( 'Values ​​above 30% indicate the appearance of a subclinical coccidiosis while greater than 60% indicate a coccidiosis clinic.' ) } </b> </span>
            </div>
          </div>
        </div>
      </div>

      <div className="graph">
        <GaugeChart id="gauge-chart2"
          nrOfLevels={20}
          colors={["#5cb85c", "#d9534f"]}
          percent={ props.value }
          textColor="rgb(45,45,60)"
          needleColor="rgba(0,0,0,0.25)"
        />
      </div>

    </div>
  )
}

export default withNamespaces()(SpeedometerCard)
