import React, { useState } from 'react';

import { withNamespaces } from 'react-i18next';

const Filter = ( props ) => {

  // VARIABLES DE ESTADO PARA MOSTRAR MODAL DE NUEVA ZONA Y FILTRO
  const [ filterString, setFilterString ] = useState( '' );

  // HANDLER AL CAMBIAR EL VALOR DEL INPUT DE FILTRO
  const handleChange = ( event ) => {
    const value = event.target.value;
    setFilterString( value );

    props.filterChangeHandler( value );
  }

  // RENDERIZADO DE COMPONENTE
  return (
    <div className="filter">
      <div className="actions">
        <div className="search">
          <input name="user" placeholder={ props.t( 'Search...' ) } value={ filterString } onChange={ handleChange } />
        </div>
        <div className="options">
          <div className="newZone" onClick={ props.createZoneHandler } > <img src="/assets/img/icons/newZone.svg" alt="newZone" /></div>
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(Filter)
