import React from 'react';
import GaugeChart from 'react-gauge-chart'

import { IconContext } from "react-icons";
import { TiInfoLarge } from "react-icons/ti";

import { withNamespaces } from 'react-i18next';

const SpeedometerCard = ( props ) => {

  return (
    <div className="card clostridiosisSpeedometer">
      <div className="head">
        <div className="title"> { props.t( 'Risk of Disbiosis' ) } </div>
        <div className="info">
          <div className="icon">
            <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
              <div>
                <TiInfoLarge />
              </div>
            </IconContext.Provider>
          </div>
          <div className="tooltip">
            <div className="title">{ props.t('Info') }</div>
            <hr />
            <div className="content">
                <span> { props.t( 'The risk index for disbiosis takes into account ' ) } </span>
                <span> <b> { props.t( 'gross lesions of the intestine, the state of the intestinal contents and the presence of coccidia ' ) } </b> </span>
                <span> { props.t( 'to estimate the possibility of a dysbiosis and consequent appearance of necrotic enteritis.' ) } </span>
                <span> <b> { props.t( 'Values ​​for above 60% indicate a great possibility of dysbiosis.' ) } </b> </span>
            </div>
          </div>
        </div>
      </div>

      <div className="graph">
        <GaugeChart id="clostridiosis-gauge-chart2"
          nrOfLevels={20}
          colors={["#5cb85c", "#d9534f"]}
          percent={ props.value }
          textColor="rgb(45,45,60)"
          needleColor="rgba(0,0,0,0.25)"
        />
      </div>

    </div>
  )
}

export default withNamespaces()(SpeedometerCard)
