import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {  Link } from 'react-router-dom';
import env from '../environment/environment';
import getProfile from '../actions/getProfile';
import permisos from '../constants/permisos';

const IndexPage = ( { profile, getProfile } ) => {

  useEffect( () => { getProfile() }, [] )
  
  return (
    <div className="indexPage">
      <div className="header">
        <div className="logo vetanco"> <img src="assets/img/logo_vetanco_white.svg" alt="vetanco" /> </div>
        <div className="logo empresa"></div>
      </div>
      <div className="tools-conatiner">
        <Link to="/main">
          <div className="tool VIIP">
            <div className="icon"> <img src="assets/img/viip-logo.png" alt="viip" /> </div>
            <div className="name"> VIIP </div>
            <div className="message"> Programa para evaluar la calidad intestinal. </div>
          </div>
        </Link> 
        <a href="https://vetancid-es.web.app">
          <div className="tool vetancid">
            <div className="icon"> <img src="assets/img/vetancid.png" alt="vetancid" /> </div>
            <div className="name"> Vetancid </div>
            <div className="message"> Programa para evaluar la infestación de Alphitobius Diaperinus </div>
          </div>
        </a>     
        {
          profile && permisos.validatePermisos( 'ACCESO_MICOTOXINAS', profile?.funcionalidades ) &&
          <a href={ env.micotoxinas_url }>
            <div className="tool VIIP">
              <div className="icon"> <img src="assets/img/micotoxinas.png" alt="micotoxinas" /> </div>
              <div className="name"> Micotoxinas </div>
              <div className="message"> Programa para realizar análisis de micotoxinas. </div>
            </div>
          </a>   
        }
      </div>
    </div>
  )

}

export default connect( state => ({ profile: state.profile }), { getProfile } )( IndexPage )
