import React, { useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import { withNamespaces } from 'react-i18next';

const GraphComposition = props => {

  const perfectStateBool =  props.formData.promedios.digestivoHigado === 0 &&
                            props.formData.promedios.digestivoPancreas === 0 &&
                            props.formData.promedios.macroscopiaIntestinalIntestinoDelgado === 0 &&
                            props.formData.promedios.macroscopiaIntestinalIntestinoGrueso === 0 &&
                            props.formData.promedios.macroscopiaIntestinalCiegos === 0 && 
                            props.formData.promedios.digestivoCavidadBucal === 0 &&
                            props.formData.promedios.digestivoProventriculo === 0 &&
                            props.formData.promedios.digestivoMolleja === 0

    let data = [
        {
          item: props.t('Liver'),
          value: perfectStateBool ? 25 : props.formData.promedios.digestivoHigado * 25,
          color: '#f9c74f',
          multiplicador: 25
        },
        {
          item: props.t('Pancreas'),
          value: perfectStateBool ? 50 : props.formData.promedios.digestivoPancreas * 50,
          color: '#90be6d',
          multiplicador: 50
        },
        {
          item: props.t('Small intestine'),
          value: perfectStateBool ? 20 : props.formData.promedios.macroscopiaIntestinalIntestinoDelgado * 20,
          color: '#43aa8b',
          multiplicador: 20
        },
        {
          item: props.t('Large intestine'),
          value: perfectStateBool ? 17 : props.formData.promedios.macroscopiaIntestinalIntestinoGrueso * 17,
          color: '#577590',
          multiplicador: 17
        },
        {
          item: props.t('Ceca'),
          value: perfectStateBool ? 25 : props.formData.promedios.macroscopiaIntestinalCiegos * 25,
          color: '#002855',
          multiplicador: 25
        },
        {
          item: props.t('Oral cavity'),
          value: perfectStateBool ? 67 : props.formData.promedios.digestivoCavidadBucal * 67,
          color: '#f94144',
          multiplicador: 67
        },
        {
          item: props.t('Proventriculus'),
          value: perfectStateBool ? 67 : props.formData.promedios.digestivoProventriculo * 67,
          color: '#f3722c',
          multiplicador: 67
        },
        {
          item: props.t('Gizzard'),
          value: perfectStateBool ? 33 : props.formData.promedios.digestivoMolleja * 33,
          color: '#f8961e',
          multiplicador: 33
        }
      ].filter( d => d.value ) // FILTRO AQUELLOS DATOS QUE TENGAN DATO SOLAMENTE

    const [ activeIndex, setActiveIndex ] = useState( 2 )

    const onPieEnter = (data, index) => {
        setActiveIndex( index )
    };

    return (
        <div className="composition">
            <div className="references">
            {
                data.map( item => {
                return (
                    <div className="reference" key={ item.item }>
                    <div className="color" style={{ background: item.color }}></div>
                    <div className="tag"> { item.item } </div>
                    </div>
                )
                } )
            }
            </div>
            <div className="graph radar">
            <ResponsiveContainer>
                <PieChart 
                    height={ 250 }>
                <Pie
                    activeIndex={ props.activeIndexArray ? props.activeIndexArray : activeIndex }
                    activeShape={ renderActiveShape }
                    data={data}
                    innerRadius={60}
                    outerRadius={80}
                    dataKey="value"
                    onMouseEnter={ onPieEnter }
                >
                    {
                    data.map((entry, index) => <Cell key={ index } fill={data[index % data.length].color}/>)
                    }
                </Pie>
                </PieChart>
            </ResponsiveContainer>
            </div>
        </div>
    )
}


const renderActiveShape = (props) => {

    const RADIAN = Math.PI / 180;
    const {
      cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
      fill, payload, percent, item
    } = props;
  
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={ fill }>{ payload.name }</text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={ payload.color }
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={ payload.color }
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={ payload.color } stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="rgb(45,45,60)">{`${ item }`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  };
  

export default withNamespaces()(GraphComposition)

