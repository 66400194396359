import Api from './Api';
import authService from './Auth';

const zonesService = {

  get: (  ) => {

    return Api.get( '/zona', {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  post: ( jsonData, jsonCallbacks ) => {

    if ( jsonCallbacks ) if ( jsonCallbacks.beforeSend ) jsonCallbacks.beforeSend();

    return Api.post( '/zona', jsonData, {
      headers: {
        token: authService.getToken()
      }
    } ).then( data => {

      if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success( data.data.info );
    } ).catch( () => {
      if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
    } )
  },
  put: ( zoneID, jsonData, jsonCallbacks ) => {

    if ( jsonCallbacks ) if ( jsonCallbacks.beforeSend ) jsonCallbacks.beforeSend();

    return Api.put( '/zona/' + zoneID, jsonData, {
      headers: {
        token: authService.getToken()
      }
    } ).then( data => {

      if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success( data.data.info );
    } ).catch( () => {
      if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
    } )
  },
  delete: ( zonesID, jsonCallbacks ) => {

    return Api.delete( `/zona?id=${ JSON.stringify( zonesID ) }`, {
      headers: {
        token: authService.getToken()
      }
    } ).then( data => {

      if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success( data.data.info );
    } ).catch( () => {
      if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
    } )
  }

}

export default zonesService
