import usersService from '../services/Users';

const getUsers = () => {

  return async ( dispatch ) => {

    const response = await usersService.get();

    dispatch({
      type: 'GET_USERS',
      payload: response.data.info
    })
  }

}

export default getUsers;
