import React, { useState } from 'react';
import { Switch } from 'antd';
import { withNamespaces } from 'react-i18next';
import { Menu, Dropdown, Button } from 'antd';


const BodyActivated = ( props ) => {

  const [ value, setValue ] = useState( props.alarmData ? props.alarmData.valor : undefined )
  const [ operator, setOperator ] = useState( props.alarmData ? props.alarmData.regla : '<=' )

  const menu = (
    <Menu>
      <Menu.Item onClick={ () => { setOperator('>') } }>
        { props.t( 'More than' ) }
      </Menu.Item>
      <Menu.Item onClick={ () => { setOperator('>=') } }>
        { props.t( 'More or Equal than' ) }
      </Menu.Item>
      <Menu.Item onClick={ () => { setOperator('<') } }>
        { props.t( 'Less than' ) }
      </Menu.Item>
      <Menu.Item onClick={ () => { setOperator('<=') } }>
        { props.t( 'Less or Equal than' ) }
      </Menu.Item>
      <Menu.Item onClick={ () => { setOperator('==') } }>
        { props.t( 'Equal to' ) }
      </Menu.Item>
      <Menu.Item onClick={ () => { setOperator('!=') } }>
        { props.t( 'Distinct of' ) }
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="body">
      <div className="message">
        { props.t( 'I want to recive notifications when any form activate this rule.' ) }
      </div>
      <div className="rule">
        <div className="tag">
         { props.title }
        </div>
        <div className="operator" value={ operator }>
          <Dropdown overlay={menu} placement="topCenter" trigger={['click']}>
           <Button> { parseOperator( operator, props.t ) } </Button>
          </Dropdown>
        </div>
        <div className="value">
         <input type="number" value={ value } onChange={ ( event ) => { setValue( event.target.value ) } } />
        </div>
      </div>
    </div>
  )
}

const BodyDeactivated = ( props ) => {
  return (
    <div className="body">
      <div className="message">
        { props.t( 'I do not want to recive notifications related to this section.' ) }
      </div>
    </div>
  )
}

const AlarmCard = ( props ) => {

  const alarmActivated = props.alarmData ? props.alarmData.activado : false;

  const [ activated, setActivated ] = useState( alarmActivated )

  return (
    <div className="card alarm" value={ props.code }>
      <div className="header">
        <div className="title"> { props.title } </div>
        <div className="activation">
          <Switch
                  checkedChildren={ props.t( 'Activated' ) }
                  unCheckedChildren={ props.t( 'Deactivated' ) }
                  size="medium"
                  checked={ activated }
                  onChange={ setActivated }
                  />
        </div>
      </div>
      {
        activated
         ?
         <BodyActivated t={ props.t } title={ props.title } unit={ props.unit } alarmData={ props.alarmData } />
         :
         <BodyDeactivated t={ props.t } />
      }
    </div>
  )
}

const parseOperator = ( operatorCode, t ) => {

  switch (operatorCode) {
    case '<':
      return t('Less than')
    case '<=':
      return t('Less or Equal than')
    case '>':
      return t('More than')
    case '>=':
      return t('More or Equal than')
    case '==':
      return t('Equal to')
    case '!=':
      return t('Distinct of')
    default:

  }
}

export default withNamespaces()(AlarmCard)
