import React from 'react';

import { IconContext } from "react-icons";
import { TiInfoLarge } from "react-icons/ti";

import { withNamespaces } from 'react-i18next';
import GraphMacroscopy from './GraphMacroscopy';

const MacroscopyCard = ( props ) => {

  return (
    <div className="card macroscopia">
        <div className="head">
          <div className="title"> { props.t( 'Macroscopia' ) } </div>
          <div className="info">
            <div className="icon">
              <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
                <div>
                  <TiInfoLarge />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tooltip">
              <div className="title">Info</div>
              <hr />
              <div className="content">
                <span> { props.t( 'Coccidiosis macroscopic evaluation score ' ) } </span>
                <span> <b> { props.t( 'adapted from Johnson & Reid ' ) } </b> </span>
                <span> { props.t( 'for duodenum, jejunum and cecum, ' ) } </span>
                <span> <b> { props.t( 'taking values ​​from 0 to 4 according to the severity of the lesions observed, in where 0 is no injuries and 4 highly serious injuries.' ) } </b> </span>
              </div>
            </div>
          </div>
        </div>
        <GraphMacroscopy form={ props.form } />
    </div>
  )
}

export default withNamespaces()(MacroscopyCard)
