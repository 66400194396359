import React from 'react';

import SpeedometerCard from './SpeedometerCard';
import MacroscopyCard from './MacroscopyCard';
import MicroscopyCard from './MicroscopyCard';

import { withNamespaces } from 'react-i18next';

import './coccidiosis.css'

const CoccidiosisSection = ( props ) => {
  return (
    <div className="section coccidiosis" id="coccidiosisSection">
      <div className="title"> { props.t( 'Coccidiosis' ) } </div>
      <div className="rowContainer">
        <SpeedometerCard value={ Math.round( props.form.resultadosIndices.riesgoDeCoccidiosis ) / 100 } />
        <MacroscopyCard form={ props.form } />
        <MicroscopyCard form={ props.form } />
      </div>
    </div>
  )
}

export default withNamespaces()(CoccidiosisSection)
