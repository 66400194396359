import React from "react";
import { withNamespaces } from 'react-i18next';
import { MdPictureAsPdf } from 'react-icons/md';
import GaugeChart from 'react-gauge-chart'

import GraphIntestinalQuality from '../summery/Graph';
import GraphProgress from '../digestiveSystem/GraphProgress';
import ProgressTractos from '../digestiveSystem/ProgressTractos';
import GraphComposition from '../digestiveSystem/GraphComposition';
import GraphMicroscopy from '../coccidiosis/GraphMicroscopy';
import GraphMacroscopy from '../coccidiosis/GraphMacroscopy';
import env from '../../../../../environment/environment';
import authService from "../../../../../services/Auth";

import reportStyle from './reportStyle';
import { downloadBlob } from "./GeneralModalBody";

const SpecificModalBody = props => {

    const user = props.users.find( u => u._id === props.form.datosFormulario.usuario )
    const username = user ? (user.nombre + ' ' + user.apellido) : '';
    const date = props.form.datosFormulario.fecha.split( 'T' )[ 0 ]
    const age = props.form.datosFormulario.edad ? ( props.form.datosFormulario.edad % 7 === 0 ? (props.form.datosFormulario.edad / 7) + ' semanas' : props.form.datosFormulario.edad + ' dias' ) : '-' 
    const initial_promotor = props.products.find( p => p.type === 'PROMOTOR_CRECIMIENTO' && p._id === props.form.datosFormulario.promotorInicial._id )
    const final_promotor = props.products.find( p => p.type === 'PROMOTOR_CRECIMIENTO' && p._id === props.form.datosFormulario.promotorFinal._id )
    const initial_cocci = props.products.find( p => p.type === 'COCCIDRATO' && p._id === props.form.datosFormulario.coccidratoInicial._id )
    const final_cocci = props.products.find( p => p.type === 'COCCIDRATO' && p._id === props.form.datosFormulario.coccidratoFinal._id )
  
    const downloadReport = () => {

        const content = `
                        <html>
                        <head>
                          ${ reportStyle }
                          <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
                        </head>
                        <body>
                          <div class="report">${ document.querySelector('.modalContainer.report').innerHTML } </div>
                        </body>
                        </html>
                        `
    
        fetch( `${ env.url }/files/report` ,{
            body: JSON.stringify({content}),
            method: 'POST',
            headers: { "Content-Type": "application/json" }
        })
        .then( res => res.blob() )
        .then( blob => downloadBlob( blob ) )
    
      }

    return (
        <div className="modalContainer">
            <div className="download" onClick={ downloadReport }>
            <div className="icon"> <MdPictureAsPdf /> </div>
            <div className="tag"> { props.t( 'Generate PDF' ) } </div>
            </div>
            <div className="modalContainer report">
                <div className="title">
                { props.t( 'Intestinal quality survey report' ) }
                </div>
                <div className="company"> { props.profile.empresa.nombre } </div>
                <div className="farm"> { props.t( 'Farm' ) } { props.form.datosFormulario.granja.nombre } </div>
                <div className="general">
                <div className="subtitle"> { props.t( 'AVERAGE RESULTS' ) } </div>
                <div className="item">
                    <div className="tag">{ props.t( 'Period surveyed' ) }: </div>
                    <div className="value"> { date } </div>
                </div>
                <div className="item">
                    <div className="tag">{ props.t( 'User' ) }: </div>
                    <div className="value"> { username } </div>
                </div>
                <div className="item">
                    <div className="tag">Galpón: </div>
                    <div className="value"> { props.form.datosFormulario.galpon ? props.form.datosFormulario.galpon : '-'} </div>
                </div>
                <div className="item">
                    <div className="tag">{ props.t( 'Age of the birds' ) }: </div>
                    <div className="value"> { age } </div>
                </div>
                </div>

                <div className="vacuna">
                <div className="item">
                    <div className="tag">{ props.t( 'Received Biotech Vac plan' ) }: </div>
                    <div className="value"> { props.form.datosFormulario.vacunacion } </div>
                </div>
                </div>

                <div className="alimentacion">
                <div className="promotor-crecimiento">
                    <div className="subtitle">{ props.t( 'Growth Promoters' ) }</div>
                    <div className="items">
                    <ul>
                        <li>
                        <div className="item">
                            <div className="tag">{ props.t( 'Initial' ) }: </div>
                            <div className="value"> { initial_promotor ? initial_promotor.nombre : '-' } </div>
                        </div>
                        </li>
                        <li>
                        <div className="item">
                            <div className="tag">{ props.t( 'Final' ) }: </div>
                            <div className="value"> { final_promotor ? final_promotor.nombre : '-' } </div>
                        </div>
                        </li>
                    </ul>
                    </div>
                </div>
                <div className="coccidiostatos">
                    <div className="subtitle">{ props.t( 'Coccidiostatos' ) }</div>
                    <div className="items">
                    <ul>
                        <li>
                        <div className="item">
                            <div className="tag">{ props.t( 'Initial' ) }: </div>
                            <div className="value"> { initial_cocci ? initial_cocci.nombre : '-' } </div>
                        </div>
                        </li>
                        <li>
                        <div className="item">
                            <div className="tag">{ props.t( 'Final' ) }: </div>
                            <div className="value"> { final_cocci ? final_cocci.nombre : '-' } </div>
                        </div>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
            
                <div className="resultados">
                <div className="subtitle">{ props.t( 'Results' ) }</div>
                <div className="summery-message">
                    { props.t( 'The results are an average of ' ) } { props.form.cantidadAves } { props.t( 'necropsies performed.' ) }
                </div>

                <div className="sections">

                    <div className="section quality">
                    <div className="name">
                        { props.t( 'Overall Bowel Quality' ) }
                    </div>
                    <div className="info">
                        <div className="graph">

                        <GraphIntestinalQuality value={ props.form.resultadosIndices.calidadIntestinal } size={ 200 } />

                        </div>
                        <div className="description">
                            <span> { props.t( 'The general state of intestinal quality can be observed through the intestinal quality index, which takes into account ' ) } </span>
                            <span> <b> { props.t( 'all the parameters evaluated ' ) } </b> </span>
                            <span> { props.t( 'and indicates the general integrity of the digestive system, ' ) } </span>
                            <span> <b> { props.t( 'maintaining an integrity above 70% it will be more feasible to achieve the objectives productive.' ) } </b> </span>
                            <p className="description-result"> { props.t( 'For the survey carried out, it was ' ) } { Math.round( props.form.resultadosIndices.calidadIntestinal ) }%. </p>
                        </div>
                    </div>
                    </div>

                    <div className="section digestive">
                    <div className="name">
                        { props.t( 'Digestive System State' ) }
                    </div>
                    <div className="info">
                        <div className="graph digestive">
                        <GraphProgress value={ props.form.resultadosIndices.lesionesSistemaDigestivo } />
                        <ProgressTractos formData={ props.form } notInfo={ true } />
                        </div>
                        <div className="description">
                            <span> { props.t( 'The digestive system is evaluated through macroscopic lesions of the different anatomical structures, both at the upper level, which assesses lesions of ' ) } </span>
                            <span> <b>  { props.t( 'the oral cavity, proventriculus, gizzard, liver and pancreas,' ) } </b> </span>
                            <span>{ props.t( 'and at the lower level, which assesses lesions of ' ) } </span>
                            <span> <b> { props.t( 'the intestines and caeca , as well as the state of the intestinal content.' ) } </b> </span>
                            <span> { props.t( 'In turn, due to its importance for productive performance, we can observe ' ) } </span>
                            <span> <b>  { props.t( 'the general quality of the liver in the birds evaluated.' ) } </b> </span>
                        </div>
                        <div className="description space-40">
                            { props.t( 'Next, we can observe the distribution and prevalence of the lesions observed in the surveyed birds.' ) }
                        </div>
                        <div className="graph distribution">
                        <GraphComposition formData={ props.form } activeIndexArray={ [0,1,2,3,4,5,6,7] } />
                        </div>
                    </div>
                    </div>

                    <div className="section cocci">
                    <div className="name">
                        { props.t( 'Coccidiosis' ) }
                    </div>
                    <div className="info">
                        <div className="description">
                            { props.t( 'The risk of presenting coccidiosis, both clinical and subclinical, is evaluated by means of macroscopic lesions at the intestinal level and the count of oocysts at the microscopic level.' ) }
                        </div>
                        <div className="rowContainer">
                        <div className="graph">
                            <GraphMacroscopy form={ props.form } />
                        </div>
                        <div className="description">
                            <span> { props.t( 'Coccidiosis macroscopic evaluation score ' ) } </span>
                            <span> <b> { props.t( 'adapted from Johnson & Reid ' ) } </b> </span>
                            <span> { props.t( 'for duodenum, jejunum and cecum, ' ) } </span>
                            <span> <b> { props.t( 'taking values ​​from 0 to 4 according to the severity of the lesions observed, in where 0 is no injuries and 4 highly serious injuries.' ) } </b> </span>
                        </div>
                        </div>
                        <div className="rowContainer">
                        <div className="description">
                            <span> { props.t( 'Microscopic evaluation score using the ' ) } </span>
                            <span> <b> { props.t( 'serial scraping of the intestinal mucosa ' ) } </b> </span>
                            <span> { props.t( 'technique and observing the presence of oocysts.' ) } </span>
                            <span> <b> { props.t( 'It takes values ​​from 0 to 4 where 0 is the absence of oocysts and 4 is uncountable number of them.' ) } </b> </span>
                        </div>
                        <div className="graph">
                            <GraphMicroscopy form={ props.form } />
                        </div>
                        </div>
                    </div>
                    <div className="risk">
                        <div className="subtitle">{ props.t( 'Risk of Coccidiosis' ) }</div>
                        <div className="description">
                            { props.t( 'Finally, with all the aforementioned values, the coccidia risk index is constructed, which indicates the possibility of the appearance of clinical or subclinical coccidiosis. Values ​​above 30% indicate the appearance of subclinical coccidiosis while values ​​above 60% indicate clinical coccidiosis.' ) }
                        </div>
                        <div className="graph">
                        <GaugeChart id="gauge-chart3"
                            nrOfLevels={20}
                            colors={["#5cb85c", "#d9534f"]}
                            percent={ Math.round( props.form.resultadosIndices.riesgoDeCoccidiosis ) / 100 }
                            textColor="rgb(45,45,60)"
                            needleColor="rgba(0,0,0,0.25)"
                        />
                        </div>
                        <div className="description-result">
                            { props.t( 'For the birds evaluated, the risk index is ' ) } { Math.round( props.form.resultadosIndices.riesgoDeCoccidiosis ) }%.
                        </div>
                    </div>
                    </div>

                    <div className="section clostridiosis">
                    <div className="name">
                        { props.t( 'Disbiosis' ) }
                    </div>
                    <div className="info">
                        <div className="description">
                            { props.t( 'Taking into account the macroscopic lesions and the possibility of risk of coccidiosis, a clostridiosis risk index is constructed, which establishes the possibility of suffering from dysbiosis with the consequent subclinical or clinical clostridiosis.' ) }
                        </div>
                        <div className="risk">
                        <div className="graph">
                            <GaugeChart id="gauge-chart4"
                                nrOfLevels={20}
                                colors={["#5cb85c", "#d9534f"]}
                                percent={ Math.round( props.form.resultadosIndices.riesgoDeClostridiosis ) / 100 }
                                textColor="rgb(45,45,60)"
                                needleColor="rgba(0,0,0,0.25)"
                            />
                        </div>
                        <div className="description-result">
                            { props.t( 'For the birds evaluated, the risk index is ' ) } { Math.round( props.form.resultadosIndices.riesgoDeClostridiosis ) }%.
                        </div>
                        </div>
                    </div>
                    </div>
                    
                    <div className="section observations">
                    <div className="name">
                        { props.t( 'General observations of the survey' ) }
                    </div>
                    <div className="description">
                        { props.form.datosFormulario.observaciones ? props.form.datosFormulario.observaciones : 'No se registraron observaciones' }
                    </div>
                    </div>

                    <div className="section observations">
                    <div className="name">
                        { props.t( 'Photos uploaded during the survey' ) }
                    </div>
                    <div className="description">
                        { 
                        props.form.datosFormulario.savedPhotos && props.form.datosFormulario.savedPhotos.length 
                        ? 
                        props.form.datosFormulario.savedPhotos.map( ( photo, index ) => {
                        return (
                            <div className="photo" key={ index }>
                            <img src={ `${ env.url }/formulario/image/${ photo }/${ props.form.datosFormulario.usuario._id }/${ props.form._id }/${ authService.getToken() }` } alt="mobile" />
                            </div>
                        )
                        })
                        : 
                        <div>{ props.t( 'No observations recorded' ) }</div>
                        }
                    </div>
                    </div>

                    <div className="closure-message">
                        { props.t( 'This report is a summary of the parameters evaluated and uploaded to the Vetanco Instinal Quality Monitoring APP. If you have any questions about its interpretation, please contact your Vetanco technician.' ) }
                    </div>

                </div>
                </div> 
            
            </div>

        </div>
    )

}

export default withNamespaces()(SpecificModalBody)