import React from 'react';

import { connect } from 'react-redux';

const PoweredBy = ( props ) => {
  return (
    <div className="poweredby">
      <div className="tag"> powered by </div>
      <div className="vetanco"> <img src="/assets/img/logo_vetanco_white.svg" alt="logo" /> </div>
    </div>
  )
}

export default PoweredBy
