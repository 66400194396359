import React from 'react';
import { Rectangle } from 'recharts';

const BarComponent = props => {

  const { id, selected } = props;
  let fill, stroke;

  if ( id === selected._id ){
    fill = 'rgb(24,114,255)';
    stroke = '#ffffff'
  } else {
    fill='#c2c6dc';
    stroke = '000000';
  }

  return <Rectangle { ...props } fill={ fill } stroke={ stroke } />
}

export default BarComponent
