import React from 'react';
import { withNamespaces } from 'react-i18next';

import Button from 'react-bootstrap/Button';

import authService from '../../../../services/Auth';

import Swal from 'sweetalert2';

const PasswordCard = ( props ) => {

  const updatePassword = () => {

    const actualPassword = document.getElementById( 'actualPassword' ).value;
    const newPassword = document.getElementById( 'newPassword' ).value;
    const confirmNewPassword = document.getElementById( 'confirmNewPassword' ).value;

    if ( !actualPassword || !newPassword || !confirmNewPassword ){

      Swal.fire({
        icon: 'warning',
        title: props.t( 'Incompleted Fields!' ),
        text: props.t( 'Please complete all passwords to update it.' )
      })

      return;
    }

    if ( newPassword !== confirmNewPassword ){

      Swal.fire({
        icon: 'warning',
        title: props.t( 'Uncompatible passwords!' ),
        text: props.t( 'Your new password and the confirmation password are uncompatible.' )
      })

      return;
    }

    if ( newPassword === actualPassword ){

      Swal.fire({
        icon: 'warning',
        title: props.t( 'Same Password!' ),
        text: props.t( 'Your new password and the new password cannot be the same.' )
      })

      return;
    }


    Swal.fire ({
       title: props.t( '..Updating your password..' ),
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    authService.updatePassword( {
      passwordActual: actualPassword,
      passwordNueva: newPassword
    } ).then( data => {
      Swal.fire({
        icon: 'success',
        title: props.t( 'Password Updated!' ),
        text: props.t( 'Your password has been changed successfully.' )
      })

      document.getElementById( 'actualPassword' ).value = null;
      document.getElementById( 'newPassword' ).value = null;
      document.getElementById( 'confirmNewPassword' ).value = null;

    }, error => {
      Swal.fire({
        icon: 'error',
        title: props.t( 'Incorrect Password!' ),
        text: props.t( 'Your actual password is wrong.' )
      })
    } )

  }

  return (
    <div className="card password">
      <div className="title"> { props.t( 'Password' ) } </div>
      <div className="content">
        <div className="column">
          <div className="line actualPassword">
            <div className="form-input">
              <input id="actualPassword" type="password" name="password" placeholder={ props.t( 'Actual Password' ) } />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="line newPassword">
            <div className="form-input">
              <input id="newPassword" type="password" name="password" placeholder={ props.t( 'New Password' ) } />
            </div>
          </div>
          <div className="line confirmNewPassword">
            <div className="form-input">
              <input id="confirmNewPassword" type="password" name="password" placeholder={ props.t( 'Confirm New Password' ) } />
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Button size="sm" onClick={ updatePassword }>{ props.t( 'Update' ) }</Button>
      </div>
    </div>
  )
}


export default withNamespaces()(PasswordCard)
