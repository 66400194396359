export const getClosestElementInArray = ( array, goal ) => {
    
    return array.reduce(function(prev, curr) {
        return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
      });

}

export const getUniques = ( array ) => {

  return array.filter( function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  } )

}

export const datediff = (first, second) => {
  return Math.round((second-first)/(1000*60*60*24));
}

export const getFormsAverage = formsArray => {
  let average = {
    promedios: {

    },
    resultadosIndices: {

    }
  };

  for ( let form in formsArray ){
    for ( let promedioItem in formsArray[ form ].promedios ){
      average.promedios[ promedioItem ] = average.promedios[ promedioItem ] ? average.promedios[ promedioItem ] + formsArray[ form ].promedios[ promedioItem ] : formsArray[ form ].promedios[ promedioItem ];
    }

    for ( let resultadoItem in formsArray[ form ].resultadosIndices ){
      average.resultadosIndices[ resultadoItem ] = average.resultadosIndices[ resultadoItem ] ? average.resultadosIndices[ resultadoItem ] + formsArray[ form ].resultadosIndices[ resultadoItem ] : formsArray[ form ].resultadosIndices[ resultadoItem ];
    }
  }


  for ( let promedioItem in average.promedios ){
    average.promedios[ promedioItem ] = average.promedios[ promedioItem ] / formsArray.length
  }

  for ( let resultadoItem in average.resultadosIndices ){
    average.resultadosIndices[ resultadoItem ] = average.resultadosIndices[ resultadoItem ] / formsArray.length
  }

  return average

}

export function areArraysEqual(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((element, index) => {
      if (element === array2[index]) {
        return true;
      }

      return false;
    });
  }

  return false;
}