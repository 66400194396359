import React from 'react';
import Modal from 'react-modal';
import environment from '../../../../../environment/environment';
import authService from '../../../../../services/Auth';
import { Carousel } from 'antd';

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    height                : '90vh',
    width                 : '90vw',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const ModalCarouselPhotos = ( props ) => {

  // RENDERIZADO DEL COMPONENTE MODAL
  return (
    <div className="modalCarouselPhotos">
      <Modal
              isOpen={ props.open }
              onRequestClose={ props.handlerClose }
              style={ customStyles }
            >

            <Carousel autoplay initialSlide={ props.initialSlide }>
              {
                props.photos.map( ( photo, index ) => {
                  return (
                    <div className="photo" key={ index }>
                      <img src={ `${ environment.url }/formulario/image/${ photo }/${ props.userID }/${ props.formID }/${ authService.getToken() }` } alt="mobile" />
                    </div>
                  )
                })
              }
            </Carousel>

      </Modal>
    </div>
  )
}

export default ModalCarouselPhotos
