const env = {

  production: false,
  // url: 'http://localhost:3000',
  // front_url: 'http://localhost:3001',
  micotoxinas_url: 'https://micotoxinas.hubvetanco.com',
  url: 'https://api.hubvetanco.com',
  front_url: 'https://hubvetanco.com',
  maintenance: false
}

export default env
