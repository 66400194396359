const updateForm = ( form ) => {

  return {
    type: 'UPDATE_FORM',
    payload: form
  }

}

export default updateForm;
