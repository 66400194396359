import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import getUsers from '../../../actions/getUsers';

import Header from './components/Header';
import Filter from './components/Filter';
import UserList from './components/UserList';

import './userPage.css';

const UserPage = ( props ) => {

  // VARIABLES DE ESTADO DEL FILTRO, LOADING Y LISTA DE USUARIOS
  const [ filterString, setFilterString ] = useState( '' );
  const [ loading, setLoading ] = useState( true );
  const [ usersList, setUserList ] = useState( [] );

  // FUNCION HANDLER PARA RELODEAR EL COMPONENTE
  const reloadUsers = () => {

    setLoading( true );
    props.getUsers();
  }

  useEffect( () => {
    setUserList( props.users );
    setLoading( false );
  }, [ props.users ])

  // RENDERIADO DEL COMPONENTE
    // LOADING
    // COMPONENTE
  if ( loading ){
    return (
      <div className="loadingPage">
        <img src="/assets/img/icons/loading.svg" alt="loading"></img>
      </div>
    )
  } else {
    return (
      <div className="userPage">
        <Header />
        <Filter filterChangeHandler={ setFilterString } reloadUsers={ reloadUsers } />
        <UserList users={ usersList } filterString={ filterString } reloadUsers={ reloadUsers } />
      </div>
    )
  }

}

const mapStateToProps = ( state ) => {

  return {
    users: state.users
  }

}

export default connect( mapStateToProps, { getUsers } )(UserPage)
