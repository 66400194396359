import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import { useEffect } from 'react'
import { useState } from 'react'
import { point } from 'turf'
import { datediff } from '../../../../constants/funciones'

import moment from 'moment';

const useFilters = ( farms, products, forms, zones ) => {

    const initialFilters = {
        date: 'ALL',
        period: [ moment().subtract(1, 'years'), moment() ],
        zones: ['ALL'],
        users: ['ALL'],
        farms: farms.map( farm => farm._id ),
        farmType: 'ALL',
        farmDivision: ['ALL'],
        ageFormat: 'DAY',
        chickenAge: [],
        vacunacion: ['ALL'],
        initialCoccidiostatos: products.filter( prod => prod.type === 'COCCIDRATO' ).map( prod => prod._id ),
        finalCoccidiostatos: products.filter( prod => prod.type === 'COCCIDRATO' ).map( prod => prod._id ),
        initialPromotors: products.filter( prod => prod.type === 'PROMOTOR_CRECIMIENTO' ).map( prod => prod._id ),
        finalPromotors: products.filter( prod => prod.type === 'PROMOTOR_CRECIMIENTO' ).map( prod => prod._id )
    }

    const [ filters, setFilters ] =  useState( initialFilters )
    const [ filteredForms, setFilteredForms ] = useState( forms )

    useEffect( () => {

        if ( forms ){

            const filteredList = forms.filter( form => {

                const dateFlag = applyDateFilter( filters.date, form.datosFormulario.fecha, filters.period );
                const chickenDaysFlag = applyChickenDaysFilter( filters.ageFormat, filters.chickenAge, form.datosFormulario.edad );
                const vacunacionFlag = applyVacunacionFilter( filters.vacunacion, form.datosFormulario.vacunacion );
                const farmFlag = applyFarmsFilter( filters.farms, form.datosFormulario.granja._id );
                const farmTypeFlag = applyFarmsTypeFilter( filters.farmType, farms.find( farm => form.datosFormulario.granja._id === farm._id ).tipo );
                const farmDivisionFlag = applyFarmsDivisionFilter( filters.farmDivision, farms.find( farm => form.datosFormulario.granja._id === farm._id ).division );
                const zoneFlag = applyZonesFilter( filters.zones, farms.find( farm => form.datosFormulario.granja._id === farm._id ).posicion, zones );
                const userFlag = applyUsersFilter( filters.users, form.datosFormulario.usuario );
                const initialCoccidiostatosFlag = applyInitialCoccidiostatosFilter( filters.initialCoccidiostatos, form.datosFormulario.coccidratoInicial );
                const finalCoccidiostatosFlag = applyFinalCoccidiostatosFilter( filters.finalCoccidiostatos, form.datosFormulario.coccidratoFinal );
                const initialPromotorsFlag = applyInitialPromotorFilter( filters.initialPromotors, form.datosFormulario.promotorInicial );
                const finalPromotorsFlag = applyFinalPromotorFilter( filters.finalPromotors, form.datosFormulario.promotorFinal );
          
                return dateFlag && chickenDaysFlag && vacunacionFlag && farmFlag && farmTypeFlag && farmDivisionFlag && zoneFlag && userFlag && initialCoccidiostatosFlag && finalCoccidiostatosFlag && initialPromotorsFlag && finalPromotorsFlag
              } )

              setFilteredForms( filteredList )

        }

    }, [ filters, forms ] )

    const modifyFilter = ( filterKey, filterValue ) => {

        if ( filters[ filterKey ] ){
            setFilters({
                ...filters,
                [ filterKey ]: filterValue
            })
        }

    }
    
    const restoreFilters = () => {
      setFilters( initialFilters )
    }

    return { initialFilters, filters, modifyFilter, filteredForms, restoreFilters }

}

const applyDateFilter = ( type, date, period ) => {

    const dateObject = new Date( date );
    const todayObject = new Date();

    switch ( type ) {
      case 'LAST_WEEK':
        if ( datediff( dateObject, todayObject ) < 7 ) return true;
        break;
      case 'LAST_MONTH':
        if ( datediff( dateObject, todayObject ) < 30 ) return true;
        break;
      case 'PERIOD':
      
        const dateFrom = new Date( period[ 0 ] );
        const dateTo = new Date( period[ 1 ] );

        if ( dateFrom < dateObject && dateObject < dateTo ) return true;
        break;
      case 'ALL':
        return true;
      default:
    }

    return false;

  }

  const applyZonesFilter = ( zonesFiltered, farmPosition, zonesData ) => {

    if ( zonesFiltered.includes( 'ALL' ) ){
      return true;
    } else {

      if ( !farmPosition || !farmPosition.longitud || !farmPosition.latitud ) return false

      const farmPoint = point([ farmPosition.longitud, farmPosition.latitud ])

      return zonesFiltered.some( zoneFiltered => {

        const zone = zonesData.find( z => z._id === zoneFiltered )

        const zoneGeojson = JSON.parse( zone.geojson ) ;

        return zoneGeojson.features.some( feat => booleanPointInPolygon( farmPoint, feat ) )
       } )

    }

  }

  const applyUsersFilter = ( users, formUser ) => {

    if ( users.includes( 'ALL' ) ){
      return true;
    } else {

      return users.includes( formUser._id );
    }
  }

  const applyFarmTypeFilter = ( type, formsType ) => {

    switch ( type ) {
      case 'PROPIA':
        if ( formsType === 'Propia' ) return true;
        break;
      case 'INTEGRADA':
        if ( formsType === 'Integrada' ) return true;
        break;
      default:
    }

    return false;
  }

  const applyChickenDaysFilter = ( ageFormat, chickenAge, formChickenDays ) => {

    const formatConstant = ageFormat === 'DAY' ? 1 : 7;

    if ( !chickenAge[0] && !chickenAge[1] ) return true

    if ( chickenAge[0] ){

      if ( chickenAge[1] ){
        if ( chickenAge[0] * formatConstant < formChickenDays && chickenAge[1] * formatConstant > formChickenDays ) return true
      } else {
        if ( chickenAge[0] * formatConstant < formChickenDays ) return true
      }

    } else {

      if ( chickenAge[1] ){
        if ( chickenAge[1] * formatConstant > formChickenDays ) return true
      }

    }

    return false;

  }

  const applyVacunacionFilter = ( vacunaciones, formVacunacion ) => {

    if ( vacunaciones.includes( 'ALL' ) ){
      return true;
    } else {
      return vacunaciones.includes( formVacunacion );
    }

  }

  const applyFarmsFilter = ( farms, formFarm ) => {

    return farms.includes( formFarm );

  }

  const applyFarmsTypeFilter = ( farmType, formFarmType ) => {

    if ( farmType.includes( 'ALL' ) ){
      return true;
    } else {
      return farmType.includes( formFarmType );
    }
  }

  const applyFarmsDivisionFilter = ( farmDivision, formFarmDivision ) => {

    if ( farmDivision.includes( 'ALL' ) ){
      return true;
    } else {
      return farmDivision.includes( formFarmDivision );
    }
  }

  const applyInitialCoccidiostatosFilter = ( initialCoccidiostatos, formInitialCoccidiostatos ) => {

    return initialCoccidiostatos.find( p => p === formInitialCoccidiostatos._id );
  }

  const applyFinalCoccidiostatosFilter = ( finalCoccidiostatos, formFinalCoccidiostatos ) => {

    return finalCoccidiostatos.find( p => p === formFinalCoccidiostatos._id );
  }

  const applyInitialPromotorFilter = ( initialPromotors, formInitialPromotor ) => {

    return initialPromotors.find( p => p === formInitialPromotor._id );
  }

  const applyFinalPromotorFilter = ( finalPromotors, formFinalPromotor ) => {

    return finalPromotors.find( p => p === formFinalPromotor._id );
  }

  export default useFilters