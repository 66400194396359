import React from 'react';
import { IconContext } from "react-icons";
import { FiCalendar } from "react-icons/fi";

import { Trans } from 'react-i18next';
import { withNamespaces } from 'react-i18next';

const DateCard = ( props ) => {

  return (
    <div className="card date">
      <div className="data">
        <div className="amount"> { convertDateISO( props.date, props.t ) } </div>
        <div className="tag"> <Trans>{ props.tag ? props.tag : 'Date' }</Trans> </div>
      </div>
      <div className="icon">
        <div className="container">
          <IconContext.Provider value={{ size: "20px" }}>
            <div>
              <FiCalendar />
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  )
}

function convertDateISO( data_in, t ){
    const keyMonth = {
      1: t("January"),
      2: t("February"),
      3: t("March"),
      4: t("April"),
      5: t("May"),
      6: t("June"),
      7: t("July"),
      8: t("August"),
      9: t("September"),
      10: t("October"),
      11: t("November"),
      12: t("December")
    }
  	const fecha = new Date(Date.parse(data_in));
  	const data_out = fecha.getDate() + " " + keyMonth[fecha.getMonth()+1].substr(0, 3)+" '"+fecha.getFullYear().toString().slice(-2);
  	return data_out;
  }

export default withNamespaces()(DateCard)
