import React from 'react';
import { withNamespaces } from 'react-i18next';

const getContent = ( formSummery, t ) => {

  let content;
  const value = Math.round( formSummery.calidadIntestinal );

  if ( value <= 40 ){
    content = {
      headerImage: "/assets/img/dangerHeader.svg",
      title: t("Dangerous Results!"),
      message: t("Your chicken health is compromised.")
    }
  } else if ( value <= 70 ){
    content = {
      headerImage: "/assets/img/warningHeader.svg",
      title: t("Caution!"),
      message: t("Please take special care on this farm.")
    }
  } else {
    content = {
      headerImage: "/assets/img/successHeader.svg",
      title: t("Congratulations!"),
      message: t("We are pleased to inform you that this form gave satisfactory results.")
    }
  }

  return content
}

const IntestinalQualitySummery = ( props ) => {

  const content = getContent( props.formSummery, props.t );

  return (
    <div className="card intestinalQualitySummery">
      <div className="summery">
        <div className="headerImage"> <img src={ content.headerImage } alt="header" /> </div>
        <div className="result">
          <div className="title"> { content.title } </div>
          <div className="message"> { content.message } </div>
          <div className="indexesIncurrence">
            <div className="index">
              <div onClick={ ( ) => { document.getElementById( 'digestiveSection' ).scrollIntoView({ behavior: 'smooth' }) } }>
                <div className="percentage">
                  <span className="value"> { Math.round( props.formSummery.lesionesSistemaDigestivo ) } </span>
                  <span className="unit"> % </span>
                </div>
                <div className="tag"> { props.t('Digestive System') } </div>
              </div>
            </div>
            <div className="index">
              <div onClick={ ( ) => { document.getElementById( 'coccidiosisSection' ).scrollIntoView({ behavior: 'smooth' }) } }>
                <div className="percentage">
                  <span className="value"> { Math.round( props.formSummery.riesgoDeCoccidiosis ) } </span>
                  <span className="unit"> % </span>
                </div>
                <div className="tag"> { props.t('Coccidiosis Risk') } </div>
              </div>
            </div>
            <div className="index">
              <div onClick={ ( ) => { document.getElementById( 'clostridiosisSection' ).scrollIntoView({ behavior: 'smooth' }) } }>
                <div className="percentage">
                  <span className="value"> { Math.round( props.formSummery.riesgoDeClostridiosis ) } </span>
                  <span className="unit"> % </span>
                </div>
                <div className="tag"> { props.t('Disbiosis Risk') } </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(IntestinalQualitySummery)
