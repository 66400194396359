const zonesReducer = ( zones = null, action ) => {

  if ( action.type === 'GET_ZONES' ){
    return action.payload;
  }

  return zones;

}

export default zonesReducer;
