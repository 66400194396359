import React from 'react';
import {
  ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, CartesianAxis, ReferenceLine
} from 'recharts';
import BarComponent from './BarComponent';
import TooltipComponent from './TooltipComponent';
import XAxisTickComponent from './XAxisTickComponent';

import { IconContext } from "react-icons";
import { TiInfoLarge } from "react-icons/ti";

import './historical.css';

import { withNamespaces } from 'react-i18next';

const regresionLinear = dataArray => {

  const x = dataArray.map( data => data[ 0 ] )
  const y = dataArray.map( data => data[ 1 ] )
  const xMean = x.reduce((a,b) => a + b, 0) / x.length
  const yMean = y.reduce((a,b) => a + b, 0) / y.length

  let alphaNumerador = 0;
  let alphaDenominador = 0;
  for ( let i in x ){

    alphaNumerador += ( x[ i ] - xMean ) * ( y[ i ] - yMean )
    alphaDenominador += Math.pow( x[ i ] - xMean , 2 )

  }

  const alpha = alphaNumerador / alphaDenominador

  const beta = yMean - alpha * xMean

  return [ alpha, beta ]

}

const HistoricalGraphSection = ( props ) => {

  // INVERTIMOS EL ARRAY DE DATOS PARA QUE VAYA DE LA MAS VIEJA A LA MAS NUEVA PPERO DE UN OBJETO NUEVO PARA QUE NO MODIFIQUE EL ORIGINAL
  const arrayData = [].concat( props.formsData ).reverse()

  const predictionCoef = regresionLinear( arrayData.map( ( form, index ) => [ index, form.resultadosIndices.calidadIntestinal ] ) )
  const average = arrayData.reduce( ( prev, curr ) => prev + curr.resultadosIndices.calidadIntestinal, 0 ) / arrayData.length

  const data = arrayData.map( ( form, index ) => {

    const date = convertDateISO( form.datosFormulario.fecha, props.t );
    const value = form.resultadosIndices.calidadIntestinal;
    const farm = props.farms.find( farm => farm._id === form.datosFormulario.granja._id )
    const farmName = farm ? farm.nombre : props.t( 'Farm Deleted' );
    const userData = form.datosFormulario.usuario;
    const userFullName = userData ? userData.nombre + ' ' + userData.apellido : ''
    const formGalpon = form.datosFormulario.galpon
    const predicted = index * predictionCoef[ 0 ] + predictionCoef[ 1 ]

    return {
      id: form._id,
      date: date,
      value: value,
      farm: farmName,
      user: userFullName,
      age: form.datosFormulario.edad,
      predicted: predicted,
      average: average,
      galpon: formGalpon
    }
  } )

  return (
    <div className="section history">
      <div className="card full">
        <div className="head">
          <div className="title"> { props.t( 'Intestinal Quality Forms' ) } </div>
          <div className="references">
            <div className="reference">
              <div className="color" style={{ backgroundColor: 'rgb(45,45,60)' }}></div>
              <div className="tag"> { props.t( 'Trend' ) } </div>
            </div>
            <div className="reference">
              <div className="color" style={{ backgroundColor: '#9c27b0' }}></div>
              <div className="tag"> { props.t( 'Average' ) } </div>
            </div>
            <div className="info">
              <div className="icon">
                <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
                  <div>
                    <TiInfoLarge />
                  </div>
                </IconContext.Provider>
              </div>
              <div className="tooltip">
              <div className="title">{ props.t( 'Info' ) }</div>
              <hr />
              <div className="content">
                <span> { props.t( 'The graph shows the ' ) } </span>
                <span> <b> { props.t( 'intestinal quality index for each autopsy performed.' ) } </b> </span>
                <span> { props.t( 'Each column indicates the ' ) } </span>
                <span> <b> { props.t( 'average result of the birds evaluated in a necropsy.' ) } </b> </span>
                <span> { props.t( 'They are ordered chronologically.' ) } </span>
                <span> <b> { props.t( 'Values above 70% indicate a correct state of the digestive system while less than 40% indicate a serious state of it.' ) } </b> </span>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className="graph historicalForms">
          <ResponsiveContainer>
            <ComposedChart
                  data={ data }
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >


              <CartesianGrid strokeDasharray="3" horizontal={ true } vertical={ false } />
              <CartesianAxis tickMargin={ 10 } />
              <XAxis height={ 50 } tick={ <XAxisTickComponent farms={ props.farms } forms={ arrayData } /> } tickLine={ false } hide={ false } axisLine={ true } dataKey="id" padding={{ left: 20, right: 20 }} />
              <YAxis hide={ false } ticks={ [0,40,70,100] } domain={[0, 100]} axisLine={ true } allowDecimals={ false } unit="%"  />
              <Tooltip content={<TooltipComponent />} wrapperStyle={{ borderRadius: '7px', backgroundColor: 'white' }}/>

              <ReferenceLine y={40} stroke="red" strokeDasharray="3 3" />
              <ReferenceLine y={70} stroke="orange" strokeDasharray="3 3" />
              <ReferenceLine y={100} stroke="green" strokeDasharray="3 3" />

              <Bar name="Index Value" dataKey="value" shape={ <BarComponent selected={ props.formSelected } /> } barSize={50} fill="rgb(24,114,255)" onClick={ formClickedData => { props.selectFormHandler( arrayData.find( form => form._id === formClickedData.id ) ) } } />

              <Line type="monotone" dot={false} dataKey="predicted" stroke="#2d2d3c" />
              <Line type="monotone" dot={false} dataKey="average" stroke="#9c27b0" />

            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

function convertDateISO( data_in, t ){
  const keyMonth = {
    1: t("January"),
    2: t("February"),
    3: t("March"),
    4: t("April"),
    5: t("May"),
    6: t("June"),
    7: t("July"),
    8: t("August"),
    9: t("September"),
    10: t("October"),
    11: t("November"),
    12: t("December")
  }
	const fecha = new Date(Date.parse(data_in));
	const data_out = fecha.getDate() + " " + keyMonth[fecha.getMonth()+1].substr(0, 3)+" '"+fecha.getFullYear().toString().slice(-2);
	return data_out;
}



export default withNamespaces()(HistoricalGraphSection)
