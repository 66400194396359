import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as L from 'leaflet';
import 'leaflet-draw';
// import 'leaflet/dist/leaflet.css';
import Button from 'react-bootstrap/Button';
import zonesService from '../../../../services/Zones';
import Swal from 'sweetalert2'

import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';

import 'react-leaflet';

const NewZone = ( props ) => {

  const [ drawnItemsState, setDrawnItemsState ] = useState( null );

  useEffect( () => {

    const map = L.map('map', {
                                center: [-32, -60],
                                zoom: 5
                              })

    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    const farmsWithPosition = props.farms.filter( farm => farm.posicion )
    if ( farmsWithPosition.length ){
      const markers = farmsWithPosition.map( farm => {

        return L.marker([ farm.posicion.latitud, farm.posicion.longitud ])
                                                  .bindPopup( farm.nombre )
                                                  .addTo( map );
        
      } )

      map.fitBounds( new L.FeatureGroup( markers ).getBounds() )
    }

    // FeatureGroup is to store editable layers
    const drawnItems = new L.FeatureGroup(  );
    map.addLayer(drawnItems);
    const drawControl = new L.Control.Draw({
      draw: {
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false,
        polyline: false
      },
      edit: {
           featureGroup: drawnItems
       }
    });
    map.addControl(drawControl);

    setDrawnItemsState( drawnItems );

    map.on('draw:created', function (e) {
        // Do whatever else you need to. (save to db, add to map etc)
        drawnItems.addLayer(e.layer);
    });

  }, []
 )

 // FUNCION DE CREACION DE ZONA
  const createNewZone = ( ) => {

    const zoneName = document.getElementById('newZoneName').value;
    const zoneGeojson = JSON.stringify( drawnItemsState.toGeoJSON() )

    if ( !zoneName ){
      Swal.fire({
        icon: 'warning',
        title: props.t( 'Incompleted Fields!' ),
        text: props.t( 'Write a name for the zone.' )
      })

      return;
    }

    if ( !drawnItemsState.toGeoJSON().features.length ){
      Swal.fire({
        icon: 'warning',
        title: props.t( 'Draw not found!' ),
        text: props.t( 'Draw the shape of the zone on the map.' )
      })

      return;
    }

    // ALERT DE LOADING
    Swal.fire ({
       title: props.t( '..Saving your new zone..' ),
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // CAPTURO LOS DATOS PARA ENVIAR A LA API POST /ZONES
    const jsonData = {
      nombre: zoneName,
      geojson: zoneGeojson
    }

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    zonesService.post( jsonData, {
      success: () => {
        Swal.close();
        props.successHandler();
      }
    } )

  }

 // FUNCION DE CREACION DE ZONA
  return (
    <div className="zonesPage map">
      <div className="mapContainer">
        <div id="map"></div>
        <div className="card">
          <div className="title"> { props.t( 'New Zone' ) } </div>
          <div className="form">
            <input id="newZoneName" type="text" name="zone" placeholder={ props.t( 'Zone Name' ) } />
          </div>
          <div className="actions">
            <Button variant="" onClick={ props.cancelHandler }> { props.t( 'Cancel' ) } </Button>
            <Button variant="success" onClick={ createNewZone }> { props.t( 'Save' ) } </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ( state ) => {

  return {
    farms: state.farms
  }

}

export default compose(withNamespaces(), connect( mapStateToProps ))(NewZone)
