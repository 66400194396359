import React from 'react';
import { Checkbox, DatePicker, Slider, Select } from 'antd';

import { Trans } from 'react-i18next';

const { RangePicker } = DatePicker;
const { Option } = Select;

const DateFilter = props => {

  const handlerDateChange = options => {

    let newOption = options.find( option => option !== props.dateOption );

    // SI TRATE DE SELECCIONAR LA SELECCIONADA NO HAGO NADA - CANCELO
    if ( !newOption ) return

    props.setDateOption( newOption );

  }

  return (
    <div className="filter date">
      <div className="tag"> <Trans>Date</Trans> </div>
      <div className="selector">
        <Checkbox.Group value={ [ props.dateOption ] } onChange={ handlerDateChange } style={{ width: '100%' }}>
          <Checkbox value="LAST_WEEK" > <Trans>Last Week</Trans> </Checkbox>
          <br />
          <Checkbox value="LAST_MONTH" > <Trans>Last Month</Trans> </Checkbox>
          <br />
          <Checkbox value="PERIOD" > <Trans>Select period</Trans> </Checkbox>
          <br />
          { props.dateOption === 'PERIOD' ? <RangePicker value={ props.datePeriod } onChange={ props.setDatePeriod } /> : null }
          <br />
          <Checkbox value="ALL" > <Trans>All</Trans> </Checkbox>
        </Checkbox.Group>
      </div>
    </div>
  )

}

export default DateFilter
