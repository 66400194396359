import React from 'react';
import { IconContext } from "react-icons";
import { GiChicken } from "react-icons/gi";

import { Trans } from 'react-i18next';

const ChickensCard = ( props ) => {

  return (
    <div className="card chicken" onClick={ ( ) => { document.getElementById( 'observationsSection' ).scrollIntoView({ behavior: 'smooth' }) } }>
      <div className="data">
        <div className="amount"> { props.amount } </div>
        <div className="tag"> <Trans>Chickens</Trans> </div>
      </div>
      <div className="icon">
        <div className="container">
          <IconContext.Provider value={{ size: "20px" }}>
            <div>
              <GiChicken />
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  )
}

export default ChickensCard
