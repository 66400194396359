const updateFarm = ( farm ) => {

    return {
      type: 'UPDATE_FARM',
      payload: farm
    }
  
  }
  
  export default updateFarm;
  