import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import getZones from '../../../actions/getZones';

import Header from './components/Header';
import Filter from './components/Filter';
import ZonesList from './components/ZonesList';
import NewZone from './components/NewZone';
import EditZone from './components/EditZone';

import { Trans } from 'react-i18next';
import Button from 'react-bootstrap/Button';

import './zonesPage.css';

const ZonesPage = ( props ) => {

  // VARIABLES DE ESTADO DEL FILTRO, LOADING Y LISTA DE ZONAS
  const [ filterString, setFilterString ] = useState( '' );
  const [ loading, setLoading ] = useState( true );
  const [ zonesList, setZonesList ] = useState( [] );
  const [ mode, setMode ] = useState( 'list' );
  const [ editionZone, setEditionZone ] = useState( null );

  // HOOK PARA PEDIR A LA API GET /ZONES AL INICIAR EL COMPONENTE
  useEffect( () => {
    setZonesList( props.zones );
    setLoading( false );
  }, [ props.zones ] )

  // FUNCION HANDLER PARA RELODEAR EL COMPONENTE
  const reloadZones = () => {

    setLoading( true );
    props.getZones();
    setMode( 'list' );
    setEditionZone( null );

  }

  const cancelZonesMap = () => {

    if ( editionZone ) setEditionZone( null );
    setMode('list')

  }

  // RENDERIADO DEL COMPONENTE
    // LOADING
    // COMPONENTE
  if ( mode === 'list' ){
    if ( loading ){
      return (
        <div className="loadingPage">
          <img src="/assets/img/icons/loading.svg" alt="loading"></img>
        </div>
      )
    } else if ( !zonesList.length ) {
      return (
        <div className="zonesPage">
          <Header />
          <div className="noZones">
            <div className="logo">
              <img src="/assets/img/icons/noZones.svg" alt="noZones" />
            </div>
            <div className="title"> <Trans>¡ No Zones !</Trans> </div>
            <div className="message">
              <p> <Trans>You haven't created any zones yet.</Trans> </p>
              <p> <Trans>In this section you can create zones to group your farms by region.</Trans> </p>
            </div>
            <div className="action">
              <Button variant="success" onClick={ () => { setMode( 'new' ) } }> <Trans>Create Zone</Trans> </Button>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="zonesPage">
          <Header />
          <Filter filterChangeHandler={ setFilterString } reloadZones={ reloadZones } createZoneHandler={ () => { setMode( 'new' ) } } />
          <ZonesList zones={ zonesList } filterString={ filterString } reloadZones={ reloadZones } modifyZoneHandler={ zone => { setEditionZone( zone ); setMode( 'modify' ) } } />
        </div>
      )
    }
  } else if ( mode === 'new' ){
    return (
      <NewZone successHandler={ reloadZones } cancelHandler={ cancelZonesMap } />
    )
  } else if ( mode === 'modify' ){
    return <EditZone successHandler={ reloadZones } cancelHandler={ cancelZonesMap } zone={ editionZone } />
  }

}

const mapStateToProps = ( state ) => {

  return {
    zones: state.zones
  }

}

export default connect( mapStateToProps, { getZones } )(ZonesPage)