import React from "react";
import { withNamespaces } from 'react-i18next';
import { MdPictureAsPdf } from 'react-icons/md';
import GaugeChart from 'react-gauge-chart'

import GraphIntestinalQuality from '../summery/Graph';
import GraphProgress from '../digestiveSystem/GraphProgress';
import ProgressTractos from '../digestiveSystem/ProgressTractos';
import GraphComposition from '../digestiveSystem/GraphComposition';
import GraphMicroscopy from '../coccidiosis/GraphMicroscopy';
import GraphMacroscopy from '../coccidiosis/GraphMacroscopy';
import env from '../../../../../environment/environment';
import authService from "../../../../../services/Auth";
import { areArraysEqual, getUniques } from "../../../../../constants/funciones";

import moment from 'moment';

import reportStyle from './reportStyle';

const GeneralModalBody = props => {

    const datePeriod = getPeriodDatesTagByFilter( props.filters, props.t )
    const farms_length = getUniques( props.forms.map( f => f.datosFormulario.granja ) ).length
    // const age = props.form.datosFormulario.edad ? ( props.form.datosFormulario.edad % 7 === 0 ? (props.form.datosFormulario.edad / 7) + ' semanas' : props.form.datosFormulario.edad + ' dias' ) : '-' 

    const downloadReport = () => {

        const content = `
                        <html>
                        <head>
                          ${ reportStyle }
                          <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
                        </head>
                        <body>
                          <div class="report">${ document.querySelector('.modalContainer.report').innerHTML } </div>
                        </body>
                        </html>
                        `
    
        fetch( `${ env.url }/files/report` ,{
            body: JSON.stringify({content}),
            method: 'POST',
            headers: { "Content-Type": "application/json" }
        })
        .then( res => res.blob() )
        .then( blob => downloadBlob( blob ) )
    
      }

    return (
        <div className="modalContainer">
            <div className="download" onClick={ downloadReport }>
            <div className="icon"> <MdPictureAsPdf /> </div>
            <div className="tag"> { props.t( 'Generate PDF' ) } </div>
            </div>
            <div className="modalContainer report">
                <div className="title">
                    { props.t( 'Intestinal quality survey report' ) }
                </div>
                <div className="company"> { props.profile.empresa.nombre } </div>
                <div className="farm"> { props.t( 'AVERAGE RESULTS' ) } </div>
                <div className="general">
                <div className="subtitle"> { props.t( 'General data' ) } </div>
                <div className="item">
                    <div className="tag">{ props.t( 'Period surveyed' ) }: </div>
                    <div className="value"> { datePeriod } </div>
                </div>
                <div className="item">
                    <div className="tag">{ props.t( 'Number of forms' ) }: </div>
                    <div className="value"> { props.forms.length } </div>
                </div>
                <div className="item">
                    <div className="tag">{ props.t( 'Number of farms' ) }: </div>
                    <div className="value"> { farms_length } </div>
                </div>
                </div>


                <div className="agrupacion">
                    <div className="subtitle">{ props.t( 'Grouping criteria' ) }</div>
                    <div className="items">
                    <ul>
                        { 
                        Object.keys( props.filters ).map( f => {
                        
                            if ( !areFiltersEquals( f, props.filters[ f ], props.initialFilters[ f ] ) ) {

                                const filtersTagsValues = getFiltersTags( f, props.filters[ f ], props.zones, props.users, props.farms, props.products, props.t )

                                if ( filtersTagsValues && filtersTagsValues.length > 1 && filtersTagsValues[ 0 ] && filtersTagsValues[ 1 ] )
                                return <li key={ f }> <div className="tag">{ filtersTagsValues[ 0 ] }</div><div className="value">{ filtersTagsValues[ 1 ] }</div> </li>

                            }
                        
                        } ) 
                        }
                    </ul>
                    </div>
                </div>
            
                <div className="resultados">
                <div className="subtitle">{ props.t( 'Results' ) }</div>
                <div className="summery-message">
                    { props.t( 'The results are an average of ' ) } { props.forms.length } { props.t( 'necropsies performed.' ) }
                </div>

                <div className="sections">

                    <div className="section quality">
                    <div className="name">
                    { props.t( 'Overall Bowel Quality' ) }
                    </div>
                    <div className="info">
                        <div className="graph">

                        <GraphIntestinalQuality value={ props.form.resultadosIndices.calidadIntestinal } size={ 200 } />

                        </div>
                        <div className="description">
                        <span> { props.t( 'The general state of intestinal quality can be observed through the intestinal quality index, which takes into account ' ) } </span>
                        <span> <b> { props.t( 'all the parameters evaluated ' ) } </b> </span>
                        <span> { props.t( 'and indicates the general integrity of the digestive system, ' ) } </span>
                        <span> <b> { props.t( 'maintaining an integrity above 70% it will be more feasible to achieve the objectives productive.' ) } </b> </span>
                        <p className="description-result"> { props.t( 'For the survey carried out, it was ' ) } { Math.round( props.form.resultadosIndices.calidadIntestinal ) }%. </p>
                        </div>
                    </div>
                    </div>

                    <div className="section digestive">
                    <div className="name">
                    { props.t( 'Digestive System State' ) }
                    </div>
                    <div className="info">
                        <div className="graph digestive">
                        <GraphProgress value={ props.form.resultadosIndices.lesionesSistemaDigestivo } />
                        <ProgressTractos formData={ props.form } notInfo={ true } />
                        </div>
                        <div className="description">
                            <span> { props.t( 'The digestive system is evaluated through macroscopic lesions of the different anatomical structures, both at the upper level, which assesses lesions of ' ) } </span>
                            <span> <b>  { props.t( 'the oral cavity, proventriculus, gizzard, liver and pancreas,' ) } </b> </span>
                            <span>{ props.t( 'and at the lower level, which assesses lesions of ' ) } </span>
                            <span> <b> { props.t( 'the intestines and caeca , as well as the state of the intestinal content.' ) } </b> </span>
                            <span> { props.t( 'In turn, due to its importance for productive performance, we can observe ' ) } </span>
                            <span> <b>  { props.t( 'the general quality of the liver in the birds evaluated.' ) } </b> </span>
                        </div>
                        <div className="description space-40">
                            { props.t( 'Next, we can observe the distribution and prevalence of the lesions observed in the surveyed birds.' ) }
                        </div>
                        <div className="graph distribution">
                        <GraphComposition formData={ props.form } activeIndexArray={ [0,1,2,3,4,5,6,7] } />
                        </div>
                    </div>
                    </div>

                    <div className="section cocci">
                    <div className="name">
                        { props.t( 'Coccidiosis' ) }
                    </div>
                    <div className="info">
                        <div className="description">
                        { props.t( 'The risk of presenting coccidiosis, both clinical and subclinical, is evaluated by means of macroscopic lesions at the intestinal level and the count of oocysts at the microscopic level.' ) }
                        </div>
                        <div className="rowContainer">
                        <div className="graph">
                            <GraphMacroscopy form={ props.form } />
                        </div>
                        <div className="description">
                            <span> { props.t( 'Coccidiosis macroscopic evaluation score ' ) } </span>
                            <span> <b> { props.t( 'adapted from Johnson & Reid ' ) } </b> </span>
                            <span> { props.t( 'for duodenum, jejunum and cecum, ' ) } </span>
                            <span> <b> { props.t( 'taking values ​​from 0 to 4 according to the severity of the lesions observed, in where 0 is no injuries and 4 highly serious injuries.' ) } </b> </span>
                        </div>
                        </div>
                        <div className="rowContainer">
                        <div className="description">
                            <span> { props.t( 'Microscopic evaluation score using the ' ) } </span>
                            <span> <b> { props.t( 'serial scraping of the intestinal mucosa ' ) } </b> </span>
                            <span> { props.t( 'technique and observing the presence of oocysts.' ) } </span>
                            <span> <b> { props.t( 'It takes values ​​from 0 to 4 where 0 is the absence of oocysts and 4 is uncountable number of them.' ) } </b> </span>
                        </div>
                        <div className="graph">
                            <GraphMicroscopy form={ props.form } />
                        </div>
                        </div>
                    </div>
                    <div className="risk">
                        <div className="subtitle">{ props.t( 'Risk of Coccidiosis' ) }</div>
                        <div className="description">
                            { props.t( 'Finally, with all the aforementioned values, the coccidia risk index is constructed, which indicates the possibility of the appearance of clinical or subclinical coccidiosis. Values ​​above 30% indicate the appearance of subclinical coccidiosis while values ​​above 60% indicate clinical coccidiosis.' ) }
                        </div>
                        <div className="graph">
                        <GaugeChart id="gauge-chart3"
                            nrOfLevels={20}
                            colors={["#5cb85c", "#d9534f"]}
                            percent={ Math.round( props.form.resultadosIndices.riesgoDeCoccidiosis ) / 100 }
                            textColor="rgb(45,45,60)"
                            needleColor="rgba(0,0,0,0.25)"
                        />
                        </div>
                        <div className="description-result">
                            { props.t( 'For the birds evaluated, the risk index is ' ) } { Math.round( props.form.resultadosIndices.riesgoDeCoccidiosis ) }%.
                        </div>
                    </div>
                    </div>

                    <div className="section clostridiosis">
                    <div className="name">
                        { props.t( 'Disbiosis' ) }
                    </div>
                    <div className="info">
                        <div className="description">
                            { props.t( 'Taking into account the macroscopic lesions and the possibility of risk of coccidiosis, a clostridiosis risk index is constructed, which establishes the possibility of suffering from dysbiosis with the consequent subclinical or clinical clostridiosis.' ) }
                        </div>
                        <div className="risk">
                        <div className="graph">
                            <GaugeChart id="gauge-chart4"
                                nrOfLevels={20}
                                colors={["#5cb85c", "#d9534f"]}
                                percent={ Math.round( props.form.resultadosIndices.riesgoDeClostridiosis ) / 100 }
                                textColor="rgb(45,45,60)"
                                needleColor="rgba(0,0,0,0.25)"
                            />
                        </div>
                        <div className="description-result">
                            { props.t( 'For the birds evaluated, the risk index is ' ) } { Math.round( props.form.resultadosIndices.riesgoDeClostridiosis ) }%.
                        </div>
                        </div>
                    </div>
                    </div>
                    
                    <div className="section observations">
                    <div className="name">
                        { props.t( 'General observations of the survey' ) }
                    </div>
                    <div className="description">
                        {
                            props.forms.map( form => {
                                return form.datosFormulario.observaciones&& <p key={ form._id }> { form.datosFormulario.observaciones } </p>
                            })
                        }
                    </div>
                    </div>

                    <div className="section observations">
                    <div className="name">
                        { props.t( 'Photos uploaded during the survey' ) }
                    </div>
                    <div className="description">
                        { 
                            props.forms.map( form => {
                                return form.datosFormulario.savedPhotos && form.datosFormulario.savedPhotos.length 
                                        ? 
                                        form.datosFormulario.savedPhotos.map( ( photo, index ) => {

                                            return (
                                                <div className="photo" key={ index }>
                                                <img src={ `${ env.url }/formulario/image/${ photo }/${ form.datosFormulario.usuario._id }/${ form._id }/${ authService.getToken() }` } alt="mobile" />
                                                </div>
                                            )
                                        })
                                        : 
                                        null
                            })
                        }
                    </div>
                    </div>

                    <div className="closure-message">
                        { props.t( 'This report is a summary of the parameters evaluated and uploaded to the Vetanco Instinal Quality Monitoring APP. If you have any questions about its interpretation, please contact your Vetanco technician.' ) }
                    </div>

                </div>
                </div> 
            
            </div>

        </div>
    )

}

function getFiltersTags( filterKey, filterValue, zones, users, farms, products, t ){

    switch ( filterKey ) {
        case 'date':
            // return [ 'Fecha' ]
            break;
        case 'period':
            // return value1[ 0 ].format( 'YYYY-MM-DD' ) == value2[ 0 ].format( 'YYYY-MM-DD' ) && value1[ 1 ].format( 'YYYY-MM-DD' ) == value2[ 1 ].format( 'YYYY-MM-DD' )
            break;
        case 'zones':
            return [ t( 'Zones' ), zones.filter( z => filterValue.indexOf( z._id ) > -1 ).map( z => z.nombre ).join( ', ' ) ]
            break;
        case 'users':
            return [ t( 'Users' ), users.filter( u => filterValue.indexOf( u._id ) > -1 ).map( u => ( u.nombre + ' ' + u.apellido ) ).join( ', ' ) ]
            break;
        case 'farms':
            return [ t( 'Farms' ), farms.filter( f => filterValue.indexOf( f._id ) > -1 ).map( f => ( f.nombre ) ).join( ', ' ) ]
            break;
        case 'farmType':
            return [ t( "Farm's Type" ), filterValue ]
            break;
        case 'farmDivision':
            return [ t( "Farm's Division" ), filterValue.join( ", " ) ]
            break;
        case 'ageFormat':
            // return value1 == value2
            break;
        // case 'chickenAge':
        //     return areArraysEqual( value1, value2 )
        //     break;
        case 'vacunacion':
            return [ t( 'Vaccination' ), filterValue.join( ", " ) ]
            break;
        case 'initialCoccidiostatos':
            return [ t( 'Initial Feed applied coccidiostat' ), products.filter( p => filterValue.indexOf( p._id ) > -1 ).map( p => ( p.nombre ) ).join( ', ' ) ]
            break;
        case 'finalCoccidiostatos':
            return [ t( 'Final Feed applied coccidiostat' ), products.filter( p => filterValue.indexOf( p._id ) > -1 ).map( p => ( p.nombre ) ).join( ', ' ) ]
            break;
        case 'initialPromotors':
            return [ t( 'Initial Growth Promoter' ), products.filter( p => filterValue.indexOf( p._id ) > -1 ).map( p => ( p.nombre ) ).join( ', ' ) ]
            break;
        case 'finalPromotors':
            return [ t( 'Final Growth Promoter' ), products.filter( p => filterValue.indexOf( p._id ) > -1 ).map( p => ( p.nombre ) ).join( ', ' ) ]
            break;
        default:
            return ''
    }
}

function areFiltersEquals( filterKey, value1, value2 ){

    switch ( filterKey ) {
        case 'date':
            return value1 == value2
            break;
        case 'period':
            return value1[ 0 ].format( 'YYYY-MM-DD' ) == value2[ 0 ].format( 'YYYY-MM-DD' ) && value1[ 1 ].format( 'YYYY-MM-DD' ) == value2[ 1 ].format( 'YYYY-MM-DD' )
            break;
        case 'zones':
            return areArraysEqual( value1, value2 )
            break;
        case 'users':
            return areArraysEqual( value1, value2 )
            break;
        case 'farms':
            return areArraysEqual( value1, value2 )
            break;
        case 'farmType':
            return value1 == value2
            break;
        case 'farmDivision':
            return areArraysEqual( value1, value2 )
            break;
        case 'ageFormat':
            return value1 == value2
            break;
        case 'chickenAge':
            return areArraysEqual( value1, value2 )
            break;
        case 'vacunacion':
            return areArraysEqual( value1, value2 )
            break;
        case 'initialCoccidiostatos':
            return areArraysEqual( value1, value2 )
            break;
        case 'finalCoccidiostatos':
            return areArraysEqual( value1, value2 )
            break;
        case 'initialPromotors':
            return areArraysEqual( value1, value2 )
            break;
        case 'finalPromotors':
            return areArraysEqual( value1, value2 )
            break;
        default:
            return value1 == value2
    }

}

function getPeriodDatesTagByFilter( filters, t ){

    if ( filters.date === 'PERIOD' ){
        return filters.period[ 0 ].format( 'DD.MM.YYYY' ) + '-' + filters.period[ 1 ].format( 'DD.MM.YYYY' )
    } else if ( 'LAST_WEEK' ){
        return moment().subtract(1, 'weeks').format( 'DD.MM.YYYY' ) + '-' + moment().format( 'DD.MM.YYYY' )
    } else if ( 'LAST_MONTH' ){
        return moment().subtract(1, 'months').format( 'DD.MM.YYYY' ) + '-' + moment().format( 'DD.MM.YYYY' )
    } else if ( 'ALL' ) {
        return t( 'All the history' )
    }

}

export function downloadBlob( blob, filename ){

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

}

export default withNamespaces()(GeneralModalBody)