import zonesService from '../services/Zones';

const getZones = () => {

  return async ( dispatch ) => {

    const response = await zonesService.get();

    dispatch({
      type: 'GET_ZONES',
      payload: response.data.info
    })
  }

}

export default getZones;
