import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';
import { IconContext } from "react-icons";
import { BsFileEarmark } from "react-icons/bs";

import formsService from '../../../../../services/Forms';
import updateForm from '../../../../../actions/updateForm';

import Swal from 'sweetalert2'
import { Select, Input } from 'antd';
import { withNamespaces } from 'react-i18next';
import { Switch } from 'antd';

import './form.css';

const { Option } = Select;

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white',
    maxHeight             : '100vh',
    overflow              : 'auto'
  }
};

const LoadingComponent = () => {
  return (
    <div className="loading">
      <img src="/assets/img/icons/loading.svg" alt="loading"></img>
    </div>
  )
}

const InfoComponent = ( props ) => {

  const [ formulario, setFormulario ] = useState( props.formData )
  const [ indexAve, setIndexAve ] = useState( 0 )
  const [ editionMode, setEditionMode ] = useState( false )

  useEffect( () => {
    setFormulario( props.formData )
  }, [ props.formData ])

  const updateChickenData = ( key, value, index ) => {

    const newForm = {
      ...formulario
    }
    newForm.aves[ index ][ key ] = value

    setFormulario( newForm )

  }

  const updateGeneralData = ( key, value ) => {

    const newForm = {
      ...formulario
    }
    newForm.datosFormulario[ key ] = value

    setFormulario( newForm )

  }

  const updateGranja = ( value ) => {

    const newForm = {
      ...formulario
    }
    newForm.datosFormulario.granja = value

    setFormulario( newForm )

  }

  const updateUser = ( value ) => {

    const newForm = {
      ...formulario
    }
    newForm.datosFormulario.usuario = value

    setFormulario( newForm )

  }

  const updateProduct = ( type, value ) => {

    const newForm = {
      ...formulario
    }
    newForm.datosFormulario[ type ] = props.products.find( p => p._id === value )

    setFormulario( newForm )

  }

  const updateVacunaccion = ( value ) => {
    
    const newForm = {
      ...formulario
    }
    newForm.datosFormulario.vacunacion = value

    setFormulario( newForm )

  }

  const saveChanges = () => {

    Swal.fire ({
       title: props.t( '..Saving changes in form..' ),
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    formsService.put( formulario )
          .then( res => {

            const newForm = {
              ...formulario
            }

            delete newForm.aves
            // newForm.datosFormulario.usuario = newForm.datosFormulario.usuario._id
            newForm.promedios = res.data.info.promedios
            newForm.resultadosIndices = res.data.info.resultadosIndices

            newForm.datosFormulario.promotorInicial = props.products.find( p => p._id === newForm.datosFormulario.promotorInicial || p._id === newForm.datosFormulario.promotorInicial._id )
            newForm.datosFormulario.promotorFinal = props.products.find( p => p._id === newForm.datosFormulario.promotorFinal || p._id === newForm.datosFormulario.promotorFinal._id )
            newForm.datosFormulario.coccidratoInicial = props.products.find( p => p._id === newForm.datosFormulario.coccidratoInicial || p._id === newForm.datosFormulario.coccidratoInicial._id )
            newForm.datosFormulario.coccidratoFinal = props.products.find( p => p._id === newForm.datosFormulario.coccidratoFinal || p._id === newForm.datosFormulario.coccidratoFinal._id )

            props.updateForm( newForm )

            setEditionMode( false )
            Swal.close();
          } )

  }

  return (
    <div className="ready">
      <div className="column general">
        <div className="title">
          <div className="icon">
            <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
              <div>
                <BsFileEarmark />
              </div>
            </IconContext.Provider>
          </div>
          <div className="date"> { convertDateISO( formulario.datosFormulario.fecha, props.t ) }</div>
          <div className="switch">
            <Switch
                    checkedChildren="Edition"
                    unCheckedChildren="View"
                    size="large"
                    checked={ editionMode }
                    onChange={ setEditionMode }
                    />
          </div>
        </div>
        <div className="data">
          <div className="card">
            <div className="head"> Datos Generales </div>
            <div className="body">
              <div className="item">
                <div className="tag"> Relevamiento </div>
                <div className="value">
                  {
                    editionMode ?
                    // <Select value={ props.formData.datosFormulario.relevamiento } onChange={ opt => { updateGeneralData( 'relevamiento', opt ) } }  style={{ width: 120 }} >
                    //   <Option value="Calidad Intestinal"> Calidad Intestinal </Option>
                    // </Select>
                    <span> { props.formData.datosFormulario.relevamiento } </span>
                    :
                    <span> { props.formData.datosFormulario.relevamiento } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag"> Granja </div>
                <div className="value">
                  {
                    editionMode ?
                    // <Select value={ formulario.datosFormulario.granja._id } onChange={ opt => { updateGranja( props.farms.find( fa => fa._id === opt ) ) } }  style={{ width: 120 }} >
                    //   {
                    //     props.farms.map( f => {
                    //       return <Option key={ f._id } value={ f._id }> { f.nombre } </Option>
                    //     } )
                    //   }
                    // </Select>
                    <span> { formulario.datosFormulario.granja.nombre } </span>
                    :
                    <span> { formulario.datosFormulario.granja.nombre } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag"> Usuario </div>
                <div className="value">
                  {
                    editionMode ?
                    // <Select value={ formulario.datosFormulario.usuario._id } onChange={ opt => { updateUser( props.users.find( us => us._id === opt ) ) } }  style={{ width: 120 }} >
                    //   {
                    //     props.users.map( u => {
                    //       return <Option key={ u._id } value={ u._id }> { u.nombre + " " + u.apellido } </Option>
                    //     } )
                    //   }
                    // </Select>
                    <span> { formulario.datosFormulario.usuario.nombre + ' ' + formulario.datosFormulario.usuario.apellido } </span>
                    :
                    <span> { formulario.datosFormulario.usuario.nombre + ' ' + formulario.datosFormulario.usuario.apellido } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag"> Galpon </div>
                <div className="value">
                  {
                    editionMode ?
                    <Input value={ formulario.datosFormulario.galpon } onChange={ evt => { updateGeneralData( 'galpon', evt.target.value ) } }  style={{ width: 120 }} />
                    :
                    <span> { formulario.datosFormulario.galpon } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag"> Edad Ave </div>
                <div className="value edad">
                  {
                    editionMode ?
                    <div>
                      <Input value={ formulario.datosFormulario.edad } onChange={ evt => { updateGeneralData( 'edad', evt.target.value ) } }  style={{ width: 120 }} />
                      <span className="unit"> dias </span>
                    </div>
                    :
                    <span> { formulario.datosFormulario.edad } dias </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag"> Vacunacion </div>
                <div className="value"> 
                { 
                editionMode
                ?
                <Select value={ formulario.datosFormulario.vacunacion } onChange={ opt => { updateVacunaccion( opt ) } }  style={{ width: 120 }} >
                  <Option value="Salmonella"> { props.t('Biotech Vac Salmonella') } </Option>
                  <Option value="Ninguna"> { props.t('Ninguna') } </Option>
                  <Option value="No Informa"> { props.t('No Informa') } </Option>
                </Select>
                :
                <span> { formulario.datosFormulario.vacunacion } </span>
                } 
                </div>
              </div>
              <div className="item">
                <div className="tag"> Promotor de Crecimiento Inicial </div>
                <div className="value"> 
                { 
                editionMode ?
                <Select dropdownStyle={{ minWidth: 250 }} value={ formulario.datosFormulario.promotorInicial._id } onChange={ opt => { updateProduct( 'promotorInicial', opt ) } }  style={{ width: 120 }} >
                  {
                    props.products.filter( prod => prod.type === 'PROMOTOR_CRECIMIENTO' ).map( p => {
                      return <Option key={ p._id } value={ p._id }> { p.nombre } </Option>
                    } )
                  }
                </Select>
                :
                <span> { props.products.find( prod => prod._id === formulario.datosFormulario.promotorInicial._id )?.nombre  } </span>
                } 
                </div>
              </div>
              <div className="item">
                <div className="tag"> Promotor de Crecimiento Final </div>
                <div className="value"> 
                { 
                editionMode ?
                <Select dropdownStyle={{ minWidth: 250 }} value={ formulario.datosFormulario.promotorFinal._id } onChange={ opt => { updateProduct( 'promotorFinal', opt ) } }  style={{ width: 120 }} >
                  {
                    props.products.filter( prod => prod.type === 'PROMOTOR_CRECIMIENTO' ).map( p => {
                      return <Option key={ p._id } value={ p._id }> { p.nombre } </Option>
                    } )
                  }
                </Select>
                :
                <span> { props.products.find( prod => prod._id === formulario.datosFormulario.promotorFinal._id )?.nombre  } </span>
                } 
                </div>
              </div>
              <div className="item">
                <div className="tag"> Coccidrato Inicial </div>
                <div className="value">
                { 
                editionMode ?
                <Select dropdownStyle={{ minWidth: 250 }} value={ formulario.datosFormulario.coccidratoInicial._id } onChange={ opt => { updateProduct( 'coccidratoInicial', opt ) } }  style={{ width: 120 }} >
                  {
                    props.products.filter( prod => prod.type === 'COCCIDRATO' ).map( p => {
                      return <Option key={ p._id } value={ p._id }> { p.nombre } </Option>
                    } )
                  }
                </Select>
                :
                <span> { props.products.find( prod => prod._id === formulario.datosFormulario.coccidratoInicial._id )?.nombre } </span>
                } 
                </div>
              </div>
             <div className="item">
                <div className="tag"> Coccidrato Final </div>
                <div className="value"> 
                { 
                editionMode ?
                <Select dropdownStyle={{ minWidth: 250 }} value={ formulario.datosFormulario.coccidratoFinal._id } onChange={ opt => { updateProduct( 'coccidratoFinal', opt ) } }  style={{ width: 120 }} >
                  {
                    props.products.filter( prod => prod.type === 'COCCIDRATO' ).map( p => {
                      return <Option key={ p._id } value={ p._id }> { p.nombre } </Option>
                    } )
                  }
                </Select>
                :
                <span> { props.products.find( prod => prod._id === formulario.datosFormulario.coccidratoFinal._id )?.nombre } </span>
                } 
                </div>
              </div>
            </div>
          </div>
          <div className="actions">
            {
              editionMode
              ?
              <Button variant="success" onClick={ saveChanges }> Guardar </Button>
              :
              null
            }
          </div>
        </div>
      </div>
      <div className="column chicken">
        <div className="card">
          <div className="title">
            <div className={ indexAve === 0 ? "prev hidden" : "prev" } onClick={ () => { setIndexAve( indexAve - 1 ) } }>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.17 20">
              <path d="M10.83,19.5a1,1,0,0,1-.71-.29l-8.48-8.5a1,1,0,0,1,0-1.42L10.12.79a1,1,0,0,1,1.41,0,1,1,0,0,1,0,1.42L3.76,10l7.77,7.79a1,1,0,0,1-.7,1.71Z" style={{ fill : "#45455b" }}/></svg>
            </div>
            <div className="index"> <span>Ave</span> { formulario.aves.length > 1 ? <span className="position"> { indexAve + 1 } de { formulario.aves.length } </span> : null } </div>
            <div className={ indexAve === formulario.aves.length - 1  ? "next hidden" : "next" } onClick={ () => { setIndexAve( indexAve + 1 ) } }>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.17 20">
              <path d="M2.34,19.5a1,1,0,0,1-.7-1.71L9.41,10,1.64,2.21a1,1,0,0,1,0-1.42,1,1,0,0,1,1.41,0l8.48,8.5a1,1,0,0,1,0,1.42l-8.48,8.5A1,1,0,0,1,2.34,19.5Z" style={{ fill : "#45455b" }}/></svg>
            </div>
          </div>
          <div className="block">
            <div className="head"> Sistema Digestivo </div>
            <div className="body">
              <div className="item">
                <div className="tag">Cavidad Bucal</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].digestivoCavidadBucal } onChange={ opt => { updateChickenData( 'digestivoCavidadBucal', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ 0 }>0</Option>
                      <Option value={ 1 }>1</Option>
                      <Option value={ 2 }>2</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].digestivoCavidadBucal } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag">Proventriculo</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].digestivoProventriculo } onChange={ opt => { updateChickenData( 'digestivoProventriculo', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ 0 }>0</Option>
                      <Option value={ 1 }>1</Option>
                      <Option value={ 2 }>2</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].digestivoProventriculo } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag">Molleja</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].digestivoMolleja } onChange={ opt => { updateChickenData( 'digestivoMolleja', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ 0 }>0</Option>
                      <Option value={ 1 }>1</Option>
                      <Option value={ 2 }>2</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].digestivoMolleja } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag">Higado</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].digestivoHigado } onChange={ opt => { updateChickenData( 'digestivoHigado', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ 0 }>0</Option>
                      <Option value={ 1 }>1</Option>
                      <Option value={ 2 }>2</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].digestivoHigado } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag">Pancreas</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].digestivoPancreas } onChange={ opt => { updateChickenData( 'digestivoPancreas', opt, indexAve ) } } style={{ width: 120 }} >
                      <Option value={ 0 }>0</Option>
                      <Option value={ 1 }>1</Option>
                      <Option value={ 2 }>2</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].digestivoPancreas } </span>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="block">
            <div className="head"> Macroscopia Intestinal </div>
            <div className="body">
              <div className="item">
                <div className="tag">Intestino Delgado</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].macroscopiaIntestinalIntestinoDelgado } onChange={ opt => { updateChickenData( 'macroscopiaIntestinalIntestinoDelgado', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ 0 }>0</Option>
                      <Option value={ 1 }>1</Option>
                      <Option value={ 2 }>2</Option>
                      <Option value={ 3 }>3</Option>
                      <Option value={ 4 }>4</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].macroscopiaIntestinalIntestinoDelgado } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag">Intestino Grueso</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].macroscopiaIntestinalIntestinoGrueso } onChange={ opt => { updateChickenData( 'macroscopiaIntestinalIntestinoGrueso', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ 0 }>0</Option>
                      <Option value={ 1 }>1</Option>
                      <Option value={ 2 }>2</Option>
                      <Option value={ 3 }>3</Option>
                      <Option value={ 4 }>4</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].macroscopiaIntestinalIntestinoGrueso } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag">Ciegos</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].macroscopiaIntestinalCiegos } onChange={ opt => { updateChickenData( 'macroscopiaIntestinalCiegos', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ 0 }>0</Option>
                      <Option value={ 1 }>1</Option>
                      <Option value={ 2 }>2</Option>
                      <Option value={ 3 }>3</Option>
                      <Option value={ 4 }>4</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].macroscopiaIntestinalCiegos } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag">Contenido Intestinal</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select mode="multiple" value={ formulario.aves[ indexAve ].macroscopiaIntestinalContenido } onChange={ opt => { updateChickenData( 'macroscopiaIntestinalContenido', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value="Normal">Normal</Option>
                      <Option value="Mucoide">Mucoide</Option>
                      <Option value="Sanguinolento">Sanguinolento</Option>
                      <Option value="Descamacion">Descamacion</Option>
                      <Option value="TasaPasajeAumentado">Tasa de pasaje aumentado</Option>
                      <Option value="Liquido">Liquido</Option>
                      <Option value="ConsumoAlphitobius">Consumo de Alphitobius</Option>
                      <Option value="ConsumoCama">Consumo de Cama</Option>
                    </Select>
                    :
                    <span> { typeof formulario.aves[ indexAve ].macroscopiaIntestinalContenido === 'string' ? formulario.aves[ indexAve ].macroscopiaIntestinalContenido : formulario.aves[ indexAve ].macroscopiaIntestinalContenido.join(' - ') } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag">Tono Intestinal</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].macroscopiaIntestinalTono } onChange={ opt => { updateChickenData( 'macroscopiaIntestinalTono', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value="Malo">Malo</Option>
                      <Option value="Intermedio">Intermedio</Option>
                      <Option value="Bueno">Bueno</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].macroscopiaIntestinalTono } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag">Enteritis Necrotica</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].macroscopiaIntestinalEnteritisNecrotica } onChange={ opt => { updateChickenData( 'macroscopiaIntestinalEnteritisNecrotica', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ true }>SI</Option>
                      <Option value={ false }>NO</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].macroscopiaIntestinalEnteritisNecrotica } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag">Retencion de Yema</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].macroscopiaIntestinalRetencionYema } onChange={ opt => { updateChickenData( 'macroscopiaIntestinalRetencionYema', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ true }>SI</Option>
                      <Option value={ false }>NO</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].macroscopiaIntestinalRetencionYema } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag">Presencia de Parasitos</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].macroscopiaIntestinalPresenciaParasitos } onChange={ opt => { updateChickenData( 'macroscopiaIntestinalPresenciaParasitos', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ true }>SI</Option>
                      <Option value={ false }>NO</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].macroscopiaIntestinalPresenciaParasitos } </span>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="block">
            <div className="head">Microscopia Intestinal</div>
            <div className="body">
              <div className="item">
                <div className="tag" style={{ fontStyle: 'italic' }}>E. acervulina</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].microscopiaIntestinalIntestinoDelgado } onChange={ opt => { updateChickenData( 'microscopiaIntestinalIntestinoDelgado', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ 0 }>0</Option>
                      <Option value={ 1 }>1</Option>
                      <Option value={ 2 }>2</Option>
                      <Option value={ 3 }>3</Option>
                      <Option value={ 4 }>4</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].microscopiaIntestinalIntestinoDelgado } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag" style={{ fontStyle: 'italic' }}>E. maxima</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].microscopiaIntestinalIntestinoGrueso } onChange={ opt => { updateChickenData( 'microscopiaIntestinalIntestinoGrueso', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ 0 }>0</Option>
                      <Option value={ 1 }>1</Option>
                      <Option value={ 2 }>2</Option>
                      <Option value={ 3 }>3</Option>
                      <Option value={ 4 }>4</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].microscopiaIntestinalIntestinoGrueso } </span>
                  }
                </div>
              </div>
              <div className="item">
                <div className="tag" style={{ fontStyle: 'italic' }}>E. tenella</div>
                <div className="value">
                  {
                    editionMode ?
                    <Select value={ formulario.aves[ indexAve ].microscopiaIntestinalCiegos } onChange={ opt => { updateChickenData( 'microscopiaIntestinalCiegos', opt, indexAve ) } }  style={{ width: 120 }} >
                      <Option value={ 0 }>0</Option>
                      <Option value={ 1 }>1</Option>
                      <Option value={ 2 }>2</Option>
                      <Option value={ 3 }>3</Option>
                      <Option value={ 4 }>4</Option>
                    </Select>
                    :
                    <span> { formulario.aves[ indexAve ].microscopiaIntestinalCiegos } </span>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const FormModal = ( props ) => {

  const [ loading, setLoading ] = useState( true )
  const [ formData, setFormData ] = useState( null )

  useEffect( () => {
    if ( props.form ) {
      formsService.getFormData( props.form ).then( response => {
        setFormData( response.data.info )
        setLoading( false )
      } )
    }
  }, [ props.form ] )

  // RENDERIZADO DEL COMPONENTE MODAL
  return (
    <div>
      <Modal
              isOpen={ props.form != null }
              onRequestClose={ props.handlerClose }
              style={ customStyles }
              contentLabel="Example Modal"
            >

        <div className="modalContainer formData">
          { loading ? <LoadingComponent /> : <InfoComponent products={ props.products } users={ props.users } farms={ props.farms } updateForm={ props.updateForm } formData={ formData } t={ props.t } /> }
        </div>

      </Modal>
    </div>
  )
}

function convertDateISO( data_in, t ){
    const keyMonth = {
      1: t("January"),
      2: t("February"),
      3: t("March"),
      4: t("April"),
      5: t("May"),
      6: t("June"),
      7: t("July"),
      8: t("August"),
      9: t("September"),
      10: t("October"),
      11: t("November"),
      12: t("December")
    }
  	const fecha = new Date(Date.parse(data_in));
  	const data_out = fecha.getDate() + " " + keyMonth[fecha.getMonth()+1].substr(0, 3)+" '"+fecha.getFullYear().toString().slice(-2);
  	return data_out;
  }


const mapStateToProps = ( state ) => {

  return {
    farms: state.farms,
    users: state.users,
    products: state.products
  }

}

export default compose(withNamespaces(), connect( mapStateToProps, { updateForm } ))(FormModal)
