import React, { useEffect, useState } from 'react';
import environment from '../../../../../environment/environment';
import authService from '../../../../../services/Auth';
import { IconContext } from "react-icons";
import { FiCameraOff, FiSearch } from "react-icons/fi";

import ModalCarouselPhotos from './ModalCarouselPhotos';

import formsService from '../../../../../services/Forms';

import { Trans } from 'react-i18next';

const PhotosCarousel = props => {
  return (
    <div className="photosContainer">
      {
        props.photos.map( ( photo, index ) => {
          return (
            <div className="photo" key={ index } onClick={ () => { props.opener( index ) } }>
              <img src={ `${ environment.url }/formulario/image/${ photo }/${ props.userID }/${ props.formID }/${ authService.getToken() }` } alt="mobile" />
              <div className="icon">
                <IconContext.Provider value={{ color: "rgba(235, 238, 253, 0.8)", size: "40px" }}>
                  <div>
                    <FiSearch />
                  </div>
                </IconContext.Provider>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

const NoPhotos = () => {
  return (
    <div className="noPhotos">
      <div className="icon">
        <IconContext.Provider value={{ color: "rgb(235, 238, 253)", size: "60px" }}>
          <div>
            <FiCameraOff />
          </div>
        </IconContext.Provider>
      </div>
      <div className="message">
        <Trans>¡ No photos taken !</Trans>
      </div>
    </div>
  )
}

const PhotosCard = ( props ) => {

  const [ photosModalOpen, setPhotosModalOpen ] = useState( false );
  const [ initialSlide, setInitialSlide ] = useState( 0 );

  const openModalInSlide = ( slide ) => {
    setInitialSlide( slide );
    setPhotosModalOpen( true );
  }

  return (
    <div className="card photos">
      <div className="title"> <Trans>Photos</Trans> </div>
      {
        props.photos.length ? <PhotosCarousel opener={ openModalInSlide } photos={ props.photos } formID={ props.formID } userID={ props.userID }/> : <NoPhotos />
      }
      <ModalCarouselPhotos initialSlide={ initialSlide } photos={ props.photos } formID={ props.formID } userID={ props.userID } open={ photosModalOpen } handlerClose={ () => { setPhotosModalOpen( false ) } } />
    </div>
  )
}

export default PhotosCard
