const removeForm = formID => {

  return {
    type: 'REMOVE_FORM',
    payload: formID
  }

}

export default removeForm;
