import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';

import Header from './components/Header';
import CardData from './components/CardData';
import MapPosition from './components/MapPosition';
import FarmsList from './components/FarmsList';
import EditFarmPosition from './components/EditFarmPosition';

import updateFarm from '../../../actions/updateFarm';

import './farmsPage.css';

const FarmsPage = ( props ) => {

  const [ farmSelected, setFarmSelected ] = useState( props.farms[ 0 ] )
  const [ mode, setMode ] = useState( 'LIST' )

  useEffect( () => {

    const farmSelectedNew = props.farms.find( f => f._id === farmSelected._id )
    if ( farmSelectedNew ) setFarmSelected( farmSelectedNew )

  }, [ props.farms ] )

  // RENDERIADO DEL COMPONENTE
    // LOADING
    // COMPONENTE
  if ( mode === 'LIST' ){
    if ( props.farms.length ){
      return (
        <div className="farmsPage">
          <Header />
          <div className="container">
            <div className="column">
              <CardData farm={ farmSelected } />
              <MapPosition farm={ farmSelected } farms={ props.farms } setMode={ setMode } />
            </div>
            <div className="column">
              <FarmsList farms={ props.farms } selectFarm={ setFarmSelected } farmSelected={ farmSelected } />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="farmsPage">
          <Header />
            <div className="noFarms">
              <div className="logo">
                <img src="/assets/img/icons/noZones.svg" alt="noZones" />
              </div>
              <div className="title"> { props.t( 'No Farms!' )} </div>
              <div className="message">
                <p> { props.t( 'No farm has yet been created in this space.' )} </p>
              </div>
            </div>
        </div>
      )
    }
  } else if ( mode === 'EDIT_FARM_POSITION' ){
    return <EditFarmPosition farm={ farmSelected } setMode={ setMode } updateFarm={ props.updateFarm } />
  }


}

const mapStateToProps = ( state ) => {

  return {
    farms: state.farms
  }

}

export default compose(withNamespaces(), connect( mapStateToProps, { updateFarm } ))(FarmsPage)