import React from 'react';

import CommentsCard from './CommentsCard';
import PhotosCard from './PhotosCard';

import { Trans } from 'react-i18next';

import './observations.css'

const ObservationsSection = ( props ) => {

  return (
    <div className="section observations" id="observationsSection">
      <div className="title"> <Trans>Observations</Trans> </div>
      <div className="rowContainer">
        <CommentsCard comments={ props.form.datosFormulario.observaciones } />
        <PhotosCard formID={ props.form._id } userID={ props.form.datosFormulario.usuario._id } photos={ props.form.datosFormulario.savedPhotos } />
      </div>
    </div>
  )
}

export default ObservationsSection
