import Api from './Api';
import authService from './Auth';

const farmsService = {

  get: (  ) => {

    return Api.get( '/granja', {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  put: ( farmID, jsonData ) => {

    return Api.put( '/granja/' + farmID, jsonData, {
      headers: {
        token: authService.getToken()
      }
    } )

  },

}

export default farmsService
