import React from 'react'
import { withNamespaces } from 'react-i18next';
import { Progress } from 'antd';

import { IconContext } from "react-icons";
import { TiInfoLarge } from "react-icons/ti";

const ProgressTractos = props => {

  const indexValue = Math.round( props.formData.resultadosIndices.lesionesSistemaDigestivo )
  const tractoSuperior = Math.round( ( 6 - ( props.formData.promedios.digestivoCavidadBucal + props.formData.promedios.digestivoProventriculo + props.formData.promedios.digestivoMolleja ) ) / 6 * 100 )
  const tractoInferior = Math.round( ( 21 - ( props.formData.promedios.digestivoHigado + props.formData.promedios.digestivoPancreas + props.formData.promedios.macroscopiaIntestinalIntestinoDelgado + props.formData.promedios.macroscopiaIntestinalIntestinoGrueso + props.formData.promedios.macroscopiaIntestinalCiegos ) ) / 21 * 100 )

  let colorIndex, colorTractoSuperior, colorTractoInferior, colorLiver, liverTag;
  if ( indexValue <= 40 ){
    colorIndex = '#d9534f'
  } else if ( indexValue <= 70 ){
    colorIndex = '#f0ad4e'
  } else {
    colorIndex = '#5cb85c'
  }

  if ( tractoSuperior <= 40 ){
    colorTractoSuperior = '#d9534f'
  } else if ( tractoSuperior <= 70 ){
    colorTractoSuperior = '#f0ad4e'
  } else {
    colorTractoSuperior = '#5cb85c'
  }

  if ( tractoInferior <= 40 ){
    colorTractoInferior = '#d9534f'
  } else if ( tractoInferior <= 70 ){
    colorTractoInferior = '#f0ad4e'
  } else {
    colorTractoInferior = '#5cb85c'
  }

  if ( props.formData.promedios.digestivoHigado > 3 ){
    colorLiver = '#d9534f'
    liverTag = 'The livers are in very bad condition'
  } else if ( props.formData.promedios.digestivoHigado >= 2 ){
    colorLiver = '#f0ad4e'
    liverTag = 'The quality of the liver is compromised'
  } else if ( props.formData.promedios.digestivoHigado < 2 ) {
    colorLiver = '#5cb85c'
    liverTag = 'The livers do not present alterations'
  }

    return (
      <div className="tractos">
        <div className="tracto superior">
          <div className="tag">{ props.t( 'Tracto Superior' ) }</div>
          <div className="value">{ tractoSuperior } %</div>
          <div className="bar">
            <Progress strokeColor={ colorTractoSuperior } percent={ tractoSuperior } showInfo={false} />
          </div>
          <div className="info">
            <div className="icon">
              <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
                <div>
                  <TiInfoLarge />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tooltip">
              <div className="title">{ props.t( 'Info' ) }</div>
              <hr />
              <div className="content">
                <span> { props.t( 'Evaluates lesions of the ' ) } </span>
                <span> <b> { props.t( 'oral cavity, proventriculus, gizzard, liver and pancreas.' ) } </b> </span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="tracto inferior">
          <div className="tag">{ props.t( 'Tracto Inferior' ) }</div>
          <div className="value">{ tractoInferior } %</div>
          <div className="bar">
            <Progress strokeColor={ colorTractoInferior } percent={ tractoInferior } showInfo={false} />
          </div>
          <div className="info">
            <div className="icon">
              <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
                <div>
                  <TiInfoLarge />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tooltip">
              <div className="title">{props.t('Info')}</div>
              <hr />
              <div className="content">
                <span> { props.t( 'Evaluates lesions of the ' ) } </span>
                <span> <b> { props.t( 'intestines and cecum, as well as the state of the intestinal contents.' ) } </b> </span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="liver">
          <div className="iconState" style={ { background: colorLiver } } ></div>
          <div className="tag">{ props.t( liverTag ) }</div>
          <div className="info">
            <div className="icon">
              <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
                <div>
                  <TiInfoLarge />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tooltip">
              <div className="title">{props.t('Info')}</div>
              <hr />
              <div className="content">
                <span> { props.t( 'Due to its importance for the productive performance, we can observe the ' ) } </span>
                <span> <b> { props.t( 'general quality of the liver ' ) } </b> </span>
                <span> { props.t( 'in the birds tested.' ) } </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default withNamespaces()(ProgressTractos)
