import formsService from '../services/Forms';

const getForms = () => {

  return async ( dispatch ) => {

    const response = await formsService.get();

    dispatch({
      type: 'GET_FORMS',
      payload: response.data.info
    })
  }

}

export default getForms;
