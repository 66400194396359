import React from 'react';

import SpeedometerCard from './SpeedometerCard';
import CompositionCard from './CompositionCard';

import { withNamespaces } from 'react-i18next';

import './clostridiosis.css'

const ClostridiosisSection = ( props ) => {
  return (
    <div className="section clostridiosis" id="clostridiosisSection">
      <div className="title"> { props.t( 'Disbiosis' ) } </div>
      <div className="rowContainer">
        <SpeedometerCard value={ Math.round( props.form.resultadosIndices.riesgoDeClostridiosis ) / 100 } />
        <CompositionCard form={ props.form } />
      </div>
    </div>
  )
}

export default withNamespaces()(ClostridiosisSection)
