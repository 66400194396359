import farmsService from '../services/Farms';

const getFarms = () => {

  return async ( dispatch ) => {

    const response = await farmsService.get()

    dispatch({
      type: 'GET_FARMS',
      payload: response.data.info
    })
  }

}

export default getFarms;
