import React from 'react';
import { withNamespaces } from 'react-i18next';

const TooltipComponent = ({ active, payload, label, t }) => {

  if (active) {

    const { state, color } =  getState( Math.round( payload[0].payload.value ) )

    return (
      <div className="custom-tooltip">
        <div className="titulo">
          <div className="percentage"> { Math.round( payload[0].payload.value ) } % </div>
        </div>
        <div className="body">
          <div className="farm">
            <div className="tag"> { t('Farm') }: </div>
            <div className="value"> { payload[0].payload.farm } </div>
          </div>
          <div className="date">
            <div className="tag"> { t('Date') }: </div>
            <div className="value"> { payload[0].payload.date } </div>
          </div>
          <div className="user">
            <div className="tag"> { t('User') }: </div>
            <div className="value"> { payload[0].payload.user } </div>
          </div>
          {
            payload[0].payload.galpon &&
            <div className="user">
              <div className="tag"> { t('Galpón') }: </div>
              <div className="value"> { payload[0].payload.galpon } </div>
            </div>
          }
          <div className="user">
            <div className="tag"> { t('Age') }: </div>
            <div className="value"> { payload[0].payload.age + ' ' + t( 'days' ) } </div>
          </div>
          <div className="state">
            <div className="tag"> { t('State') }: </div>
            <div className="value" style={{ color: color }}> { t( state ) } </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const getState = value => {

  let color, state;

  if ( value <= 40 ){
    color = '#d9534f';
    state = 'Danger';
  } else if ( value <= 70 ){
    color = '#f0ad4e';
    state = 'Warning';
  } else {
    color = '#5cb85c';
    state = 'Safe';
  }

  return {
    state: state,
    color: color
  }
}

export default withNamespaces()(TooltipComponent)
