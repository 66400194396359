import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';

import Header from './components/Header';
import FormulariosList from './components/FormsList';
import NoForms from './components/NoForms';

import './formsPage.css';

const FormsPage = ( props ) => {

  return (
    <div className="formsPage">
      <Header formularios={ props.forms } users={ props.users } />
      {
        props.forms.length
        ?
        <FormulariosList formularios={ props.forms } users={ props.users } />
        :
        <NoForms  />
      }
    </div>
  )

}

const mapStateToProps = ( state ) => {

  return {
    forms: state.forms,
    users: state.users
  }

}

export default compose(withNamespaces(), connect( mapStateToProps ))(FormsPage)