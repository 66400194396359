const usersReducer = ( users = null, action ) => {

  if ( action.type === 'GET_USERS' ){
    return action.payload;
  }

  return users;

}

export default usersReducer;
