import React, { useState, useEffect } from 'react';

import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import { Trans } from 'react-i18next';
import { withNamespaces } from 'react-i18next';
import NewProduct from './NewProduct';

import productsService from '../../../../services/Products';

import { IconContext } from "react-icons";
import { FiTrash } from "react-icons/fi";

// COMPONENTS

const ProductListFilter = ( props ) => {

  const [ modalNewProduct, setModalNewProduct ] = useState( false )

  // HANDLER PARA EL CAMBIO DE VALOR EN EL INPUT DE FILTRO
  const handleChange = ( event ) => {

    const value = event.target.value;

    props.filterChangeHandler( value );
  }

  return (
    <div className="filter">
      <div className="search">
        <input name="product" placeholder={ props.t('Search...') } value={ props.filterString } onChange={ handleChange } />
      </div>
      <div className="new">
        <Button variant="success" onClick={ () => { setModalNewProduct( true ) } }> { props.t( 'New' )} </Button>

        <NewProduct updateProduct={ props.updateProduct } type={ props.type } open={ modalNewProduct } handlerClose={ () => { setModalNewProduct( false ) } } />
      </div>
    </div>
  )
}

// ITEM LIST DEL BODY
const ProductListItem = ( props ) => {

  const deleteProduct = ( product ) => {

    const t = props.t

    // DISPARO ALERT DE CONFIRMACION
    Swal.fire({
              title: t( 'Delete Product' ),
              text: t( 'Are you sure you want to delete this product?' ),
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText: t( 'Cancel' ),
              confirmButtonText: t( 'Yes, delete it!' ),
              showLoaderOnConfirm: true,
              // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
              preConfirm: ( value ) => {
                return productsService.delete( product._id )
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
              if ( result.value ) {
                Swal.fire(
                  t( 'Deleted!' ),
                  t( 'Product has been deleted successfully.' ),
                  'success'
                )

                props.updateProduct( product._id, 'REMOVE' )

              }
            })


  }

  return (
    <div className='productListItem'>
      <div></div>
      <div className="name"> { props.product.nombre } </div>
      <div className="actions">
        <div className="action remove" onClick={ () => deleteProduct( props.product ) }>
          <IconContext.Provider size={ 20 } value={{ className: "global-class-name" }} >
            <div>
              <FiTrash />
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const Products = ( props ) => {

  // VARIABLES DE ESTADO DE PAGINACION Y FILTRO
  const [ filteredList, setFilteredList ] = useState( [] );
  const [ filterString, setFilterString ] = useState( '' );

  // HOOK PARA ACTUALIZACION DE LISTA DE USUARIOS EN FUNCION DEL FILTRO
  useEffect( () => {
    const fullList = props.products.map( prod => {

      const fullName = prod.nombre

      if ( fullName.toUpperCase().includes( filterString.toUpperCase() ) ){
        return <ProductListItem t={ props.t } key={ prod._id } product={ prod } updateProduct={ props.updateProduct }  />
      }

    } ).filter( data => data )

    setFilteredList( fullList );

  }, [ filterString, props.products ] )


  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="productList">
      <div className="title"> { props.title } </div>
      <hr />
      <ProductListFilter updateProduct={ props.updateProduct } type={ props.type } filterString={ filterString } filterChangeHandler={ setFilterString } t={ props.t } />
      <div className="list">
        { filteredList }
      </div>
    </div>
  )
}


export default withNamespaces()(Products)
