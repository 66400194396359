import React from 'react';
import { connect } from 'react-redux';
import { Checkbox, DatePicker, Slider, Select } from 'antd';

import { Trans } from 'react-i18next';

const { Option } = Select;

const FarmsFilter = props => {

  const handlerFarmsChange = farmsSelected => {

    props.setFarms( farmsSelected );

  }

  return (
    <div className="filter farms">
      <div className="tag"> <Trans>Farms</Trans> </div>
      <div className="selector">
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
          value={ props.farms }
          onChange={ handlerFarmsChange }
        >
          { props.initialFarms.map( farm => {
              return <Option key={ farm._id } value={ farm._id } > { farm.nombre } </Option>
            } )
          }
        </Select>
        <div className="noneAllSelector">
          <div className="all" onClick={ () => { props.setFarms( props.initialFarms.map( farm => farm._id ) ) } }> <Trans>Select All</Trans> </div>
          <div className="separator"> | </div>
          <div className="none" onClick={ () => { props.setFarms( [] ) } }> <Trans>None</Trans> </div>
        </div>
      </div>
    </div>
  )

}

const mapStateToProps = ( state ) => {

  return {
    initialFarms: state.farms
  }

}

export default connect( mapStateToProps )(FarmsFilter)
