import React from 'react';
import { IconContext } from "react-icons";
import { FiFilter } from "react-icons/fi";
import { FaChartLine } from "react-icons/fa";
import Button from 'react-bootstrap/Button';

import { Trans } from 'react-i18next';
import authService from '../../../../services/Auth';
import env from '../../../../environment/environment';

const FilterButton = ( props ) => {
  return (
    <div className="right">
      <div className="filterOpener" onClick={ () => { props.openPanelHandler( true ) } }>
        <div className="icon">
          <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
            <div>
              <FiFilter />
            </div>
          </IconContext.Provider>
        </div>
        <div className="tag"> <Trans>Filter</Trans> </div>
      </div>
    </div>
  )
}

const Header = ( props ) => {

  const getExcel = () => {
  
    var f = document.getElementById('excelGraphForms');
    f.token.value = authService.getToken();
    f.forms.value = JSON.stringify( props.forms.map( f => f._id ) );
    window.open('', 'TheWindow');
    f.submit();

  }

  return (
    <div className="header">
      <div className="left">
        <div className="title"> <Trans>Dashboards</Trans> </div>
        <div className="separator"> | </div>
        <div className="icon">
          <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
            <div>
              <FaChartLine />
            </div>
          </IconContext.Provider>
        </div>
        <div className="type"> <Trans>Intestinal Quality</Trans> </div>
        <div className="switch">
          {
            props.averageMode
            ?
            <div className="btn-group">
              <Button variant="outline-primary" onClick={ () => { props.setAverageMode( false ) } }> <Trans>See Individual</Trans> </Button>
              <Button variant="outline-primary" onClick={ getExcel }> { <Trans>Download Excel</Trans> } </Button>
              <form id="excelGraphForms" method="post" action={ env.url + '/files/excel' } target="TheWindow">
                <input type="hidden" name="forms" value="content" />
                <input type="hidden" name="token" value="content" />
              </form>
              <Button variant="outline-primary" onClick={ props.reportOpener }> <Trans>Generate Report</Trans> </Button>
            </div>
            :
            // <Dropdown.Button onClick={ () => { props.setAverageMode( true ) } } overlay={
            //                                                       <Menu>
            //                                                         <Menu.Item onClick={ props.formOpener } >
            //                                                         <Trans>Edit Form</Trans>
            //                                                         </Menu.Item>
            //                                                         <Menu.Item onClick={ props.reportOpener } >
            //                                                           <Trans>Generate Report</Trans>
            //                                                         </Menu.Item>
            //                                                       </Menu>
            //                                                       }>
            //     <Trans>See Average</Trans>
            // </Dropdown.Button>
            <div className="btn-group">
              <Button variant="outline-primary" onClick={ () => { props.setAverageMode( true ) } }> <Trans>See Average</Trans> </Button>
              <Button variant="outline-primary" onClick={ props.formOpener }> <Trans>Edit Form</Trans> </Button>
              <Button variant="outline-primary" onClick={ props.reportOpener }> <Trans>Generate Report</Trans> </Button>
            </div>
          }
        </div>
      </div>
      {
        <FilterButton openPanelHandler={ props.openPanelHandler } />
      }
    </div>
  )
}

export default Header
