import React, { useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import { withNamespaces } from 'react-i18next';
import { IconContext } from "react-icons";
import { FaRegCheckCircle } from "react-icons/fa";
import { TiInfoLarge } from "react-icons/ti";
import GraphComposition from './GraphComposition';

const DigestiveSystemDistribution = ( props ) => {
  
  // SI TODOS LOS VALORES SON 0, SETEO TODOS LOS VALORES EN 1 PARA VISUALIZAR EN EL GRAFICO EL PORCENTAJE DE INCIDENCIA
  if ( 
      props.formData.promedios.digestivoHigado == 0 &&
      props.formData.promedios.digestivoPancreas == 0 &&
      props.formData.promedios.macroscopiaIntestinalIntestinoDelgado == 0 &&
      props.formData.promedios.macroscopiaIntestinalIntestinoGrueso == 0 &&
      props.formData.promedios.macroscopiaIntestinalCiegos == 0 &&
      props.formData.promedios.digestivoCavidadBucal == 0 &&
      props.formData.promedios.digestivoProventriculo == 0 &&
      props.formData.promedios.digestivoMolleja == 0
     ) {
    return (
      <div className="card distribution">
        <div className="head">
          <div className="title"> { props.t( 'Injuries Distribution' ) } </div>
          <div className="info">
            <div className="icon">
              <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
                <div>
                  <TiInfoLarge />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tooltip">
              <div className="title">{props.t('Info')}</div>
              <hr />
              <div className="content">
                  <span> { props.t( 'Indicates the distribution of macroscopic lesions that ' ) } </span>
                  <span> <b> { props.t( 'intervene in the construction of the index ' ) } </b> </span>
                  <span> { props.t( 'of digestive system injuries.' ) } </span>
              </div>
            </div>
          </div>
        </div>
        <div className="success">
          <div className="icon">
            <img src="/assets/img/icons/success.svg" alt="succcess" />
          </div>
          <div className="message">
            { props.t( 'There is not any digestive system injuries' ) }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="card distribution">
      <div className="head">
        <div className="title"> { props.t( 'Injuries Distribution' ) } </div>
        <div className="info">
          <div className="icon">
            <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
              <div>
                <TiInfoLarge />
              </div>
            </IconContext.Provider>
          </div>
          <div className="tooltip">
              <div className="title">{props.t('Info')}</div>
              <hr />
              <div className="content">
                  <span> { props.t( 'Indicates the distribution of macroscopic lesions that ' ) } </span>
                  <span> <b> { props.t( 'intervene in the construction of the index ' ) } </b> </span>
                  <span> { props.t( 'of digestive system injuries.' ) } </span>
              </div>
            </div>
        </div>
      </div>
      <div className="rowContainer">
        <GraphComposition formData={ props.formData } />
      </div>
    </div>
  )
}

export default withNamespaces()(DigestiveSystemDistribution)
