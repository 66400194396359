import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as L from 'leaflet';
import 'leaflet-draw';
// import 'leaflet/dist/leaflet.css';
import Button from 'react-bootstrap/Button';
import { Trans } from 'react-i18next';
import zonesService from '../../../../services/Zones';
import Swal from 'sweetalert2'

import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';

import 'react-leaflet';

const NewZone = ( props ) => {

  const [ drawnItemsState, setDrawnItemsState ] = useState( null );

  useEffect( () => {

    const map = L.map('map')
    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    let polygons = []

    if ( props.zone ){

      const geojson = JSON.parse( props.zone.geojson );

      const bounds = L.geoJSON( geojson ).getBounds()

      map.fitBounds( bounds )

      for ( let feat in geojson.features ){
        polygons.push( L.polygon( geojson.features[feat].geometry.coordinates[0].map( coord => [ coord[ 1 ], coord[ 0 ] ] ) ) )
      }

      document.getElementById('newZoneName').value = props.zone.nombre
    }


    props.farms.map( farm => {
      if ( farm.posicion ) return L.marker([ farm.posicion.latitud, farm.posicion.longitud ])
                                                                .bindPopup( farm.nombre )
                                                                .addTo( map );

    } )

    // FeatureGroup is to store editable layers
    const drawnItems = new L.FeatureGroup( polygons );
    map.addLayer(drawnItems);
    const drawControl = new L.Control.Draw({
      draw: {
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false,
        polyline: false
      },
      edit: {
           featureGroup: drawnItems
       }
    });
    map.addControl(drawControl);

    setDrawnItemsState( drawnItems );

    map.on('draw:created', function (e) {
        // Do whatever else you need to. (save to db, add to map etc)
        drawnItems.addLayer(e.layer);
    });

  }, []
 )

 // FUNCION DE CREACION DE ZONA
  const editZone = ( ) => {

    const zoneName = document.getElementById('newZoneName').value;
    const zoneGeojson = JSON.stringify( drawnItemsState.toGeoJSON() )

    if ( !zoneName ){
      Swal.fire({
        icon: 'warning',
        title: 'Incompleted Fields!',
        text: 'Write a name for the zone.'
      })

      return;
    }

    if ( !drawnItemsState.toGeoJSON().features.length ){
      Swal.fire({
        icon: 'warning',
        title: 'Draw not found!',
        text: 'Draw the shape of the zone.'
      })

      return;
    }

    // ALERT DE LOADING
    Swal.fire ({
       title: '..Saving your new zone..',
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // CAPTURO LOS DATOS PARA ENVIAR A LA API POST /ZONES
    const jsonData = {
      nombre: zoneName,
      geojson: zoneGeojson
    }

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    zonesService.put( props.zone._id, jsonData, {
      success: () => {
        Swal.close();
        props.successHandler();
      }
    } )

  }

 // FUNCION DE CREACION DE ZONA
  return (
    <div className="zonesPage map">
      <div className="mapContainer">
        <div id="map"></div>
        <div className="card">
          <div className="title"> <Trans> Edit Zone </Trans> </div>
          <div className="form">
            <input id="newZoneName" type="text" name="zone" placeholder="Zone Name" />
          </div>
          <div className="actions">
            <Button variant="" onClick={ props.cancelHandler }> <Trans> Cancel </Trans> </Button>
            <Button variant="success" onClick={ editZone }> <Trans> Save </Trans> </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ( state ) => {

  return {
    farms: state.farms
  }

}

export default connect( mapStateToProps )(NewZone)
