const formsReducer = ( forms = null, action ) => {

  if ( action.type === 'GET_FORMS' ){
    return action.payload;
  } else if ( action.type === 'REMOVE_FORM' ) {
    return forms.filter( form => form._id !== action.payload )
  } else if ( action.type === 'UPDATE_FORM' ) {
    return forms.map( form => {
      if ( form._id === action.payload._id ){
        
        return action.payload
      } else {
        return form
      }
    } )
  }

  return forms;

}

export default formsReducer;
