import Api from './Api';
import authService from './Auth';

const notificationsService = {

  get: (  ) => {

    return Api.get( '/notificaciones', {
      headers: {
        token: authService.getToken()
      }
    } )
  },
  post: ( jsonData, jsonCallbacks ) => {

    if ( jsonCallbacks ) if ( jsonCallbacks.beforeSend ) jsonCallbacks.beforeSend();

    return Api.post( '/notificaciones', jsonData, {
      headers: {
        token: authService.getToken()
      }
    } ).then( data => {

      if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success( data.data.info );
    } ).catch( () => {
      if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
    } )
  },
  put: ( notificationConfigID, jsonData, jsonCallbacks ) => {

    if ( jsonCallbacks ) if ( jsonCallbacks.beforeSend ) jsonCallbacks.beforeSend();

    return Api.put( '/notificaciones/' + notificationConfigID, jsonData, {
      headers: {
        token: authService.getToken()
      }
    } ).then( data => {

      if ( jsonCallbacks ) if ( jsonCallbacks.success ) jsonCallbacks.success( data.data.info );
    } ).catch( () => {
      if ( jsonCallbacks ) if ( jsonCallbacks.error ) jsonCallbacks.error();
    } )
  }

}

export default notificationsService
