import React, { useState } from 'react';
import environment from '../../../environment/environment';
import authService from '../../../services/Auth';
import Swal from 'sweetalert2'

import { useHistory, useLocation, useParams } from "react-router-dom";

import './recovery.css'

const RevoveryPasswordPage = () => {

  const [ passwordVisible, setPasswordVisible ] = useState( false );
  const { t } = useParams();

  const recovery = () => {

    const password1 = document.getElementById('password').value
    const password2 = document.getElementById('password2').value

    if ( password1 !== password2 ){
      Swal.fire({
        title: 'Update Error',
        text: 'Las contraseñas que ingresaste no coinciden',
        icon: 'error',
        confirmButtonText: 'Retry'
      })
    }

    authService.updatePasswordOutside({
      passwordNueva: password1,
      passwordNueva2: password2,
      t: t
    })
    .then( res => {

      Swal.fire({
        title: 'Update Successfull!',
        text: 'Tu contraseña fue actualizada correctamente!',
        icon: 'success',
        confirmButtonText: 'Enter to VIIP'
      })
      .then((result) => {
        // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
        if ( result.value ) {

          window.localStorage.setItem('t', t );

          window.location = environment.front_url;

        }
      })

    } )
    .catch( err => {
      Swal.fire({
        title: 'Update Error!',
        text: 'Tu contraseña no pudo ser actualizada!',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    } )
  }

  return (
    <div className="recoveryContainer">

      <div className="overlay">
        <div className="card">
          <div className="title">
            <img src="/assets/img/logo_vetanco.svg" alt="logo vetanco" />
          </div>

          <div className="form">
            <div id="password-field" className="field-wrapper input">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                <input id="password" name="password" type='text' className="form-control" placeholder="New Password" />
            </div>

            <div id="password-field2" className="field-wrapper input">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                <input id="password2" name="password" type='text' className="form-control" placeholder="Repeat New Password" />
            </div>
          </div>

          <div className="actions">
            <button onClick={ recovery } className="btn btn-primary">Recovery Password</button>
          </div>

        </div>
      </div>

    </div>
  )
}

export default RevoveryPasswordPage
