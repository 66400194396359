import React, { useState } from 'react';
import { connect } from 'react-redux';
import SlidingPanel from 'react-sliding-side-panel';
import { Checkbox, DatePicker, Slider, Select } from 'antd';
import Button from 'react-bootstrap/Button';

import { Trans } from 'react-i18next';
import productsList from '../../../../../constants/constants';

import DateFilter from './items/DateFilter';
import ZonesFilter from './items/ZonesFilter';
import UsersFilter from './items/UsersFilter';
import FarmsFilter from './items/FarmsFilter';
import FarmTypeFilter from './items/FarmTypeFilter';
import FarmDivisionFilter from './items/FarmDivisionFilter';
import ChickenAgeFilter from './items/ChickenAgeFilter';
import VacunacionFilter from './items/VacunacionFilter';
import CoccidiostatosFilter from './items/CoccidiostatosFilter';
import PromotorsFilter from './items/PromotorsFilter';

import './filter.css';

const FilterPanel = ( props ) => {

  return (
    <div>
      <SlidingPanel
        type={ 'right' }
        isOpen={ props.openPanel }
        size={ 30 }
        backdropClicked={ e => { props.openPanelHandler( false ) } }
      >
        <div>
          <div className="header">
            <div className="title"> <Trans>Filters</Trans> </div>
            <div className="action">
               <Button variant="empty" style={{ color: 'white' }} onClick={ props.restoreFilters } > <Trans> Restaurar </Trans> </Button>
            </div>
          </div>
          <div className="filters">

            <DateFilter dateOption={ props.filters.date } setDateOption={ ( date ) => { props.modifyFilter( 'date', date ) } } datePeriod={ props.filters.period } setDatePeriod={ ( period ) => { props.modifyFilter( 'period', period ) } } />

            <ZonesFilter zones={ props.filters.zones } setZones={ ( zones ) => { props.modifyFilter( 'zones', zones ) } } />

            <UsersFilter users={ props.filters.users } setUsers={ ( users ) => { props.modifyFilter( 'users', users ) } } />

            <FarmsFilter farms={ props.filters.farms } setFarms={ ( farms ) => { props.modifyFilter( 'farms', farms ) } } />

            <FarmTypeFilter farmType={ props.filters.farmType } setFarmType={ ( farmType ) => { props.modifyFilter( 'farmType', farmType ) } } />

            <FarmDivisionFilter farmDivision={ props.filters.farmDivision } setFarmDivision={ ( farmDivision ) => { props.modifyFilter( 'farmDivision', farmDivision ) } } />

            <ChickenAgeFilter chickenAge={ props.filters.chickenAge } setChickenAge={ ( chickenAge ) => { props.modifyFilter( 'chickenAge', chickenAge ) } } ageFormat={ props.filters.ageFormat } setAgeFormat={ ( ageFormat ) => { props.modifyFilter( 'ageFormat', ageFormat ) } } />

            <VacunacionFilter vacunacion={ props.filters.vacunacion } setVacunacion={ ( vacunacion ) => { props.modifyFilter( 'vacunacion', vacunacion ) } } />

            <CoccidiostatosFilter type="initial" coccidiostatos={ props.filters.initialCoccidiostatos } setCoccidiostatos={ ( initialCoccidiostatos ) => { props.modifyFilter( 'initialCoccidiostatos', initialCoccidiostatos ) } } list={ props.products.filter( prod => prod.type === 'COCCIDRATO' ) } />

            <CoccidiostatosFilter type="final" coccidiostatos={ props.filters.finalCoccidiostatos } setCoccidiostatos={ ( finalCoccidiostatos ) => { props.modifyFilter( 'finalCoccidiostatos', finalCoccidiostatos ) } } list={ props.products.filter( prod => prod.type === 'COCCIDRATO' ) } />

            <PromotorsFilter type="initial" promotors={ props.filters.initialPromotors } setPromotors={ ( initialPromotors ) => { props.modifyFilter( 'initialPromotors', initialPromotors ) } } list={ props.products.filter( prod => prod.type === 'PROMOTOR_CRECIMIENTO' ) } />

            <PromotorsFilter type="final" promotors={ props.filters.finalPromotors } setPromotors={ ( finalPromotors ) => { props.modifyFilter( 'finalPromotors', finalPromotors ) } } list={ props.products.filter( prod => prod.type === 'PROMOTOR_CRECIMIENTO' ) } />

          </div>

        </div>
      </SlidingPanel>
    </div>
  );
};

const mapStateToProps = ( state ) => {

  return {
    farms: state.farms,
    products: state.products
  }

}

export default connect( mapStateToProps )(FilterPanel)
