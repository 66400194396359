import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import { withNamespaces } from 'react-i18next';

import './report.css';

import SpecificModalBody from './SpecificModalBody';
import GeneralModalBody from './GeneralModalBody';

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white',
    maxHeight             : '90vh',
    width                 : '700px'
  }
};

const ReportModal = ( props ) => {

  const form = props.formSelected
  if ( !form ) return null

  // RENDERIZADO DEL COMPONENTE MODAL
  return (
    <div>
      <Modal
              isOpen={ props.opened }
              onRequestClose={ props.handlerClose }
              style={ customStyles }
              contentLabel="Example Modal"
            >
              
              {
                props.average
                ?
                <GeneralModalBody forms={ props.forms } form={ form } filters={ props.filters } users={ props.users } profile={ props.profile } farms={ props.farms } products={ props.products } zones={ props.zones } initialFilters={ props.initialFilters } />
                :
                <SpecificModalBody form={ form } users={ props.users } profile={ props.profile } farms={ props.farms } products={ props.products } />
              }
              
      </Modal>
    </div>
  )
}

const mapStateToProps = ( state ) => {

  return {
    profile: state.profile,
    farms: state.farms,
    users: state.users,
    products: state.products,
    zones: state.zones
  }

}

export default compose(withNamespaces(), connect( mapStateToProps, null ))(ReportModal)
