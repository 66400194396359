import React from 'react';

import FormsCard from './cards/FormsCard';
import FarmCard from './cards/FarmCard';
import FarmsCard from './cards/FarmsCard';
import DateCard from './cards/DateCard';
import PhotosCard from './cards/PhotosCard';
import ChickensCard from './cards/ChickensCard';
import ObservationsCard from './cards/ObservationsCard';

import { withNamespaces } from 'react-i18next';

import './info.css'

const AverageCards = ( props ) => {

  return (
    <div className="rowContainer">
      <FormsCard amount={ props.formsList.length } />
      <FarmsCard amount={ getDistinct( props.formsList.map( form => form.datosFormulario.granja._id ) ).length } />
      <DateCard date={ props.formsList[ props.formsList.length - 1 ].datosFormulario.fecha } tag={ props.t( 'Start Date' ) } />
      <DateCard date={ props.formsList[ 0 ].datosFormulario.fecha } tag={ props.t( 'End Date' ) } />
    </div>
  )
}

const IndividualFormCards = ( props ) => {

  return (
    <div className="rowContainer">
      <FarmCard formSelected={ props.formSelected } />
      <DateCard date={ props.formSelected.datosFormulario.fecha } />
      <ChickensCard amount={ props.formSelected.cantidadAves } />
      {
        !props.formSelected.datosFormulario.savedPhotos.length && props.formSelected.datosFormulario.observaciones
        ?
        <ObservationsCard amount={ props.formSelected.datosFormulario.observaciones ? 1 : 0 } />
        :
        <PhotosCard amount={ props.formSelected.datosFormulario.savedPhotos ? props.formSelected.datosFormulario.savedPhotos.length : 0 } />
      }
    </div>
  )
}

const InfoSection = ( props ) => {

  return (
    <div className="section average">
      <div className="title"> { props.averageMode || !props.formSelected.datosFormulario ? props.t( 'Forms Data' ) : props.t( 'Selected Form Data' ) } </div>
      { props.averageMode || !props.formSelected.datosFormulario ? <AverageCards formsList={ props.formsList } t={ props.t } /> : <IndividualFormCards formSelected={ props.formSelected } /> }
    </div>
  )
}

 const getDistinct = ( dataArray ) => {

   const uniques = dataArray.filter( ( value, index, self ) => {
     return self.indexOf( value ) === index
   } );

   return uniques
 }

export default withNamespaces()(InfoSection)
