import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import { Select } from 'antd';
import { withNamespaces } from 'react-i18next';
import FarmCard from './FarmCard';

const { Option } = Select;

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NoFarms = props => {

  return (
    <div className="noFarms">
      <div className="icon">
        <img src="/assets/img/icons/marker.svg" alt="no farms" />
      </div>
      <div className="message">
        { props.t('¡There are not any farm inside this zone!') }
      </div>
    </div>
  )

}

const ZoneInfoModal = ( props ) => {

  // RENDERIZADO DEL COMPONENTE MODAL
  if ( props.infoModal.open ){

    let farmsElement;
    if ( props.infoModal.info.farms.length ){
      farmsElement = props.infoModal.info.farms.map( farm => {
                        return <FarmCard key={ farm._id } name={ farm.nombre } m2={ farm.m2 } renspa={ farm.renspa } owner={ farm.usuarioPropietario.nombre + ' ' + farm.usuarioPropietario.apellido } division={ farm.division } type={ farm.tipo } />
                      } )
    } else {
      farmsElement = <NoFarms t={ props.t } />
    }


    return (
      <div>
        <Modal
                isOpen={ props.infoModal.open }
                onRequestClose={ props.handlerClose }
                style={ customStyles }
                contentLabel="Example Modal"
              >

            <div className="container zoneInfo">
              <div className="header">

                <div className="name"> { props.infoModal.info.zoneName } </div>
                <div className="surface">
                  <div className="tag"> { props.t( 'Surface' ) + ': ' } </div>
                  <div className="value"> { props.infoModal.info.zoneSurface } </div>
                </div>

              </div>

              <div className="body">

                { farmsElement }

              </div>
            </div>

        </Modal>
      </div>
    )
  } else {
    return null
  }
}

export default withNamespaces()(ZoneInfoModal)
