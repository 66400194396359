import React from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';

import { Trans } from 'react-i18next';

const CardData = ( props ) => {

  return (
    <div className="card data">
      <div className="title"> <Trans>{ props.farm.nombre }</Trans> </div>
      <div className="dataValues">
        <div className="dataItem">
          <div className="tag"> <Trans>ID Sanitario</Trans> </div>
          <div className="value"> { props.farm.renspa ? props.farm.renspa : '-' } </div>
        </div>
        <div className="dataItem">
          <div className="tag"> <Trans>Surface</Trans> </div>
          <div className="value"> { props.farm.m2 ? props.farm.m2 + ' m2' : '-' } </div>
        </div>
        <div className="dataItem">
          <div className="tag"> <Trans>Type</Trans> </div>
          <div className="value"> { props.farm.tipo } </div>
        </div>
        <div className="dataItem">
          <div className="tag"> <Trans>Division</Trans> </div>
          <div className="value"> { props.farm.division } </div>
        </div>
        <div className="dataItem">
          <div className="tag"> <Trans>User</Trans> </div>
          <div className="value"> { props.farm.usuarioPropietario.nombre + ' ' + props.farm.usuarioPropietario.apellido } </div>
        </div>
      </div>
    </div>
  )
}

export default CardData
