const farmsReducer = ( farms = null, action ) => {

  if ( action.type === 'GET_FARMS' ){
    return action.payload;
  } else if ( action.type === 'UPDATE_FARM' ){
    return farms.map( farm => {
      if ( farm._id === action.payload._id ){
        
        return action.payload
      } else {
        return farm
      }
    } )
  }

  return farms;

}

export default farmsReducer;
