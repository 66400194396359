import React from 'react';
import { IconContext } from "react-icons";
import { BsHouse } from "react-icons/bs";

import { Trans } from 'react-i18next';

const FarmsCard = ( props ) => {

  return (
    <div className="card farms">
      <div className="data">
        <div className="amount"> { props.amount } </div>
        <div className="tag"> <Trans>Farms Selected</Trans> </div>
      </div>
      <div className="icon">
        <div className="container">
          <IconContext.Provider value={{ size: "20px" }}>
            <div>
              <BsHouse />
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  )
}

export default FarmsCard
