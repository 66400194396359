import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Header from './components/Header';
import AccountCard from './components/AccountCard';
import LanguageCard from './components/LanguageCard';
import PasswordCard from './components/PasswordCard';

import './profile.css'

const ProfilePage = props => {

  const [ loading, setLoading ] = useState( true );

  useEffect( () => {
    setLoading( false );
  }, [ props.profile ])

  if ( loading ){
    return (
      <div className="loadingPage">
        <img src="/assets/img/icons/loading.svg" alt="loading"></img>
      </div>
    )
  } else {
    return (
      <div className="profilePage">
        <Header />
        <div className="section info">
          <AccountCard />
        </div>
        <div className="section actions">
          <LanguageCard />
          <PasswordCard />
        </div>
      </div>
    )
  }

}

const mapStateToProps = ( state ) => {

  return {
    profile: state.profile
  }

}

export default connect( mapStateToProps, null )(ProfilePage)
