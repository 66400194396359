import React from 'react';
import { Trans } from 'react-i18next';

const NoRecords = props => {

  return (
    <div className="noRecords">
      <div className="logo">
        <img src="/assets/img/icons/noForms.svg" alt="noForms" />
      </div>
      <div className="title"> <Trans>¡ No Forms !</Trans> </div>
      <div className="message">
        <p> <Trans>None of the forms in this space meet all the selected filters.</Trans> </p>
        <p> <Trans>Please check the selected filters to watch your forms.</Trans> </p>
      </div>
    </div>
  )
}

export default NoRecords
