import React from 'react';
import { Checkbox } from 'antd';

import { Trans } from 'react-i18next';

const FarmDivisionFilter = props => {

  const handlerFarmDivisionChange = divisionsSelected => {

    if ( divisionsSelected.includes( 'ALL' ) ){

      // SI ALL ESTABA SELECCIONADO Y MARQUE UNA OPCION, DESMARCO ALL
      if ( props.farmDivision.includes('ALL') ){
        props.setFarmDivision( divisionsSelected.filter( d => d !== 'ALL' ) )
      } else {
        // SI ALL LO MARQUE AHORA.. MARCO ALL
        props.setFarmDivision( ['ALL'] )
      }

    } else {
        props.setFarmDivision( divisionsSelected );
    }

  }


  return (
    <div className="filter farmType">
      <div className="tag"> <Trans>Farm's Division</Trans> </div>
      <div className="selector">
        <Checkbox.Group value={ props.farmDivision } onChange={ handlerFarmDivisionChange } style={{ width: '100%' }}>
          <Checkbox value="Postura" > <Trans>Postura</Trans> </Checkbox>
          <br />
          <Checkbox value="Engorde" > <Trans>Engorde</Trans> </Checkbox>
          <br />
          <Checkbox value="Reproductoras" > <Trans>Reproductora</Trans> </Checkbox>
          <br />
          <Checkbox value="Otras" > <Trans>Otras</Trans> </Checkbox>
          <br />
          <br />
          <Checkbox value="ALL" > <Trans>All</Trans> </Checkbox>
        </Checkbox.Group>
      </div>
    </div>
  )

}

export default FarmDivisionFilter
