const sidenavReducer = ( sidenavOpen, action ) => {

  if ( action.type === 'CLOSE_SIDENAV' ){

    return false

  }

  if ( action.type === 'OPEN_SIDENAV' ){

    return true

  }

  return true

}

export default sidenavReducer
