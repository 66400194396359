import React from 'react';
import { IconContext } from "react-icons";
import { FaUserCircle, FaCommentSlash } from "react-icons/fa";

import { Trans } from 'react-i18next';

const ChatComponent = props => {
  return (
    <div className="chat">
      <div className="icon">
        <IconContext.Provider value={{ color: "rgb(235, 238, 253)", size: "40px" }}>
          <div>
            <FaUserCircle />
          </div>
        </IconContext.Provider>
      </div>
      <div className="chatContainer">
        { props.comments }
      </div>
    </div>
  )
}

const NoCommentsComponent = () => {
  return (
    <div className="noCommentsContainer">
      <div className="icon">
        <IconContext.Provider value={{ color: "rgb(235, 238, 253)", size: "60px" }}>
          <div>
            <FaCommentSlash />
          </div>
        </IconContext.Provider>
      </div>
      <div className="message">
        <Trans>¡ No observations registered !</Trans>
      </div>
    </div>
  )
}

const CommentsCard = ( props ) => {

  return (
    <div className="card comments">
      <div className="title"> <Trans>Comments</Trans> </div>
      {
        props.comments ? <ChatComponent comments={ props.comments } /> : <NoCommentsComponent />
      }
    </div>
  )
}

export default CommentsCard
