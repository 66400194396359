import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import * as L from 'leaflet';
import 'leaflet-draw';
// import 'leaflet/dist/leaflet.css';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2'

import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';

import 'react-leaflet';
import farmsService from '../../../../services/Farms';

const EditFarmPosition = ( props ) => {

    const [ map, setMap ] = useState( null )

    useEffect( () => {

        const defaultCenter =   props.farm &&
                                props.farm.posicion &&
                                props.farm.posicion.latitud &&
                                props.farm.posicion.longitud ?
                                [ props.farm.posicion.latitud, props.farm.posicion.longitud ] :
                                [ -32, -60 ]

        const mapa = L.map('map', {
            center: defaultCenter,
            zoom: 5
        })

        setMap( mapa )

        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo( mapa )

        }, []
    )

 // FUNCION DE CREACION DE ZONA
  const createNewZone = ( ) => {

    // // ALERT DE LOADING
    Swal.fire ({
       title: props.t( '..Updating position..' ),
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    const center = map.getCenter()
    const data = {
        posicion: {
            latitud: center.lat,
            longitud: center.lng
        }
    }
    farmsService.put( props.farm._id, data )
                                                .then( res => {
                                                    props.updateFarm({
                                                        ...props.farm,
                                                        ...data
                                                    })
                                                    Swal.close();
                                                    props.setMode( 'LIST' )
                                                } )

  }

 // FUNCION DE CREACION DE ZONA
  return (
    <div className="farmsPage map">
      <div className="mapContainer">
        <div id="map"></div>
        <div className="card">
          <div className="title"> { props.farm.nombre } </div>
          <div className="message">
              { props.t( 'Position the position of your farm to match the center of the map.' ) }
          </div>
        </div>
        <div className="marker">
            <img src="/assets/img/icons/location.svg"></img>
        </div>
        <div className="footer">
          <div className="actions">
            <Button variant="" onClick={ () => { props.setMode( 'LIST' ) } }> { props.t( 'Cancel' ) } </Button>
            <Button variant="success" onClick={ createNewZone }> { props.t( 'Save' ) } </Button>
          </div>
        </div>
      </div>
    </div>
  )
}


export default withNamespaces()(EditFarmPosition)
