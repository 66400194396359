import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2'
import { IconContext } from "react-icons";
import { FiTrash } from "react-icons/fi";

import formsService from '../../../../services/Forms';
import removeForm from '../../../../actions/removeForm';

const deleteForm = ( formID, removeForm, t ) => {

  // DISPARO ALERT DE CONFIRMACION
  Swal.fire({
            title: t( 'Remove Form' ),
            text: t( 'Are you sure you want to delete this form?' ),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: t( 'Cancel' ),
            confirmButtonText: t( 'Yes, delete it!' ),
            showLoaderOnConfirm: true,
            // AL CONFIRMAR LE PEGO A LA API DE DELETE /ZONES
            preConfirm: ( value ) => {
              return formsService.delete( formID )
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // AL FINALIZAR MUESTRO ALERT DE SUCCESS Y LE PEGO AL HANDLER DE RELOAD PAGE
            if ( result.value ) {
              Swal.fire(
                t( 'Form Deleted!' ),
                t( 'The form has been deleted successfully.' ),
                'success'
              )
              removeForm()
            }
          })
}

const RemoveForm = props => {

  return (
    <div className="removeForm">
      <div className="button" onClick={ () => { deleteForm( props.form._id, () => {
          props.removeForm( props.form._id )
        }, props.t ) } }>
        <div className="logo">
          <IconContext.Provider size={ 20 } value={{ className: "global-class-name" }} >
            <div>
              <FiTrash />
            </div>
          </IconContext.Provider>
        </div>
        <div className="tag"> { props.t( 'Remove Form' ) } </div>
      </div>
    </div>
  )
}


export default compose(withNamespaces(), connect( null, { removeForm } ))(RemoveForm)
