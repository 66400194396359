import React from 'react';
import { Checkbox } from 'antd';
import { Trans } from 'react-i18next';

const VacunacionFilter = props => {

  const handlerVacunacion = vacunaciones => {

    if ( vacunaciones.includes( 'ALL' ) ){

      // SI ALL ESTABA SELECCIONADO Y MARQUE UNA OPCION, DESMARCO ALL
      if ( props.vacunacion.includes('ALL') ){
        props.setVacunacion( vacunaciones.filter( vac => vac !== 'ALL' ) )
      } else {
        // SI ALL LO MARQUE AHORA.. MARCO ALL
        props.setVacunacion( ['ALL'] )
      }

    } else {
        props.setVacunacion( vacunaciones );
    }

  }


  return (
    <div className="filter vacunacion">
      <div className="tag"> <Trans>Vaccination</Trans> </div>
      <div className="selector">
        <Checkbox.Group value={ props.vacunacion } onChange={ handlerVacunacion } style={{ width: '100%' }}>
          <Checkbox value="Salmonella" > <Trans>Salmonella</Trans> </Checkbox>
          <br />
          <Checkbox value="Ninguna" > <Trans>None</Trans> </Checkbox>
          <br />
          <Checkbox value="No Informa" > <Trans>No Information</Trans> </Checkbox>
          <br />
          <br />
          <Checkbox value="ALL" > <Trans>All</Trans> </Checkbox>
        </Checkbox.Group>
      </div>
    </div>
  )

}

export default VacunacionFilter
