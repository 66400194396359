import React, { useState } from 'react';

import NewUser from './NewUser';
import { withNamespaces } from 'react-i18next';

const Filter = ( props ) => {

  // VARIABLES DE ESTADO PARA MOSTRAR MODAL DE NUEVA ZONA Y FILTRO
  const [ newUserFormVisible, setNewUserFormVisible ] = useState( false );
  const [ filterString, setFilterString ] = useState( '' );

  // HANDLER PARA EL CAMBIO DE VALOR EN EL INPUT DE FILTRO
  const handleChange = ( event ) => {
    const value = event.target.value;
    setFilterString( value );

    props.filterChangeHandler( value );
  }

  // HANDLER PARA RELODEAR LISTA DE ZONAS AL CREAR UNA ZONA NUEVA
  const reloadUsers = () => {

    setNewUserFormVisible( false );

    props.reloadUsers();

  }

  // RENDERIZADO DE COMPONENTE
  return (
    <div className="filter">
      <div className="actions">
        <div className="search">
          <input name="user" placeholder={ props.t('Search...') } value={ filterString } onChange={ handleChange } />
        </div>
        <div className="options">
          <div className="newUser" onClick={ () => { setNewUserFormVisible( true ) } }> <img src="/assets/img/icons/newUser.svg" alt="newUser" /></div>
        </div>
      </div>


      <NewUser open={ newUserFormVisible } handlerClose={ () => { setNewUserFormVisible( false ) } } successHandler={ reloadUsers } />
    </div>
  )
}

export default withNamespaces()(Filter)
