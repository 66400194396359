import React from 'react';
import { IconContext } from "react-icons";
import { BsHouse } from "react-icons/bs";
import { connect } from 'react-redux';

import { Trans } from 'react-i18next';

const FarmCard = ( props ) => {
  const farm = props.farms.find( f => f._id === props.formSelected.datosFormulario.granja._id )

  if ( !farm ){
    return (
      <div className="card farms">
        <div className="data">
          <div className="amount" style={{ color: 'red' }}> <Trans>Farm Deleted</Trans> </div>
        </div>
        <div className="icon">
          <div className="container">
            <IconContext.Provider value={{ size: "20px" }}>
              <div>
                <BsHouse />
              </div>
            </IconContext.Provider>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="card farms">
      <div className="data">
        <div className="amount"> { farm.nombre } </div>
        <div className="tag"> <Trans>Farm</Trans> </div>
      </div>
      <div className="icon">
        <div className="container">
          <IconContext.Provider value={{ size: "20px" }}>
            <div>
              <BsHouse />
            </div>
          </IconContext.Provider>
        </div>
      </div>
      <div className="popup">
        <div className="name"> { farm.nombre } </div>

        <hr />

        <div className="data">
          <div className="item">
            <div className="tag"> <Trans>ID</Trans> </div>
            <div className="value"> { farm.renspa } </div>
          </div>
          <div className="item">
            <div className="tag"> <Trans>Surface</Trans> </div>
            <div className="value"> { farm.m2 ? farm.m2 + ' m2' : '-' } </div>
          </div>
          <div className="item">
            <div className="tag"> <Trans>Owner</Trans> </div>
            <div className="value"> { farm.usuarioPropietario.nombre + " " + farm.usuarioPropietario.apellido } </div>
          </div>
          <div className="item">
            <div className="tag"> <Trans>Type</Trans> </div>
            <div className="value"> { farm.tipo } </div>
          </div>
          <div className="item">
            <div className="tag"> <Trans>Division</Trans> </div>
            <div className="value"> { farm.division } </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ( state ) => {

  return {
    farms: state.farms
  }

}

export default connect( mapStateToProps )(FarmCard)
