import React, { useState, useEffect } from 'react';

import Swal from 'sweetalert2'

import Pagination from "react-js-pagination";

import { Trans } from 'react-i18next';
import { withNamespaces } from 'react-i18next';

// COMPONENTS

const FarmListFilter = ( props ) => {

  // HANDLER PARA EL CAMBIO DE VALOR EN EL INPUT DE FILTRO
  const handleChange = ( event ) => {

    const value = event.target.value;

    props.filterChangeHandler( value );
  }

  return (
    <div className="filter">
      <div className="search">
        <input name="farm" placeholder={ props.t('Search...') } value={ props.filterString } onChange={ handleChange } />
      </div>
    </div>
  )
}

// ITEM LIST DEL BODY
const FarmListItem = ( props ) => {

  return (
    <div className={ props.farmSelected._id === props.farm._id ? 'farmListItem selected' : 'farmListItem'} onClick={ () => { props.selectFarm( props.farm ) } }>
      <div className="name"> { props.farm.nombre } </div>
      <div className="renspa"> { props.farm.renspa } </div>
    </div>
  )
}

// LISTA COMPLETA DE USUARIOS
const FarmsList = ( props ) => {

  // VARIABLES DE ESTADO DE PAGINACION Y FILTRO
  const [ activePage, setActivePage ] = useState( 1 );
  const [ filteredList, setFilteredList ] = useState( [] );
  const [ filterString, setFilterString ] = useState( '' );

  // HOOK PARA ACTUALIZACION DE LISTA DE USUARIOS EN FUNCION DEL FILTRO
  useEffect( () => {
    const fullList = props.farms.map( farm => {

      const fullName = farm.nombre

      if ( fullName.toUpperCase().includes( filterString.toUpperCase() ) ){
        return <FarmListItem selectFarm={ props.selectFarm } t={ props.t } key={ farm._id } farm={ farm } farmSelected={ props.farmSelected }  />
      }

    } ).filter( data => data )

    setFilteredList( fullList );

  }, [ filterString, props.farmSelected ] )

  // RENDERIZO LA LISTA DE ZONAS CON LA PAGINACION
  return (
    <div className="farmList">
      <FarmListFilter filterString={ filterString } filterChangeHandler={ setFilterString } t={ props.t } />
      <div className="list">
        { filteredList }
      </div>
    </div>
  )
}


export default withNamespaces()(FarmsList)
