import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import Header from './components/Header';
import Products from './components/Products';

import updateProduct from '../../../actions/updateProduct';

import './products.css';

const ProductsPage = ( props ) => {

  const [ products, setProducts ] = useState( [] )

  useEffect( () => {

    setProducts( props.products )

  }, [ props.products ] )

  // RENDERIADO DEL COMPONENTE
  return (
    <div className="productsPage">
      <Header />
      <div className="columns">
        <Products title={ props.t( "Growth Promoters" ) } updateProduct={ props.updateProduct } type="PROMOTOR_CRECIMIENTO" products={ products.filter( prod => prod.type === 'PROMOTOR_CRECIMIENTO' ) } />
        <Products title={ props.t( "Coccidiostatos" ) } updateProduct={ props.updateProduct } type="COCCIDRATO" products={ products.filter( prod => prod.type === 'COCCIDRATO' ) } />
      </div>
    </div>
  )

}

const mapStateToProps = ( state ) => {

  return {
    products: state.products
  }

}

export default compose(withNamespaces(), connect( mapStateToProps, { updateProduct } ))(ProductsPage)