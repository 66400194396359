import React from 'react';

import IntestinalQualityPercentage from './IntestinalQualityPercentage';
import IntestinalQualitySummery from './IntestinalQualitySummery';

import { Trans } from 'react-i18next';

import './summery.css'

const SummerSection = ( props ) => {
  return (
    <div className="section summery" id="summerySection">
      <div className="title"> <Trans>Intestinal Health Summery</Trans> </div>
      <div className="rowContainer">
        <IntestinalQualityPercentage value={ props.form.resultadosIndices.calidadIntestinal } />
        <IntestinalQualitySummery formSummery={ props.form.resultadosIndices } />
      </div>
    </div>
  )
}

export default SummerSection
