import React from 'react'

import Header from './components/Header';
import FeaturesList from './components/FeaturesList';
import PoweredBy from './components/PoweredBy';

import './sidenav.css';

const Sidenav = ( props ) => {

  return (
    <div className={ props.open ? 'sidenav' : 'sidenav closed' }>
      <Header />
      <FeaturesList />
      <PoweredBy />
    </div>
  )
}

export default Sidenav
