import React, { useEffect } from 'react';
import { Route, useRouteMatch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import authService from '../services/Auth';

import getFarms from '../actions/getFarms';
import getForms from '../actions/getForms';
import getUsers from '../actions/getUsers';
import getZones from '../actions/getZones';
import getProducts from '../actions/getProducts';
import getProfile from '../actions/getProfile';

import Sidenav from './sidenav/Sidenav';

import UserPage from './pages/users/UserPage';
import FarmsPage from './pages/farms/FarmsPage';
import ZonesPage from './pages/zones/ZonesPage';
import ProfilePage from './pages/profile/ProfilePage';
import DashboardsPage from './pages/dashboards/DashboardsPage';
import ProductsPage from './pages/products/ProductsPage';
import AlarmsPage from './pages/alarms/AlarmsPage';
import TestPage from './pages/test/TestPage';

import Modal from 'react-modal';
import i18n from '../i18n';
import FormsPage from './pages/forms/FormsPage';

Modal.setAppElement('#root');

const Main = ( props ) => {

  useEffect( () => {
    authService.ping({
      success: () => {
        props.getFarms();
        props.getForms();
        props.getUsers();
        props.getProducts();
        props.getZones();
        props.getProfile();
      }
    })
  }, [] )

  let { path } = useRouteMatch();

  if ( !props.farms || !props.users || !props.forms || !props.zones || !props.profile ){
    return (
      <div className="mainContainer">
        <div className="loadingPage">
          <img src="/assets/img/icons/loading.svg" alt="loading"></img>
        </div>
      </div>
    )
  } else {

    return (
       <div className="mainContainer">
         <Sidenav open={ props.sidenavOpen } />
         <div className={ !props.sidenavOpen ? 'pageContainer full' : 'pageContainer' }>
           <Route path={ `${ path }/users` } component={ UserPage } />
           <Route path={ `${ path }/farms` } component={ FarmsPage } />
           <Route path={ `${ path }/zones` } component={ ZonesPage } />
           <Route path={ `${ path }/dashboards` } component={ DashboardsPage } />
           <Route path={ `${ path }/forms` } component={ FormsPage } />
           <Route path={ `${ path }/products` } component={ ProductsPage } />
           <Route path={ `${ path }/alarms` } component={ AlarmsPage } />
           <Route path={ `${ path }/profile` } component={ ProfilePage } />
           <Route path={ `${ path }/test` } component={ TestPage } />
           <Route path={ `${ path }` } exact > <Redirect to={ `${ path }/dashboards` } /> </Route>
         </div>
       </div>
    )
  }
}

const mapStateToProps = ( state ) => {

  return {
    sidenavOpen: state.sidenavOpen,
    farms: state.farms,
    forms: state.forms,
    users: state.users,
    products: state.products,
    zones: state.zones,
    profile: state.profile
  }

}

export default connect( mapStateToProps, { getFarms, getForms, getUsers, getZones, getProducts, getProfile } )(Main)
