import React from 'react';
import { IconContext } from "react-icons";
import { FiMap } from "react-icons/fi";

import { Trans } from 'react-i18next';

const Header = ( props ) => {
  return (
    <div className="header">
      <div className="title"> <Trans>Zones List</Trans> </div>
      <div className="separator"> | </div>
      <div className="icon">
        <IconContext.Provider size={ 25 } value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
          <div>
            <FiMap />
          </div>
        </IconContext.Provider>
      </div>
    </div>
  )
}

export default Header
