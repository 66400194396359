import React, { useState } from 'react';
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import usersService from '../../../../services/Users';

import Swal from 'sweetalert2'
import { Select } from 'antd';
import { withNamespaces } from 'react-i18next';

const { Option } = Select;

// ESTILO DEL MODAL DE INVITACION DE USUARIO
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background            : 'white'
  }
};

const NewUser = ( props ) => {

  const [ tipoUsuario, setTipoUsuario ] = useState( 1 );
  const [ language, setLanguage ] = useState( 'es_AR' );

  const handleTipoUsuarioChange = newRole => {
    setTipoUsuario( newRole );
  }

  const handleLanguageChange = newLanguage => {
    setLanguage( newLanguage );
  }

  // FUNCION DE CREACION DE ZONA
  const inviteUser = () => {

    const name = document.getElementById('invitationName').value;
    const lastName = document.getElementById('invitationLastName').value;
    const email = document.getElementById('invitationEmail').value;

    if ( !name || !lastName || !email ){
      Swal.fire({
        icon: 'warning',
        title: props.t( 'Incompleted Fields!' ),
        text: props.t( 'Please complete all data fields to send invitation.' )
      })

      return;
    }

    // ALERT DE LOADING
    Swal.fire ({
       title: props.t( '..Sending invitation to this new user..' ),
       onBeforeOpen: () => {
         Swal.showLoading ()
       }
    })

    // CAPTURO LOS DATOS PARA ENVIAR A LA API POST /ZONES
    const jsonData = {
      nombre: name,
      apellido: lastName,
      email: email,
      tipoUsuario: tipoUsuario,
      idioma: language
    }

    // LE PEGO A LA API Y AL FINALIZAR LLAMO AL HANDLER DE SUCCESS Y CIERRO EL ALERT
    usersService.post( jsonData, {
      success: () => {
        Swal.close();
        props.successHandler();
      },
      error: err => {

        if ( err.code && err.code === 1 ) {
          Swal.fire({
            icon: 'error',
            title: props.t( 'Existing User' ),
            text: props.t( 'This user already has an account in the system.' )
          })
        }
      }
    } )

  }

  // RENDERIZADO DEL COMPONENTE MODAL
  return (
    <div>
      <Modal
              isOpen={ props.open }
              onRequestClose={ props.handlerClose }
              style={ customStyles }
              contentLabel="Example Modal"
            >

        <div className="modalContainer newUser">
          <div className="column">
            <img src="/assets/img/icons/user.svg" alt="user" />
          </div>
          <div className="data">
            <div className="form">
              <div className="line name">
                <div className="form-input">
                  <input id="invitationName" type="text" name="firstname" placeholder={ props.t( 'First Name' ) } />
                </div>
                <div className="form-input">
                  <input id="invitationLastName" type="text" name="lastname" placeholder={ props.t( 'Last Name' ) } />
                </div>
              </div>
              <div className="line email">
                <div className="form-input">
                  <input id="invitationEmail" type="text" name="user" placeholder={ props.t( 'Email' ) } />
                </div>
              </div>
              <div className="line extra">
                <div className="form-select">
                  <Select value={ tipoUsuario } style={{ width: 150 }} onChange={ handleTipoUsuarioChange }>
                    <Option value={ 1 }> { props.t( 'Supervisor' ) } </Option>
                    <Option value={ 2 }> { props.t( 'Only Read' ) } </Option>
                    <Option value={ 3 }> { props.t( 'Data Loader' ) } </Option>
                    <Option value={ 4 }> { props.t( 'Partial Edition' ) } </Option>
                  </Select>
                </div>
                <div className="form-select">
                  <Select value={ language } style={{ width: 150 }} onChange={ handleLanguageChange }>
                    <Option value="es_AR"> { props.t( 'Spanish' ) } </Option>
                    <Option value="en_US"> { props.t( 'English' ) } </Option>
                    <Option value="pt_BR"> { props.t( 'Portugues' ) } </Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="footer">
              <Button variant="primary" onClick={ inviteUser }>{ props.t( 'Send Invitation' ) }</Button>
            </div>
          </div>
        </div>

      </Modal>
    </div>
  )
}

export default withNamespaces()(NewUser)
