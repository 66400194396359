import React from 'react';
import { Checkbox } from 'antd';

import { Trans } from 'react-i18next';

const FarmTypeFilter = props => {

  const handlerFarmTypeChange = typesSelected => {

    let newOption = typesSelected.find( option => option !== props.farmType );

    // SI TRATE DE SELECCIONAR LA SELECCIONADA NO HAGO NADA - CANCELO
    if ( !newOption ) return

    props.setFarmType( newOption );
  }

  return (
    <div className="filter farmType">
      <div className="tag"> <Trans>Farm's Type</Trans> </div>
      <div className="selector">
        <Checkbox.Group value={ [ props.farmType ] } onChange={ handlerFarmTypeChange } style={{ width: '100%' }}>
          <Checkbox value="Propia" > <Trans>Own</Trans> </Checkbox>
          <br />
          <Checkbox value="Integrada" > <Trans>Integrated</Trans> </Checkbox>
          <br />
          <br />
          <Checkbox value="ALL" > <Trans>All</Trans> </Checkbox>
        </Checkbox.Group>
      </div>
    </div>
  )

}

export default FarmTypeFilter
