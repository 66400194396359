import React from 'react';
import { Trans } from 'react-i18next';
import AlarmCard from './AlarmCard';
import { withNamespaces } from 'react-i18next';

const notificationsList = [
  {
    code: 'FORM_CALIDAD_INTESTINAL_INDICE_SISTEMA_DIGESTIVO',
    title: 'Digestive System Injury Index'
  },
  {
    code: 'FORM_CALIDAD_INTESTINAL_INDICE_COCCIDIOS',
    title: 'Coccidia Risk Index'
  },
  {
    code: 'FORM_CALIDAD_INTESTINAL_INDICE_CLOSTRIDIOS',
    title: 'Disbiosis Risk Index'
  },
  {
    code: 'FORM_CALIDAD_INTESTINAL_INDICE_CALIDAD',
    title: 'Intestinal Quality Index'
  }
]

const AlarmsList = props => {

  return (
    <div className="sections">
      <div className="title"> { props.t('Sections') } </div>
      <div className="message">
        <p> { props.t('Activate any alarm you want to receive notifications by email.') } </p>
      </div>
      <div className="alarms">

        <div className="colContainer">
          <AlarmCard alarmData={ props.alarmsData && props.alarmsData.notificaciones ? props.alarmsData.notificaciones.find( not => not.codigo === notificationsList[0].code ) : null } code={ notificationsList[0].code } title={ props.t( notificationsList[0].title ) } />
          <AlarmCard alarmData={ props.alarmsData && props.alarmsData.notificaciones ? props.alarmsData.notificaciones.find( not => not.codigo === notificationsList[1].code ) : null } code={ notificationsList[1].code } title={ props.t( notificationsList[1].title ) } />
        </div>
        <div className="colContainer">
          <AlarmCard alarmData={ props.alarmsData && props.alarmsData.notificaciones ? props.alarmsData.notificaciones.find( not => not.codigo === notificationsList[2].code ) : null } code={ notificationsList[2].code } title={ props.t( notificationsList[2].title ) } />
          <AlarmCard alarmData={ props.alarmsData && props.alarmsData.notificaciones ? props.alarmsData.notificaciones.find( not => not.codigo === notificationsList[3].code ) : null } code={ notificationsList[3].code } title={ props.t( notificationsList[3].title ) } />
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(AlarmsList)
