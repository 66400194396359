import React from 'react';

import { IconContext } from "react-icons";
import { TiInfoLarge } from "react-icons/ti";

import { Trans } from 'react-i18next';
import Graph from './Graph';
import { withNamespaces } from 'react-i18next';

const IntestinalQualityPercentage = ( props ) => {

  const indexValue = Math.round( props.value );

  return (
    <div className="card intestinalQualityPercentage">

        <div className="head">
          <div className="title"> <Trans>Intestinal Quality</Trans> </div>
          <div className="info">
            <div className="icon">
              <IconContext.Provider value={{ color: "rgb(45,45,60)", className: "global-class-name" }}>
                <div>
                  <TiInfoLarge />
                </div>
              </IconContext.Provider>
            </div>
            <div className="tooltip">
              <div className="title">{ props.t('Info') } </div>
              <hr />
              <div className="content">
                <span> { props.t( 'The intestinal quality index takes into account ' ) } </span>
                <span> <b> { props.t( 'all the parameters evaluated ' ) } </b> </span>
                <span> { props.t( 'and indicates the general integrity of the digestive system, ' ) } </span>
                <span> <b> { props.t( 'maintaining an integrity above 70% will be more feasible to achieve productive objectives.' ) } </b> </span>
              </div>
            </div>
          </div>
        </div>

        <Graph value={ indexValue } />
    </div>
  )
}

export default withNamespaces()(IntestinalQualityPercentage)